import React from 'react';
import classnames from 'classnames';
import {
  Link, Route, RouteComponentProps, withRouter
} from 'react-router-dom';

import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  label: string;
  to: string;
  disabled?: boolean;
}

class NavLink extends React.PureComponent<IProps> {
  static defaultProps = {
    disabled: false
  };

  public render() {
    const {
      to,
      label,
      disabled
    } = this.props;

    const link = (props: RouteComponentProps<{}>) => {
      const classNames = classnames(
        styles.link,
        {
          [styles.disabled]: disabled,
          [styles.active]: Boolean(props.match)
        }
      );

      return (
        <div className={classNames}>
          {disabled ? <div>{label}</div> : <Link to={to}>{label}</Link>}
        </div>
      );
    };

    return (
      <Route path={to}>
        {link}
      </Route>
    );
  }
}

export default withRouter(NavLink);
