// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DescriptionList_list_49z1n {\n  display: table;\n  width: 100%; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW,EAAA","file":"styles.scss","sourcesContent":[".list {\n  display: table;\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"list": "DescriptionList_list_49z1n"
};
module.exports = exports;
