import React from 'react';
import {
  HashRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import CustomersListView from './CustomersListView';
import AccountDetailsView from './AccountDetailsView';
import CustomerDetailsView from './CustomerDetailsView';

type IProps = RouteComponentProps<{}>;

class CustomersManager extends React.PureComponent<IProps> {
  public render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/customers" component={CustomersListView} />
          <Route path="/customers/accounts/:trustAccountId" component={AccountDetailsView} />
          <Route path="/customers/:customerId" component={CustomerDetailsView} />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(CustomersManager);
