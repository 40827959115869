import qs from 'qs';
import { Parser } from 'json2csv';
import moment from 'moment';
// Services
import ApiService from '../ApiService';
// Models
import UserModel, { IUserObject } from '../../models/UserModel';
import UsersDao, {
  ILoadUsersResponse,
  IUserCreationRequest, IUserExportObject,
  IUsersSearchParams,
  IUserUpdateRequest, UsersSearchUserStatusGroup
} from './UsersDao';
import Pagination from '../Pagination';
// helpers
import locales from '../../helpers/locales';

const USERS_ENDPOINT = '/admin/admin_users';

interface IUsersResponse extends Pagination {
  admin_users: IUserObject[];
}

export default class UsersDaoImpl implements UsersDao {
  public api: ApiService;
  constructor(api: ApiService) {
    this.api = api;
  }

  public async loadUsers(params: IUsersSearchParams): Promise<ILoadUsersResponse> {
    const query = qs.stringify(params);
    const res = await this.api.get<IUsersResponse>(`${USERS_ENDPOINT}?${query}`);
    return {
      pagination: res.data.pagination,
      users: res.data.admin_users.map((userJson) => new UserModel(userJson))
    };
  }

  public async requestUserCreation(request: IUserCreationRequest) {
    await this.api.post(`${USERS_ENDPOINT}`, request);
  }

  public async requestUserUpdate(id: number, request: IUserUpdateRequest) {
    await this.api.put(`${USERS_ENDPOINT}/${id}`, request);
  }

  public async requestUserDeletion(id: number) {
    await this.api.delete(`${USERS_ENDPOINT}/${id}`);
  }

  public async rejectRequests(ids: number[]) {
    await this.api.put(`${USERS_ENDPOINT}/reject`, { admin_user_ids: ids });
  }

  public async approveRequests(ids: number[]) {
    await this.api.put(`${USERS_ENDPOINT}/approve`, { admin_user_ids: ids });
  }

  public async downloadUsers(): Promise<Blob> {
    const users = await this.fetchAllUsers();

    const exportedUsers: IUserExportObject[] = users.map((user) => {
      /* eslint-disable no-param-reassign */
      delete user.pending_updates;
      user.role = locales.get(`general.admin_user_roles.${user.role}`);
      user.admin_user_status = locales.get(`general.admin_user_statuses.${user.admin_user_status}`);
      user.created_at = moment(user.created_at).format('YYYY-MM-DD');
      user.revoked_at = user.revoked_at ? moment(user.revoked_at).format('YYYY-MM-DD') : '';
      user.updated_at = user.updated_at ? moment(user.updated_at).format('YYYY-MM-DD') : '';
      return user;
    });

    let csv = '';
    if (exportedUsers.length > 0) {
      const fields = Object.keys(exportedUsers[0]).map((field) => ({
        label: locales.get(`general.admin_user.${field}`),
        value: field
      }));

      const opts = {
        fields,
        delimiter: ',',
        doubleQuote: '',
        quote: ''
      };
      const parser = new Parser(opts);
      csv = parser.parse(exportedUsers);
    }
    return new Blob([csv], { type: 'text/csv' });
  }

  private async fetchAllUsers(): Promise<IUserObject[]> {
    let users: IUserObject[] = [];
    let page = 1;
    let last = 1;
    while (page <= last) {
      const params = {
        page,
        admin_user_status_group: UsersSearchUserStatusGroup.ActiveAll
      };
      const query = qs.stringify(params);
      const res = await this.api.get<IUsersResponse>(`${USERS_ENDPOINT}?${query}`);
      if (res.data.admin_users.length > 0) {
        users = users.concat(res.data.admin_users);
      }
      last = res.data.pagination.last;
      page += 1;
    }
    return users;
  }
}
