import locales from '../helpers/locales';

export interface IUserPendingUpdatesObject {
  requester_admin_user_id: number;
  branch_code?: string;
  employee_number?: string;
  name_kanji?: string;
  role?: string;
}

export default class UserPendingUpdatesModel {
  public requesterAdminUserId: number;
  public branchCode?: string;
  public employeeNumber?: string;
  public nameKanji?: string;
  public role?: string;

  constructor(json: IUserPendingUpdatesObject) {
    this.requesterAdminUserId = json.requester_admin_user_id;
    this.branchCode = json.branch_code;
    this.employeeNumber = json.employee_number;
    this.nameKanji = json.name_kanji;
    this.role = json.role;
  }

  get roleString() {
    return this.role ? locales.get(`general.admin_user_roles.${this.role}`) : '';
  }
}
