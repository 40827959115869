import UserModel from './UserModel';

export enum AccountState {
  Unknown = 'UNKNOWN',
  Pending = 'PENDING',
  SignedIn = 'SIGNED_IN',
  PasswordExpired = 'PASSWORD_EXPIRED',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED'
}

export default class AccountModel extends UserModel {}
