import React from 'react';
import classnames from 'classnames';
// Components
import Breadcrumb from '../../../../components/Breadcrumb';
// Models
import ClaimModel, { ClaimStatus } from '../../../../models/ClaimModel';
// Helpers
import locales from '../../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps {
  claim: ClaimModel;
  onClickLink: () => void;
}

export default class ClaimDetailsHeaderContent extends React.PureComponent<IProps> {
  static getClaimStatusColor(claim: ClaimModel) {
    switch (claim.status) {
      case ClaimStatus.Returned:
      case ClaimStatus.Cancelled:
        return styles.negative;
      default:
        return styles.positive;
    }
  }

  public render() {
    const { claim, onClickLink } = this.props;
    if (!claim) {
      return null;
    }
    const { trustAccount } = claim.customer;
    const breadcrumbTexts = [
      trustAccount.nameKanji,
      locales.get('claim.breadcrumb.detail'),
      locales.get('claim.breadcrumb.claim_detail')
    ];
    const statusClassName = classnames(
      styles.status,
      ClaimDetailsHeaderContent.getClaimStatusColor(claim)
    );
    return (
      <>
        <Breadcrumb
          texts={breadcrumbTexts}
        />

        <div className={statusClassName}>
          {claim.statusString}
        </div>

        <div className={classnames(styles.box)}>
          <div>{locales.get('general.trust_account.branch_code')}</div>
          <div>{trustAccount.branchCode}</div>
        </div>
        <div className={classnames(styles.box)}>
          <div>{locales.get('general.trust_account.account_number')}</div>
          <div>{trustAccount.number}</div>
        </div>
        <div className={classnames(styles.box)}>
          <div>{locales.get('general.claim.submitted_at')}</div>
          <div>{claim.submittedAtString}</div>
        </div>
        <div className={classnames(styles.box)}>
          <div>{locales.get('general.claim.mimamori_until_date')}</div>
          <div>{claim.mimamoriUntilDateString}</div>
        </div>

        <div className={classnames(styles.link)}>
          <a onClick={onClickLink}>{locales.get('claim.detail_link')}</a>
        </div>
      </>
    );
  }
}
