// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InvitationsList_link_2V9mk {\n  color: var(--primary-color); }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,2BAA2B,EAAA","file":"styles.scss","sourcesContent":["@import \"../../../../styles/theme\";\n\n.link {\n  color: var(--primary-color);\n}\n"]}]);
// Exports
exports.locals = {
	"link": "InvitationsList_link_2V9mk"
};
module.exports = exports;
