import React from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
// Components
import config from 'config.json';
import Button, { ButtonSize } from '../../components/Button';
import InputField, { InputFieldType } from '../../components/InputField';
import Title from '../../components/Title';
// Services
import FormService from '../../services/FormService';
import AccountService from '../../services/AccountService';
import DialogService, { DialogType } from '../../services/DialogService';
import SettingsService from '../../services/SettingsService';
// Models
import { AccountState } from '../../models/AccountModel';
// Helpers
import locales from '../../helpers/locales';
// Styles
import styles from './styles.scss';
import Loader from '../Loader';

interface IProps {
  accountService: AccountService;
  dialogService: DialogService;
  settingsService: SettingsService;
}

const EMPLOYEE = locales.get('login.employee_number');
const PASSWORD = locales.get('login.password');

@inject('accountService', 'dialogService', 'settingsService')
@observer
export default class Login extends React.Component<IProps> {
  private form: FormService;

  constructor(props: IProps) {
    super(props);
    this.form = new FormService(false)
      .initForm()
      .setField(EMPLOYEE, 'required')
      .setField(PASSWORD, 'required|password');
  }

  public componentDidMount = () => {
    const { settingsService } = this.props;
    settingsService.clearProductSelection();
    this.handleQueryParams();
  };

  public login = async () => {
    const { accountService, dialogService } = this.props;
    if (!this.form.validateAll()) {
      return;
    }
    try {
      await accountService.login(
        this.form.getValue(EMPLOYEE),
        this.form.getValue(PASSWORD)
      );
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message
      });

      this.form.clearField(PASSWORD);
    }
  };

  public changeField = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.form.setValue(event.currentTarget.name, event.currentTarget.value.replace(/\s/g, ''));
  };

  public blurField = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.form.validate(event.currentTarget.name);
  };

  // After clicking SSO login button, redirect to Cognito authorize endpoint
  public ssoLogin = async () => {
    const { dialogService } = this.props;
    const ssoUrl = `${config.cognito.domain}?` +
    `response_type=${config.cognito.responseType}&` +
    `client_id=${config.cognito.ssoAppClientId}&` +
    `redirect_uri=${config.cognito.redirectUri}&` +
    `identity_provider=${config.cognito.identityProvider}`;
    try {
      window.location.href = ssoUrl;
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('error_messages.sso_failure')
      });
    }
  };

  // Fix history to exclude unnecessary parameters when redirecting after SSO login
  public clearHistoryParams = () => {
    const url = new URL(window.location.href);
    // Clear unnecessary parameters
    url.searchParams.delete('code');
    url.searchParams.delete('error');
    // Add modified URL to history
    window.history.replaceState({}, document.title, url.toString());
    window.location.href = '/';
  };

  public handleQueryParams = async () => {
    const { accountService, dialogService } = this.props;
    // If SSO login returns 'error' in URL, show error message for cognito error
    const params = new URLSearchParams(window.location.search);
    const cognitoError = params.get('error');
    if (cognitoError !== null && cognitoError !== '') {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('error_messages.sso_failure'),
        ssoError: true
      });
    }

    // If SSO login returns 'code' in URL, get tokens from congnito token endpoint
    const code = params.get('code');
    if (code !== null && code !== '') {
      try {
        const tokenObtainURL = config.cognito.tokenDomain;
        const tokenParams = new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: config.cognito.ssoAppClientId,
          code,
          redirect_uri: config.cognito.redirectUri
        });
        const response = await axios.post(tokenObtainURL, tokenParams.toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        const tokenData = response.data;
        // Extract tokens from response data
        const { access_token, id_token, refresh_token } = tokenData;
        // Call ssoLogin method with obtained tokens
        await accountService.ssoLogin(access_token, id_token, refresh_token);
        // Clear unnecessary parameters before redirect
        this.clearHistoryParams();
      } catch (error) {
        dialogService.setDialog({
          type: DialogType.Warn,
          content: locales.get('error_messages.sso_failure'),
          ssoError: true
        });
      }
    }
  };


  public render() {
    const { accountService: { pendingAccount: loading, accountState } } = this.props;

    switch (accountState) {
      case AccountState.NewPasswordRequired:
        return <Redirect to="/new-password" />;

      case AccountState.PasswordExpired:
        return <Redirect to="/change-expired-password" />;

      case AccountState.SignedIn:
        return <Redirect to="/" />;

      default:
        return (
          <>
            <Loader loading={loading} />
            <div className={styles.login}>
              <div className={styles.container}>
                <Title className={styles.title}>{locales.get('login.title')}</Title>
                <div className={styles.formNew}>
                  <div className={styles.form}>
                    <InputField
                      type={InputFieldType.Text}
                      name={EMPLOYEE}
                      label={EMPLOYEE}
                      value={this.form.getValue(EMPLOYEE)}
                      error={this.form.getError(EMPLOYEE)}
                      onChange={this.changeField}
                      onBlur={this.blurField}
                    />
                    <InputField
                      type={InputFieldType.Password}
                      name={PASSWORD}
                      label={PASSWORD}
                      value={this.form.getValue(PASSWORD)}
                      error={this.form.getError(PASSWORD)}
                      onChange={this.changeField}
                      onBlur={this.blurField}
                    />
                  </div>
                  <Button
                    className={styles.button}
                    size={ButtonSize.Big}
                    label={locales.get('login.login')}
                    disabled={!this.form.isFormFilled()}
                    onClick={this.login}
                  />
                </div>
                <Button
                  className={styles.button}
                  size={ButtonSize.Big}
                  label={locales.get('login.sso_login')}
                  isSSO
                  onClick={this.ssoLogin}
                />
                <span className={styles.notice}>{locales.get('login.notice')}</span>
              </div>
            </div>
          </>
        );
    }
  }
}
