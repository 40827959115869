import awsAmplify, { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import moment from 'moment';
// Interfaces
import config from 'config.json';
import debug from 'debug';
import AccountDao, { SessionTimeoutHandler } from './AccountDao';
// Services
import ApiService, { RequestConfig, ApiResponse } from '../ApiService';
import StorageService from '../StorageService';
// Models
import AccountModel, { AccountState } from '../../models/AccountModel';
import { IUserObject } from '../../models/UserModel';
// App Config

import locales from '../../helpers/locales';

const logger = debug('MUTB:AccountDaoImpl');

const ACCOUNT_ENDPOINT = '/admin/admin_users/me';
const PASSWORD_CHANGE_ENDPOINT = '/password/change';
const PASSWORD_REGISTER_ENDPOINT = '/password/register';

const SESSION_LAST_TIMESTAMP_KEY = 'session_last_timestamp';
const SESSION_INTERVAL = 10 * 1000; // Time interval to check if the session is valid

// Constants for login types
const LoginType = {
  IDPW: 'IDPW',
  SSO: 'sso'
};

interface IAdminUserResponse {
  admin_user: IUserObject;
}

enum PasswordErrorType {
  InvalidArgs = 'INVALID_ARGS',
  ReusedPassword = 'REUSED_PASSWORD',
  SameInputPassword = 'SAME_INPUT_PASSWORD',
  NotAuthorized = 'NotAuthorizedException', // Congnito's error
  InvalidPassword = 'InvalidPasswordException' // Congnito's error
}

interface IPasswordResponse {
  username?: string;
  error?: PasswordErrorType;
  debug?: string;
}

export default class AccountDaoImpl implements AccountDao {
  public passwordApi: ApiService;
  public cognitoUserPool: CognitoUserPool|null;
  public cognitoUser: CognitoUser|null;
  private api: ApiService;
  private storage: StorageService;
  private tempPassword = '';
  private sessionInterval = 0;
  private sessionTimeoutHandlers: SessionTimeoutHandler[] = [];

  private get sessionTimestamp(): number {
    let lastRequestTimestamp = moment(this.storage.getItem(SESSION_LAST_TIMESTAMP_KEY) || 0);

    // Check in case if value in storage was broken (modified by user for example)
    if (!lastRequestTimestamp.isValid()) {
      lastRequestTimestamp = moment(0);
    }

    return lastRequestTimestamp.valueOf();
  }

  constructor(api: ApiService, storage: StorageService) {
    this.api = api;
    this.storage = storage;
    this.cognitoUserPool = null;
    this.cognitoUser = null;
    this.passwordApi = new ApiService(
      config.api.url, this.determineNormalResponse
    );
  }

  // ensure always checking the error from returned response
  public determineNormalResponse = () => true;

  public async getAccount(): Promise<AccountModel|null> {
    const res = await this.api.get<IAdminUserResponse>(ACCOUNT_ENDPOINT);
    logger('getAccount: ', res.data.admin_user);
    return new AccountModel(res.data.admin_user);
  }

  public async load(): Promise<AccountState> {
    // switch userPoolClientId for IDPW login and SSO login
    let userPoolClientId;
    if (this.storage.getItem('loginType') === LoginType.SSO) {
      userPoolClientId = config.cognito.ssoAppClientId;
    } else {
      userPoolClientId = config.cognito.userPoolClientId;
    }
    awsAmplify.configure({
      Auth: {
        userPoolId: config.cognito.userPoolId,
        userPoolWebClientId: userPoolClientId,
        identityPoolId: config.cognito.identityPoolId,
        region: config.cognito.region
      }
    });

    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
      logger('Cognito user: ', this.cognitoUser);

      this.authenticateApi();

      // skip checkPasswordExpired for SSO login
      if (this.storage.getItem('loginType') !== LoginType.SSO) {
        const isExpired = await this.checkPasswordExpired();
        if (isExpired) {
          return AccountState.PasswordExpired;
        }
      }
    } catch (error) {
      // "not authenticated" error thrown by Cognito
      return AccountState.Unknown;
    }

    if (this.isSessionExprired()) {
      await this.logout();
      return AccountState.Unknown;
    }

    this.startSessionTimeoutListener();
    return AccountState.SignedIn;
  }

  public async login(username: string, password: string): Promise<AccountState> {
    try {
      this.storage.setItem('loginType', LoginType.IDPW);

      const user = await Auth.signIn(username, password);
      this.cognitoUser = user;

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.tempPassword = password;
        return AccountState.NewPasswordRequired;
      }

      this.authenticateApi();
      const token = await this.getAccessToken();
      logger('### Token', token);

      const isExpired = await this.checkPasswordExpired();
      if (isExpired) {
        return AccountState.PasswordExpired;
      }
    } catch (error) {
      if (
        /User does not exist/i.test(error.message) ||
        /Incorrect username or password/i.test(error.message) ||
        /Password attempts exceeded/i.test(error.message)
      ) {
        throw new Error(locales.get('error_messages.incorrect_login'));
      }
      if (/User account has expired/i.test(error.message)) {
        throw new Error(locales.get('error_messages.account_has_expired'));
      }
      if (/ACCOUNT_LOCKED_IN/i.test(error.message)) {
        throw new Error(locales.get('error_messages.exceed_login_attempts'));
      }

      throw error;
    }

    this.startSessionTimeoutListener();
    return AccountState.SignedIn;
  }

  public async ssoLogin(accessToken: string, idToken: string, refreshToken: string): Promise<AccountState> {
    // Create a CognitoUserSession object from the provided tokens
    const cognitoUserSessionData = {
      IdToken: new CognitoIdToken({ IdToken: idToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken })
    };
    const cognitoUserSession = new CognitoUserSession(cognitoUserSessionData);

    // Create a CognitoUserPool object using config data
    const userPoolData = {
      UserPoolId: config.cognito.userPoolId,
      ClientId: config.cognito.ssoAppClientId
    };
    const userPool = new CognitoUserPool(userPoolData);

    // Create a CognitoUser object with the username extracted from the access token
    const userData = {
      Username: cognitoUserSession.getAccessToken().payload.username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    // Associate the CognitoUserSession with the CognitoUser
    cognitoUser.setSignInUserSession(cognitoUserSession);
    this.cognitoUser = cognitoUser;
    this.storage.setItem('loginType', LoginType.SSO);

    this.authenticateApi();
    this.startSessionTimeoutListener();
    return AccountState.SignedIn;
  }

  // For sso login, invalidate(revoke) refresh token when logout
  public async revokeSsoToken(): Promise<void> {
    // Retrieve the current authenticated user
    this.cognitoUser = await Auth.currentAuthenticatedUser();
    if (this.cognitoUser) {
      // Retrieve the refresh token
      const refreshToken = this.cognitoUser.getSignInUserSession()?.getRefreshToken().getToken();
      if (refreshToken) {
        const clientId = config.cognito.ssoAppClientId;
        // Construct URL parameters for the token revocation request
        const tokenRevokeurl = config.cognito.tokenRevokeDomain;
        const params = new URLSearchParams();
        params.append('token', refreshToken);
        params.append('client_id', clientId);

        // Perform token revocation by sending a POST request to the token revoke endpoint
        try {
          await fetch(tokenRevokeurl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params.toString()
          });
        } catch (error) {
          console.error('Token revoke failed', error); // eslint-disable-line no-console
          throw error;
        }
      }
    }
  }

  public async logout(): Promise<AccountState> {
    // Determine the login type
    const loginType = await this.storage.getItem('loginType');
    // If the login type is SSO, revoke the SSO token
    if (loginType === LoginType.SSO) {
      await this.revokeSsoToken();
    }
    // Remove the login type from storage
    await this.storage.removeItem('loginType');
    await Auth.signOut();
    this.stopSessionTimeoutListener();
    this.notifySessionTimeoutHandlers();
    window.location.href = '/';
    return AccountState.Unknown;
  }

  public async logoutForPasswordChange(): Promise<AccountState> {
    await this.storage.removeItem('loginType');
    await Auth.signOut();
    this.stopSessionTimeoutListener();
    this.notifySessionTimeoutHandlers();
    return AccountState.Unknown;
  }

  public async completeNewPassword(newPassword: string): Promise<AccountState> {
    const user = this.cognitoUser;
    if (!user) {
      throw new Error('Attempt to set new password for non existing Cognito user.');
    }

    if (!this.tempPassword) {
      throw new Error('Attempt to complete the new password without having the temporal one');
    }

    if (newPassword === this.tempPassword) {
      throw new Error(locales.get('error_messages.reused_temp_password'));
    }

    try {
      await Auth.completeNewPassword(user, newPassword, { name: user.getUsername() });

      this.cognitoUser = await Auth.currentAuthenticatedUser();
      const token = await this.getBearerAccessToken();
      this.passwordApi.addGlobalInterceptor(this.interceptor);

      const response = await this.passwordApi.post<IPasswordResponse>(PASSWORD_REGISTER_ENDPOINT, {
        InitialPassword: this.tempPassword,
        CurrentPassword: newPassword,
        AccessToken: token
      });

      this.verifyPasswordResponse(response);

      this.tempPassword = '';
      return AccountState.SignedIn;
    } catch (e) {
      // The call throws error even if password was changed successfully.
      // Ignore error with status code 200 which means password was updated and handle any others.
      logger('New password error handler', e.statusCode, e);
      if (e.code !== 'UnknownError') {
        throw e;
      }

      return AccountState.SignedIn;
    }
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    const user = this.cognitoUser;

    if (!user) {
      throw new Error('Attempt to change the password for non existing Cognito user.');
    }

    const token = await this.getAccessToken();
    this.passwordApi.addGlobalInterceptor(this.interceptor);

    const response = await this.passwordApi.post<IPasswordResponse>(PASSWORD_CHANGE_ENDPOINT, {
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
      AccessToken: token
    });

    this.verifyPasswordResponse(response);
  }

  public addSessionTimeoutHandler(handler: SessionTimeoutHandler): void {
    this.sessionTimeoutHandlers.push(handler);
  }

  private verifyPasswordResponse(response: ApiResponse<IPasswordResponse>) {
    switch (response.status) {
      case 200:
        break;
      case 401:
        throw new Error(locales.get('error_messages.unauthenticated'));
      case 403:
        throw new Error(locales.get('error_messages.unauthorized'));
      case 404:
        throw new Error(locales.get('error_messages.not_found'));
      case 400:
        if (!response.data.error) {
          throw new Error(locales.get('error_messages.connection_error'));
        }

        switch (response.data.error) {
          case PasswordErrorType.InvalidArgs:
            throw new Error(locales.get('error_messages.bad_request'));
          case PasswordErrorType.ReusedPassword:
            throw new Error(locales.get('error_messages.reused_password'));
          case PasswordErrorType.SameInputPassword:
            throw new Error(locales.get('error_messages.same_input_password'));
          case PasswordErrorType.NotAuthorized:
            throw new Error(locales.get('error_messages.incorrect_current_password'));
          case PasswordErrorType.InvalidPassword:
            throw new Error(locales.get('error_messages.invalid'));
          default:
            throw new Error(response.data.error);
        }
      default:
        if (response.status >= 500) {
          throw new Error(locales.get('error_messages.server_error'));
        }
        throw new Error(locales.get('error_messages.connection_error'));
    }
  }

  private interceptor = async (cfg: RequestConfig): Promise<RequestConfig> => {
    if (!this.isSessionExprired()) {
      this.renewSession();
    }

    const token = await this.getBearerAccessToken();
    // eslint-disable-next-line no-param-reassign
    cfg.headers.Authorization = token;
    return cfg;
  };

  private authenticateApi() {
    this.api.addGlobalInterceptor(this.interceptor);
  }

  private async getBearerAccessToken(): Promise<string> {
    const user = this.cognitoUser;

    if (!user) {
      throw new Error('Attempt to get the token for non existing user.');
    }

    const session = await Auth.userSession(user);
    return `Bearer ${session.getIdToken().getJwtToken()}`;
  }

  private async getAccessToken(): Promise<string> {
    const user = this.cognitoUser;

    if (!user) {
      throw new Error('Attempt to get the token for non existing user.');
    }

    const session = await Auth.userSession(user);
    return session.getAccessToken().getJwtToken();
  }

  private async checkPasswordExpired(): Promise<boolean> {
    const user = this.cognitoUser;

    if (!user) {
      throw new Error('Attempt to get the attribute for non existing user.');
    }

    const attributes = await Auth.userAttributes(user);
    const expiresAtAttribute = attributes.find(
      (attribute) => (attribute.getName() === 'custom:password_expires_at')
    );
    if (!expiresAtAttribute) {
      return false;
    }
    const expiresAt = new Date(expiresAtAttribute.getValue());
    const now = new Date();
    return expiresAt.getTime() < now.getTime();
  }

  private notifySessionTimeoutHandlers() {
    this.sessionTimeoutHandlers.forEach((handler) => handler());
  }

  private startSessionTimeoutListener() {
    this.renewSession();

    if (this.sessionInterval) {
      window.clearInterval(this.sessionInterval);
    }

    this.sessionInterval = window.setInterval(this.sessionTimeoutHandler, SESSION_INTERVAL);
  }

  private sessionTimeoutHandler = async () => {
    if (this.isSessionExprired()) {
      await this.logout();
      this.notifySessionTimeoutHandlers();
    }
  };

  private renewSession() {
    this.storage.setItem(SESSION_LAST_TIMESTAMP_KEY, moment().format());
  }

  private stopSessionTimeoutListener() {
    this.storage.removeItem(SESSION_LAST_TIMESTAMP_KEY);

    if (this.sessionInterval) {
      window.clearInterval(this.sessionInterval);
      this.sessionInterval = 0;
    }
  }

  private isSessionExprired(): boolean {
    // Valid if time difference between current, and time in the local storage is less then max idle session length.
    return (new Date().getTime() - this.sessionTimestamp) > config.sessionTimeoutMinutes * 1000 * 60;
  }
}
