import React from 'react';
import classnames from 'classnames';
// Styles
import styles from './styles.scss';

interface IProps {
  label: string;
  index: number;
  active: boolean;
  disabled?: boolean;
  onChange: (newValueIndex: number) => void;
}

export default class Tab extends React.PureComponent<IProps> {
  public pressTab = () => {
    const {
      disabled, active, index, onChange
    } = this.props;
    if (!disabled && !active) {
      onChange(index);
    }
  };

  public render() {
    const { active, disabled, label } = this.props;
    return (
      <div
        className={classnames(styles.tab, { [styles.active]: active, [styles.disabled]: disabled })}
        onClick={this.pressTab}
      >
        {label}
      </div>
    );
  }
}
