import qs from 'qs';
// Interfaces
import Pagination from '../Pagination';
import { ILoadCustomersResponse } from '../CustomersService/CustomersDao';
// Services
import ApiService from '../ApiService';
// Models
import TrustAccountsDao, { ILoadClaimsResponse, ILoadTransactionsResponse } from './TrustAccountsDao';
import TrustAccountModel, { ITrustAccountObject } from '../../models/TrustAccountModel';
import TransactionModel, { ITransactionObject } from '../../models/TransactionModel';
import ClaimModel, { IClaimObject } from '../../models/ClaimModel';
import CustomerModel, { ICustomerObject } from '../../models/CustomerModel';

const TRUST_ACCOUNTS_ENDPOINT = '/admin/trust_accounts';

interface ITransactionsResponse extends Pagination {
  transactions: ITransactionObject[];
}

interface IClaimsResponse extends Pagination {
  claims: IClaimObject[];
}

interface ICustomersResponse extends Pagination {
  customers: ICustomerObject[];
}

export default class TrustAccountsDaoImpl implements TrustAccountsDao {
  public api: ApiService;

  constructor(api: ApiService) {
    this.api = api;
  }

  public async loadTrustAccountById(id: number): Promise<TrustAccountModel> {
    const response = await this.api.get<ITrustAccountObject>(`${TRUST_ACCOUNTS_ENDPOINT}/${id}`);
    return new TrustAccountModel(response.data);
  }

  public async loadTransactionsById(
    id: number, page: number, showVaultAccount: boolean
  ): Promise<ILoadTransactionsResponse> {
    const query = qs.stringify({ page, show_vault_account: showVaultAccount });
    const response = await this.api
      .get<ITransactionsResponse>(`${TRUST_ACCOUNTS_ENDPOINT}/${id}/transactions?${query}`);
    const transactions = response.data.transactions
      .map((transaction) => new TransactionModel(transaction));
    return {
      transactions,
      pagination: response.data.pagination
    };
  }

  public async loadClaimsById(id: number, page: number): Promise<ILoadClaimsResponse> {
    const query = qs.stringify({ page });
    const response = await this.api
      .get<IClaimsResponse>(`${TRUST_ACCOUNTS_ENDPOINT}/${id}/claims?${query}`);
    const claims = response.data.claims.map((claim) => new ClaimModel(claim));
    return {
      claims,
      pagination: response.data.pagination
    };
  }

  public async loadCustomersById(id: number, page: number): Promise<ILoadCustomersResponse> {
    const query = qs.stringify({ page });
    const response = await this.api
      .get<ICustomersResponse>(`${TRUST_ACCOUNTS_ENDPOINT}/${id}/customers?${query}`);
    const customers = response.data.customers.map((customer) => new CustomerModel(customer));
    return {
      customers,
      pagination: response.data.pagination
    };
  }

  public async toggleApplicationLock(id: number, locked: boolean) {
    await this.api.put(`${TRUST_ACCOUNTS_ENDPOINT}/${id}/lock`, { locked });
  }

  public async downloadQr(id: number) {
    const response = await this.api.post<ArrayBuffer>(
      `${TRUST_ACCOUNTS_ENDPOINT}/${id}/invitations`,
      null,
      { responseType: 'arraybuffer' }
    );
    return new Blob([response.data], { type: 'application/pdf' });
  }
}
