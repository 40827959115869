import React from 'react';
import {
  HashRouter as Router, Redirect, Route, Switch
} from 'react-router-dom';
import { observer } from 'mobx-react';
// Components
import UsersManager from '../UsersManager';
import CustomersManager from '../CustomersManager';
import ClaimsManager from '../ClaimsManager';
import DashboardManager from '../DashboardManager';
import ChangePassword from '../ChangePassword';
import ProductSelection from '../ProductSelection';
import Header from '../../components/Header';
import NavLink from '../../components/NavLink';
import MenuButton from '../../components/MenuButton';
import NavMenu from '../../components/NavMenu';
import Page from '../../components/Page';
import MenuLink from '../../components/MenuLink';
// Services
import AccountService, { AccountPermissions } from '../../services/AccountService';
import DialogService, { DialogType } from '../../services/DialogService';
import SettingsService from '../../services/SettingsService';
import ApiService from '../../services/ApiService';
// Helpers
import locales from '../../helpers/locales';

interface IProps {
  accountService: AccountService;
  dialogService: DialogService;
  settingsService: SettingsService;
  apiService: ApiService;
}

@observer
export default class AuthenticatedPage extends React.Component<IProps> {
  public confirmLogout = async () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('logout.confirmation'),
      onConfirm: this.logout
    });
  };

  public logout = async () => {
    const { accountService } = this.props;
    accountService.logout();
  };

  public renderEmptyHeader() {
    const { settingsService } = this.props;
    return (
      <Header settingsService={settingsService} />
    );
  }

  public renderHeader() {
    const { accountService, settingsService } = this.props;
    const showUsers = accountService.hasAccountPermission(AccountPermissions.ViewUsers);
    const showDailyProcess =
      accountService.hasAccountPermission(AccountPermissions.ViewDailyProcess);
    const showReport = accountService.hasAccountPermission(AccountPermissions.ViewReport);
    const showSendNotification =
      accountService.hasAccountPermission(AccountPermissions.ViewSendNotification);
    const showDashboard = showDailyProcess || showReport || showSendNotification;
    const showClaims = accountService.hasAccountPermission(AccountPermissions.ViewClaimsList);
    const showCustomers = accountService.hasAccountPermission(AccountPermissions.ViewCustomers);

    return (
      <Header settingsService={settingsService}>
        <NavLink
          label={locales.get('navigation.customers_management')}
          to="/customers"
          disabled={!showCustomers}
        />
        <NavLink
          label={locales.get('navigation.claims_management')}
          to="/claims"
          disabled={!showClaims}
        />
        <NavLink
          label={locales.get('navigation.users_management')}
          to="/users"
          disabled={!showUsers}
        />
        <NavMenu label={locales.get('navigation.dashboard')} disabled={!showDashboard}>
          <MenuLink
            label={locales.get('navigation.daily_process')}
            to="/dashboard/daily"
            disabled={!showDailyProcess}
          />
          <MenuLink
            label={locales.get('navigation.reports')}
            to="/dashboard/reports"
            disabled={!showReport}
          />
          <MenuLink
            label={locales.get('navigation.notification')}
            to="/dashboard/notification"
            disabled={!showSendNotification}
          />
        </NavMenu>
        <NavMenu label={locales.get('navigation.account_settings')}>
          <MenuLink label={locales.get('navigation.change_password')} to="/change-password" />
          <MenuButton label={locales.get('navigation.logout')} onClick={this.confirmLogout} />
        </NavMenu>
      </Header>
    );
  }

  public renderRoutes(): React.ReactNodeArray {
    let defaultPage = '';
    const { accountService, settingsService } = this.props;

    const routes = [
      <Route key="change-password" path="/change-password" component={ChangePassword} />,
      <Route key="product-selection" path="/product-selection" component={ProductSelection} />
    ];

    const showCustomers = accountService.hasAccountPermission(AccountPermissions.ViewCustomers);
    if (showCustomers) {
      routes.push(<Route key="customers" path="/customers" component={CustomersManager} />);
    }

    if (
      accountService.hasAccountPermission(AccountPermissions.ViewClaimsList) ||
      accountService.hasAccountPermission(AccountPermissions.ViewClaimDetails)
    ) {
      routes.push(<Route key="claims" path="/claims" component={ClaimsManager} />);
    }

    if (accountService.hasAccountPermission(AccountPermissions.ViewUsers)) {
      routes.push(<Route key="/users" path="/users" component={UsersManager} />);
    }

    const showDashboard =
      accountService.hasAccountPermission(AccountPermissions.ViewDailyProcess) ||
      accountService.hasAccountPermission(AccountPermissions.ViewReport) ||
      accountService.hasAccountPermission(AccountPermissions.ViewSendNotification);

    if (showDashboard) {
      routes.push(<Route key="dashboard" path="/dashboard" component={DashboardManager} />);
    }

    if (settingsService.isProductSelected) {
      defaultPage = showCustomers ? '/customers' : '/users';
    } else {
      defaultPage = '/product-selection';
    }

    routes.push(<Redirect key="default" to={defaultPage} />);

    return routes;
  }

  public render() {
    const { settingsService } = this.props;

    return (
      <>
        {settingsService.productSelectionInProgress ? this.renderEmptyHeader() : this.renderHeader()}
        <Page>
          <Router>
            <Switch>
              {this.renderRoutes()}
            </Switch>
          </Router>
        </Page>
      </>
    );
  }
}
