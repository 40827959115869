import React from 'react';
import { inject, observer } from 'mobx-react';
// Containers/Components
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import Button from '../../../components/Button';
import Footer from '../../../components/Footer';
import TabContent from '../../../components/TabContent';
import CustomersList from './CustomersList';
import Filter from '../../../components/Filter';
import FilterField from '../../../components/FilterField';
import FilterButton from '../../../components/FilterButton';
import InvitationsList from './InvitationsList';
// Services
import AccountService, { AccountPermissions } from '../../../services/AccountService';
import CustomersService from '../../../services/CustomersService';
import InvitationsService from '../../../services/InvitationsService';
import FormService from '../../../services/FormService';
import DialogService, { DialogType } from '../../../services/DialogService';
// Models
import { TrustAccountLinkStatus } from '../../../models/TrustAccountModel';
// Locales
import locales from '../../../helpers/locales';
import { convertZenkakuToHankaku } from '../../../helpers/convert';
// Styles
import styles from './styles.scss';

enum TabType {
  Customers = 'customers_list',
  Invitations = 'invitations_list',
  PendingQr = 'pending_qr'
}

const TAB_TYPES = [
  TabType.Customers,
  TabType.Invitations,
  TabType.PendingQr
];

const FILTER_BRANCH_FIELD = 'customers.branch_code';
const FILTER_TORIHIKI_FIELD = 'customers.bank_torihiki_number';
const FILTER_ACCOUNT_FIELD = 'customers.account_number';
const FILTER_KANJI_FIELD = 'customers.name_kanji';
const FILTER_KANA_FIELD = 'customers.name_kana';

interface IProps {
  accountService: AccountService;
  customersService: CustomersService;
  invitationsService: InvitationsService;
  dialogService: DialogService;
}

interface IState {
  currentTabIndex: number;
}

@inject('accountService', 'customersService', 'invitationsService', 'dialogService')
@observer
export default class CustomersListView extends React.Component<IProps, IState> {
  private form: FormService;

  constructor(props: IProps) {
    super(props);
    this.form = new FormService(true)
      .setField(FILTER_BRANCH_FIELD, 'numeric')
      .setField(FILTER_TORIHIKI_FIELD, 'numeric')
      .setField(FILTER_ACCOUNT_FIELD, 'numeric')
      .setField(FILTER_KANJI_FIELD)
      .setField(FILTER_KANA_FIELD);
    this.state = {
      currentTabIndex: 0
    };
  }

  public componentDidMount() {
    const { customersService } = this.props;
    customersService.loadCustomers({ page: 1 }).catch(this.handleError);
  }

  private getCustomersSearchParams(page: number) {
    return {
      page,
      branchCode: this.form.getValue(FILTER_BRANCH_FIELD),
      bankTorihikiNumber: this.form.getValue(FILTER_TORIHIKI_FIELD),
      accountNumber: this.form.getValue(FILTER_ACCOUNT_FIELD),
      customerNameKanji: this.form.getValue(FILTER_KANJI_FIELD),
      customerNameKana: this.form.getValue(FILTER_KANA_FIELD)
    };
  }

  private getInvitationsSearchParams(page: number) {
    return {
      page,
      trustAccountNumber: this.form.getValue(FILTER_ACCOUNT_FIELD),
      inviteeNameKanji: this.form.getValue(FILTER_KANJI_FIELD)
    };
  }

  private handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  private search = () => {
    const { currentTabIndex } = this.state;
    this.searchByTab(TAB_TYPES[currentTabIndex], 1);
  };

  private clear = () => {
    this.form.reset();
    this.search();
  };

  private printQr = () => {
    const { customersService } = this.props;
    const { customers } = customersService;
    customersService.downloadQrByCustomers(customers).catch(this.handleError);
  };

  private completeQr = async () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    try {
      await customersService.completeQrByCustomers(customers);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('customers.messages.complete_qr_success'),
        onClose: this.search
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  private confirmCompleteQr = async () => {
    const { dialogService } = this.props;

    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('customers.messages.complete_qr_confirmation'),
      onConfirm: this.completeQr
    });
  };

  private changeTab = (newTabIndex: number) => {
    this.setState({ currentTabIndex: newTabIndex });
    this.searchByTab(TAB_TYPES[newTabIndex], 1);
  };

  private onPageChange = (page: number) => {
    const { currentTabIndex } = this.state;
    this.searchByTab(TAB_TYPES[currentTabIndex], page);
  };

  private updateFormValue = (value: string, name?: string) => {
    if (!name) {
      throw new Error('FilterField must have name prop in this component');
    }
    this.form.setValue(name, value);
  };

  private updateFormValueWithHankaku = (value: string, name?: string) => {
    if (!name) {
      throw new Error('FilterField must have name prop in this component');
    }
    this.form.setValue(name, convertZenkakuToHankaku(value));
  };

  private searchByTab(tabType: TabType, page: number) {
    const { customersService, invitationsService } = this.props;
    switch (tabType) {
      case TabType.Customers:
        customersService.loadCustomers(this.getCustomersSearchParams(page))
          .catch(this.handleError);
        break;
      case TabType.Invitations:
        invitationsService.loadInvitations(this.getInvitationsSearchParams(page))
          .catch(this.handleError);
        break;
      case TabType.PendingQr:
        customersService.loadCustomers({
          page,
          trustAccountLinkStatus: TrustAccountLinkStatus.Initial
        }).catch(this.handleError);
        break;
      default:
        throw new Error('Invalid TabType was specified');
    }
  }

  private renderFilter() {
    const { currentTabIndex } = this.state;
    const isPendingQr = TAB_TYPES[currentTabIndex] === TabType.PendingQr;
    const isInvitations = TAB_TYPES[currentTabIndex] === TabType.Invitations;

    const isClearDisabled = isPendingQr || !(
      this.form.getValue(FILTER_BRANCH_FIELD) ||
      this.form.getValue(FILTER_TORIHIKI_FIELD) ||
      this.form.getValue(FILTER_ACCOUNT_FIELD) ||
      this.form.getValue(FILTER_KANJI_FIELD) ||
      this.form.getValue(FILTER_KANA_FIELD)
    );

    return (
      <Filter className={styles.filter}>
        <FilterField
          flex="0 0 80px"
          name={FILTER_BRANCH_FIELD}
          placeholder={locales.get('customers.filter.branch_code')}
          onChange={this.updateFormValueWithHankaku}
          value={this.form.getValue(FILTER_BRANCH_FIELD)}
          disabled={isPendingQr || isInvitations}
        />
        <FilterField
          flex="0 0 140px"
          name={FILTER_TORIHIKI_FIELD}
          placeholder={locales.get('customers.filter.bank_torihiki_number')}
          onChange={this.updateFormValueWithHankaku}
          value={this.form.getValue(FILTER_TORIHIKI_FIELD)}
          disabled={isPendingQr || isInvitations}
        />
        <FilterField
          flex="0 0 140px"
          name={FILTER_ACCOUNT_FIELD}
          placeholder={locales.get('customers.filter.account_number')}
          onChange={this.updateFormValueWithHankaku}
          value={this.form.getValue(FILTER_ACCOUNT_FIELD)}
          disabled={isPendingQr}
        />
        <FilterField
          flex="0 0 140px"
          name={FILTER_KANJI_FIELD}
          onChange={this.updateFormValue}
          placeholder={locales.get('customers.filter.customer_name_kanji')}
          value={this.form.getValue(FILTER_KANJI_FIELD)}
          disabled={isPendingQr}
        />
        <FilterField
          flex="0 0 140px"
          name={FILTER_KANA_FIELD}
          onChange={this.updateFormValue}
          placeholder={locales.get('customers.filter.customer_name_kana')}
          value={this.form.getValue(FILTER_KANA_FIELD)}
          disabled={isPendingQr || isInvitations}
        />
        <FilterButton
          className={styles.search}
          iconName="search"
          label={locales.get('customers.filter.search')}
          onClick={this.search}
          disabled={isPendingQr}
        />
        <FilterButton
          className={styles.clear}
          iconName="times"
          label={locales.get('customers.filter.clear')}
          onClick={this.clear}
          disabled={isClearDisabled}
        />
      </Filter>
    );
  }

  private renderTabs() {
    return (
      <Tabs>
        {
          TAB_TYPES.map((type, index) => {
            const { customersService } = this.props;
            const { currentTabIndex } = this.state;
            return (
              <Tab
                key={type}
                index={index}
                onChange={this.changeTab}
                disabled={customersService.pendingCustomers}
                active={currentTabIndex === index}
                label={locales.get(`customers.tabs.${type.valueOf()}`)}
              />
            );
          })
        }
      </Tabs>
    );
  }

  private renderList() {
    const { customersService, invitationsService } = this.props;
    const { currentTabIndex } = this.state;
    switch (TAB_TYPES[currentTabIndex]) {
      case TabType.Customers:
      case TabType.PendingQr:
        return (
          <CustomersList
            customers={customersService.customers.peek()}
            loading={customersService.pendingCustomers}
            currentPage={customersService.customersCurrentPage}
            pagesCount={customersService.customersPagesCount}
            onPageChange={this.onPageChange}
          />
        );
      case TabType.Invitations:
        return (
          <InvitationsList
            invitations={invitationsService.invitations}
            loading={invitationsService.pendingInvitations}
            currentPage={invitationsService.currentPage}
            pagesCount={invitationsService.pagesCount}
            onPageChange={this.onPageChange}
          />
        );
      default:
        throw new Error('Invalid TabType was specified');
    }
  }

  private renderButtons() {
    const { currentTabIndex } = this.state;
    if (TAB_TYPES[currentTabIndex] !== TabType.PendingQr) {
      return null;
    }
    const { customersService, accountService } = this.props;
    const isNoAwaiting = customersService.customers.length === 0;
    const canPrintQr = accountService.hasAccountPermission(AccountPermissions.PrintAwaitingQr);
    return (
      <>
        <Button
          label={locales.get('customers.print_qr')}
          disabled={customersService.pendingCustomers || !canPrintQr || isNoAwaiting}
          onClick={this.printQr}
        />
        <Button
          label={locales.get('customers.complete_qr')}
          disabled={customersService.pendingCustomers || !canPrintQr || isNoAwaiting}
          onClick={this.confirmCompleteQr}
        />
      </>
    );
  }

  public render() {
    return (
      <>
        {this.renderFilter()}
        {this.renderTabs()}
        <TabContent>
          {this.renderList()}
        </TabContent>
        <Footer>
          {this.renderButtons()}
        </Footer>
      </>
    );
  }
}
