import React from 'react';
import classnames from 'classnames';

import locales from '../../helpers/locales';

// Styles
import styles from './styles.scss';

const SHOW_LABEL = locales.get('password_field.show');
const HIDE_LABEL = locales.get('password_field.hide');

export enum InputFieldType {
  Text = 'text',
  Password = 'password'
}

interface IProps {
  className?: string;
  type: InputFieldType;
  label?: string;
  name?: string;
  value: string;
  error?: string;
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onBlur: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

interface IState {
  isTyping: boolean;
  showPassword?: boolean;
}

export default class InputField extends React.Component<IProps, IState> {
  static defaultProps = {
    label: '',
    name: '',
    error: '',
    onFocus: () => undefined
  };

  constructor(props: IProps) {
    super(props);
    this.state = { isTyping: false, showPassword: false };
  }

  get shouldShowMessage() {
    const { error } = this.props;
    const { isTyping } = this.state;
    return error && !isTyping;
  }

  public getInputType() {
    const { type } = this.props;
    const { showPassword } = this.state;
    if (type === InputFieldType.Password) {
      return showPassword ? InputFieldType.Text : InputFieldType.Password;
    }
    return InputFieldType.Text;
  }

  public togglePasswordVisibility = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  public onBlur = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ isTyping: false });
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
  };

  public onFocus = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ isTyping: true });
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  public renderLabel = () => {
    const { label } = this.props;
    if (!label) {
      return null;
    }
    return (
      <div className={styles.label}>{label}</div>
    );
  };

  public renderButton = () => {
    const { type } = this.props;
    const { showPassword } = this.state;
    if (type !== InputFieldType.Password) {
      return null;
    }
    const label = showPassword ? HIDE_LABEL : SHOW_LABEL;
    return (
      <div className={styles.toggle}>
        <button
          type="button"
          className={styles.passwordButton}
          onMouseDown={this.togglePasswordVisibility}
          tabIndex={-1}
        >
          {label}
        </button>
      </div>
    );
  };

  public renderMessage = () => {
    const { error } = this.props;
    if (!this.shouldShowMessage) {
      return null;
    }
    return (
      <div className={styles.message}>{error}</div>
    );
  };

  public render() {
    const {
      className, name, value, onChange
    } = this.props;
    return (
      <div
        className={classnames(styles.field, className, { [styles.error]: this.shouldShowMessage })}
      >
        {this.renderLabel()}
        <div className={styles.container}>
          <input
            className={styles.text}
            type={this.getInputType()}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          {this.renderButton()}
        </div>
        {this.renderMessage()}
      </div>
    );
  }
}
