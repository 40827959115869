import React from 'react';

import styles from './styles.scss';

interface IProps {
  children?: React.ReactNode;
}

export default class Header extends React.PureComponent<IProps> {
  public render() {
    const { children } = this.props;
    return (
      <div className={styles.page}>
        <div className={styles.inner}>
          {children}
        </div>
      </div>
    );
  }
}
