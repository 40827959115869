import React from 'react';
import classnames from 'classnames';

// Styles
import styles from './styles.scss';

const MESSAGE_HEIGHT = 25;

interface IProps {
  className?: string;
  textAreaHeight: number;
  name?: string;
  placeholder?: string;
  value: string;
  error?: string;
  onChange: (event: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.SyntheticEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.SyntheticEvent<HTMLTextAreaElement>) => void;
}

interface IState {
  isTyping: boolean;
}

export default class TextAreaField extends React.Component<IProps, IState> {
  static defaultProps = {
    name: '',
    placeholder: '',
    error: '',
    onFocus: () => undefined,
    onBlur: () => undefined
  };

  constructor(props: IProps) {
    super(props);
    this.state = { isTyping: false };
  }

  get shouldShowMessage() {
    const { error } = this.props;
    const { isTyping } = this.state;
    return error && !isTyping;
  }

  public onBlur = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    this.setState({ isTyping: false });
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
  };

  public onFocus = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    this.setState({ isTyping: true });
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  public renderMessage = () => {
    const { error } = this.props;
    if (!this.shouldShowMessage) {
      return null;
    }
    return (
      <div className={styles.message} style={{ height: MESSAGE_HEIGHT }}>{error}</div>
    );
  };

  public render() {
    const {
      className, textAreaHeight, name, placeholder, value, onChange
    } = this.props;
    const height = this.shouldShowMessage ? textAreaHeight : textAreaHeight + MESSAGE_HEIGHT;
    return (
      <div
        className={classnames(styles.field, className, { [styles.message]: this.shouldShowMessage })}
      >
        <div className={styles.container} style={{ height }}>
          <textarea
            className={styles.text}
            style={{ height: textAreaHeight }}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        </div>
        {this.renderMessage()}
      </div>
    );
  }
}
