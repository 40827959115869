// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ClaimDetailsEvents_eventItem_3x7Cr {\n  margin-bottom: 10px; }\n  .ClaimDetailsEvents_eventItem_3x7Cr .ClaimDetailsEvents_eventItemSub_G3sn8 {\n    font-size: 14px;\n    color: #AAA; }\n  .ClaimDetailsEvents_eventItem_3x7Cr .ClaimDetailsEvents_eventSource_3sGKJ {\n    color: #AAA; }\n", "",{"version":3,"sources":["styles.scss","../../../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EACE,mBAAmB,EAAA;EADrB;IAII,eAAe;IACf,WCKiB,EAAA;EDVrB;IASI,WCCiB,EAAA","file":"styles.scss","sourcesContent":["@import \"../../../../styles/theme\";\n\n.eventItem {\n  margin-bottom: 10px;\n\n  .eventItemSub {\n    font-size: 14px;\n    color: $text-sub-color;\n  }\n\n  .eventSource {\n    color: $text-sub-color;\n  }\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"eventItem": "ClaimDetailsEvents_eventItem_3x7Cr",
	"eventItemSub": "ClaimDetailsEvents_eventItemSub_G3sn8",
	"eventSource": "ClaimDetailsEvents_eventSource_3sGKJ"
};
module.exports = exports;
