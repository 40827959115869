import React from 'react';
// Components
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import DescriptionList, { DescriptionListSize } from '../../../components/DescriptionList';
// Models
import TrustAccountModel from '../../../models/TrustAccountModel';
import PaymentAccountModel from '../../../models/PaymentAccountModel';
// Helpers
import locales from '../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps {
  isOpen: boolean;
  account: TrustAccountModel;
  onClickLink: () => void;
  onCloseModal: () => void;
}

export default class AccountInformation extends React.PureComponent<IProps> {
  public renderPaymentAccounts() {
    const { account: { paymentAccounts } } = this.props;
    return paymentAccounts.map((paymentAccount: PaymentAccountModel, index: number) => (
      <div key={paymentAccount.accountNumber} className="row">
        <div className="cell">
          {locales.get('general.trust_account.payment_account', { number: index + 1 })}
        </div>
        <div className="cell">
          <div>
            {paymentAccount.bankName}
          </div>
          <div>
            {`${paymentAccount.branchName} (${paymentAccount.branchCode})`}
          </div>
          <div>
            {`${paymentAccount.accountType} ${paymentAccount.accountNumberString}`}
          </div>
          <div>
            {paymentAccount.accountName}
          </div>
        </div>
      </div>
    ));
  }

  public renderAmountValue(amount: number, amountString: string) {
    return amount < 0 ? <div className={styles.minus}>{amountString}</div> : amountString;
  }

  public renderModal() {
    const { account, onCloseModal } = this.props;
    const initialTrustAmount = this.renderAmountValue(
      account.initialTrustAmount, account.initialTrustAmountString
    );
    const totalTrustAmount = this.renderAmountValue(
      account.totalTrustAmount, account.totalTrustAmountString
    );
    const automaticPaymentAmount = this.renderAmountValue(
      account.automaticPaymentAmount, account.automaticPaymentAmountString
    );

    return (
      <Modal closable isOpen onClose={onCloseModal}>
        <div className={styles.information}>
          <Title className={styles.title}>{locales.get('account.account_information')}</Title>
          <div className={styles.descriptions}>
            <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Small}>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.product')}
                </div>
                <div className="cell">{locales.get(`general.trust_account_product.${account.productType}`)}</div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.contract_number')}
                </div>
                <div className="cell">{account.contractNumber}</div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.trust_account.name_kanji')}</div>
                <div className="cell">{account.nameKanji}</div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.trust_account.name_kana')}</div>
                <div className="cell">{account.nameKana}</div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.trust_account.branch_code')}</div>
                <div className="cell">
                  {`${account.branchCode} (${account.branchName})`}
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.bank_torihiki_number')}
                </div>
                <div className="cell">{account.bankTorihikiNumber}</div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.trust_account.main_account_number')}</div>
                <div className="cell">{account.number}</div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.trust_account.main_account_balance')}</div>
                <div className="cell">
                  {this.renderAmountValue(account.balance, account.balanceString)}
                </div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.vault_account.number')}</div>
                <div className="cell">{account.vaultAccount?.number}</div>
              </div>
              <div className="row">
                <div className="cell">{locales.get('general.vault_account.balance')}</div>
                <div className="cell">
                  {
                    account.vaultAccount ?
                      this.renderAmountValue(account.vaultAccount.balance, account.vaultAccount.balanceString) :
                      ''
                  }
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.initial_trust_amount')}
                </div>
                <div className="cell">{initialTrustAmount}</div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.total_trust_amount')}
                </div>
                <div className="cell">{totalTrustAmount}</div>
              </div>
            </DescriptionList>
            <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Small}>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.contract_start_date')}
                </div>
                <div className="cell">
                  {account.contractStartDateString}
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.contract_end_date')}
                </div>
                <div className="cell">
                  {account.contractEndDateString}
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.contract_suspended_date')}
                </div>
                <div className="cell">
                  {account.contractSuspendedDateString}
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.account_owner_death_date')}
                </div>
                <div className="cell">
                  {account.accountOwnerDeathDateString}
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  {locales.get('general.trust_account.automatic_payment_amount')}
                </div>
                <div className="cell">{automaticPaymentAmount}</div>
              </div>
              {this.renderPaymentAccounts()}
            </DescriptionList>
          </div>
        </div>
      </Modal>
    );
  }

  public render() {
    const { onClickLink, isOpen } = this.props;
    return (
      <>
        <div className={styles.account} onClick={onClickLink}>
          <span className="mutb-account-icon" />
          &nbsp;
          {locales.get('account.show_account_info')}
        </div>
        {isOpen && this.renderModal()}
      </>
    );
  }
}
