import AdminUserModel, { IAdminUserObject } from './AdminUserModel';

export interface IClaimCommentObject {
  id: number;
  admin_user: IAdminUserObject;
  comment: string;
  created_at: string;
  updated_at: string;
}

export default class ClaimCommentModel {
  public id: number;
  public adminUser: AdminUserModel;
  public comment: string;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(json: IClaimCommentObject) {
    this.id = json.id;
    this.adminUser = new AdminUserModel(json.admin_user);
    this.comment = json.comment;
    this.createdAt = new Date(json.created_at);
    this.updatedAt = new Date(json.updated_at);
  }
}
