import React from 'react';
import classnames from 'classnames';
// Components
// Models
import ClaimModel from '../../../../models/ClaimModel';
// Helpers
import locales from '../../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  claim: ClaimModel;
}

export default class ClaimDetailsFamilyReactions extends React.PureComponent<IProps> {
  public render() {
    const { className, claim } = this.props;

    const confirmations = claim.claimConfirmations.map((confirmation) => (
      <div key={confirmation.id} className={styles.reactionItem}>
        <div>
          {`${confirmation.nameKanji} ${locales.get('claim.confirmations.confirmed')}`}
        </div>
        <div className={styles.sub}>{confirmation.createdAtString}</div>
      </div>
    ));

    return (
      <div className={classnames(className, styles.family)}>
        <div className={styles.heading}>
          {locales.get('claim.family_reactions')}
        </div>
        <div className={styles.familyInner}>
          {confirmations}
        </div>
      </div>
    );
  }
}
