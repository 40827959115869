// Services
import config from 'config.json';
import CustomersService from './CustomersService';
import UsersService from './UsersService';
import AccountService from './AccountService';
import SettingsService from './SettingsService';
import ClaimsService from './ClaimsService';
import ApiService from './ApiService';
import StorageService from './StorageService';
import TrustAccountsService from './TrustAccountsService';
import DialogService from './DialogService';
import InvitationsService from './InvitationsService';
import BatchService from './BatchService';
import NotificationsService from './NotificationsService';
// DAO
import ClaimsDaoImpl from './ClaimsService/ClaimsDaoImpl';
import AccountDaoImpl from './AccountService/AccountDaoImpl';
import StorageDaoImpl from './StorageService/StorageDaoImpl';
import UsersDaoImpl from './UsersService/UsersDaoImpl';
import CustomersDaoImpl from './CustomersService/CustomersDaoImpl';
import InvitationsDaoImpl from './InvitationsService/InvitationsDaoImpl';
import TrustAccountsDaoImpl from './TrustAccountsService/TrustAccountsDaoImpl';
import BatchDaoImpl from './BatchService/BatchDaoImpl';
import NotificationsDaoImpl from './NotificationsService/NotificationsDaoImpl';
// Error Handler
import sharedServicesErrorHandler from './ApiService/sharedServicesErrorHandler';
// Config

const apiService = new ApiService(config.api.url);
apiService.addResponseInterceptor(async (response) => response, sharedServicesErrorHandler);

const storageService = new StorageService(new StorageDaoImpl());
const dialogService = new DialogService();
const accountDao = new AccountDaoImpl(apiService, storageService);
const accountService = new AccountService(accountDao);
const settingsService = new SettingsService();
const usersDao = new UsersDaoImpl(apiService);
const customersDao = new CustomersDaoImpl(apiService, settingsService);
const invitationsDao = new InvitationsDaoImpl(apiService);
const trustAccountsDao = new TrustAccountsDaoImpl(apiService);
const claimsDao = new ClaimsDaoImpl(apiService);

const services = {
  apiService,
  storageService,
  dialogService,
  accountService,
  settingsService,
  usersService: new UsersService(usersDao, accountService),
  customersService: new CustomersService(customersDao, accountService, settingsService),
  invitationsService: new InvitationsService(invitationsDao),
  claimsService: new ClaimsService(claimsDao, accountService),
  trustAccountsService: new TrustAccountsService(trustAccountsDao, accountService, settingsService),
  batchService: new BatchService(new BatchDaoImpl(apiService), settingsService),
  notificationsService: new NotificationsService(new NotificationsDaoImpl(apiService))
};

export default services;
