// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".List_link_2Qyj9 {\n  color: var(--primary-color);\n  cursor: pointer; }\n\n.List_positive_2LuW8 {\n  color: #7ED321; }\n\n.List_negative_3XI8f {\n  color: var(--primary-color); }\n\n.List_minus_p7vNU::first-letter {\n  font-size: 25px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,2BAA2B;EAC3B,eAAe,EAAA;;AAGjB;EACE,cAAc,EAAA;;AAGhB;EACE,2BAA2B,EAAA;;AAG7B;EAEI,eAAe,EAAA","file":"styles.scss","sourcesContent":["@import \"../../../../styles/theme\";\n\n.link {\n  color: var(--primary-color);\n  cursor: pointer;\n}\n\n.positive {\n  color: #7ED321;\n}\n\n.negative {\n  color: var(--primary-color);\n}\n\n.minus {\n  &::first-letter {\n    font-size: 25px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"link": "List_link_2Qyj9",
	"positive": "List_positive_2LuW8",
	"negative": "List_negative_3XI8f",
	"minus": "List_minus_p7vNU"
};
module.exports = exports;
