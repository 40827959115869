import React from 'react';
import { inject, observer } from 'mobx-react';
// Containers/Components
import Button, { ButtonType } from '../../components/Button';
import Title from '../../components/Title';
// Services
import BatchService from '../../services/BatchService';
import DialogService, { DialogType } from '../../services/DialogService';
// Helpers
import locales from '../../helpers/locales';
// Styles
import styles from './styles.scss';
import Loader from '../Loader';

interface IProps {
  batchService: BatchService;
  dialogService: DialogService;
}

@inject('batchService', 'dialogService')
@observer
export default class ReportsView extends React.Component<IProps> {
  private downloadKPIs = () => {
    const { batchService } = this.props;
    batchService.downloadKPIs().catch(this.handleError);
  };

  private downloadClaims = () => {
    const { batchService } = this.props;
    batchService.downloadClaims().catch(this.handleError);
  };

  private downloadCustomers = () => {
    const { batchService } = this.props;
    batchService.downloadCustomers().catch(this.handleError);
  };

  private handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public render() {
    const { batchService: { pendingBatch: loading } } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <div className={styles.reports}>
          <Title className={styles.title}>{locales.get('reports.title')}</Title>
          <div className={styles.buttons}>
            <Button
              type={ButtonType.Round}
              label={locales.get('reports.download_statuses')}
              onClick={this.downloadKPIs}
            />
            <Button
              type={ButtonType.Round}
              label={locales.get('reports.download_claims')}
              onClick={this.downloadClaims}
            />
            <Button
              type={ButtonType.Round}
              label={locales.get('reports.download_customers')}
              onClick={this.downloadCustomers}
            />
          </div>
        </div>
      </>
    );
  }
}
