// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ChangePassword_password_2wM09 {\n  padding-bottom: 40px; }\n  .ChangePassword_password_2wM09 .ChangePassword_title_3L-zz {\n    margin-top: 39px;\n    margin-bottom: 34px; }\n  .ChangePassword_password_2wM09 .ChangePassword_descriptionList_3SKiR {\n    width: 580px;\n    margin-bottom: 50px; }\n    .ChangePassword_password_2wM09 .ChangePassword_descriptionList_3SKiR .ChangePassword_descriptionTitle_YRUwx {\n      width: 120px; }\n  .ChangePassword_password_2wM09 .ChangePassword_inputs_2L666 {\n    width: 580px; }\n  .ChangePassword_password_2wM09 .ChangePassword_form_2RCOA {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%; }\n  .ChangePassword_password_2wM09 .ChangePassword_buttons_3xR8C {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 30px; }\n    .ChangePassword_password_2wM09 .ChangePassword_buttons_3xR8C button {\n      width: 300px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EAGE,oBAAoB,EAAA;EAHtB;IAMI,gBAAgB;IAChB,mBAAmB,EAAA;EAPvB;IAeI,YAdgB;IAehB,mBAAmB,EAAA;IAhBvB;MAYM,YAAY,EAAA;EAZlB;IAoBI,YAnBgB,EAAA;EADpB;IAwBI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW,EAAA;EA3Bf;IA+BI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB,EAAA;IAlCpB;MAqCM,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.password {\n  $form-width: 580px;\n\n  padding-bottom: 40px;\n\n  .title {\n    margin-top: 39px;\n    margin-bottom: 34px;\n  }\n\n  .descriptionList {\n    .descriptionTitle {\n      width: 120px;\n    }\n\n    width: $form-width;\n    margin-bottom: 50px;\n  }\n\n  .inputs {\n    width: $form-width;\n  }\n\n  .form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n  }\n\n  .buttons {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 30px;\n\n    button {\n      width: 300px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"password": "ChangePassword_password_2wM09",
	"title": "ChangePassword_title_3L-zz",
	"descriptionList": "ChangePassword_descriptionList_3SKiR",
	"descriptionTitle": "ChangePassword_descriptionTitle_YRUwx",
	"inputs": "ChangePassword_inputs_2L666",
	"form": "ChangePassword_form_2RCOA",
	"buttons": "ChangePassword_buttons_3xR8C"
};
module.exports = exports;
