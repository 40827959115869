import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
// Containers/Components
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import Filter from '../../../components/Filter';
import FormService from '../../../services/FormService';
import FilterField from '../../../components/FilterField';
import FilterButton from '../../../components/FilterButton';
import FilterSelect from '../../../components/FilterSelect';
import Button from '../../../components/Button';
import Footer from '../../../components/Footer';
import TabContent from '../../../components/TabContent';
// Claim lists
import ClaimsSubmittedList from './ClaimsSubmittedList';
import ClaimsInitialReviewList from './ClaimsInitialReviewList';
import ClaimsAwaitingCheckList from './ClaimsAwaitingCheckList';
import ClaimsCheckingList from './ClaimsCheckingList';
import ClaimsReviewedList from './ClaimsReviewedList';
import ClaimsPendingExportList from './ClaimsPendingExportList';
import ClaimsExportedList from './ClaimsExportedList';
import ClaimsPaidList from './ClaimsPaidList';
import ClaimsReturnedList from './ClaimsReturnedList';
import ClaimsCancelledList from './ClaimsCancelledList';
// Services
import ClaimsService from '../../../services/ClaimsService';
import { IClaimsSearchParams } from '../../../services/ClaimsService/ClaimsDao';
import DialogService, { DialogType } from '../../../services/DialogService';
import BatchService from '../../../services/BatchService';
// Models
import { ClaimSearchDates, ClaimSearchItems, ClaimStatus } from '../../../models/ClaimModel';
import { CustomerRole } from '../../../models/CustomerModel';
// Locales
import locales from '../../../helpers/locales';
import { convertZenkakuToHankaku } from '../../../helpers/convert';

interface IProps {
  claimsService: ClaimsService;
  dialogService: DialogService;
  batchService: BatchService;
}

interface IState {
  currentTabIndex: number;
}

const CLAIM_DATE_KEY_FIELD = 'claim-date-key';
const CLAIM_DATE_VALUE_YEAR_FIELD = 'claim-year';
const CLAIM_DATE_VALUE_MONTH_FIELD = 'claim-month';
const CLAIM_DATE_VALUE_DAY_FIELD = 'claim-day';
const CLAIM_ITEM_KEY_FIELD = 'claim-item-key';
const CLAIM_ITEM_VALUE_FIELD = 'claim-item-value';

const CLAIM_STATUSES_LIST = [
  ClaimStatus.Submitted,
  ClaimStatus.InitialReview,
  ClaimStatus.AwaitingCheck,
  ClaimStatus.Checking,
  ClaimStatus.Reviewed,
  ClaimStatus.PendingExport,
  ClaimStatus.Exported,
  ClaimStatus.Paid,
  ClaimStatus.Returned,
  ClaimStatus.Cancelled
];

@inject('claimsService', 'batchService', 'dialogService')
@observer
export default class ClaimsView extends React.Component<IProps, IState> {
  public form: FormService;

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTabIndex: 0
    };

    this.form = new FormService(true)
      .setField(CLAIM_DATE_KEY_FIELD, '', ClaimSearchDates.SubmittedAt)
      .setField(CLAIM_DATE_VALUE_YEAR_FIELD, 'numeric|max:2999')
      .setField(CLAIM_DATE_VALUE_MONTH_FIELD, 'numeric|min:1|max:12')
      .setField(CLAIM_DATE_VALUE_DAY_FIELD, 'numeric|min:1|max:31')
      .setField(CLAIM_ITEM_KEY_FIELD, '', ClaimSearchItems.InitialReviewerKanjiName)
      .setField(CLAIM_ITEM_VALUE_FIELD);
  }

  public componentDidMount() {
    const { currentTabIndex } = this.state;
    this.loadClaims(currentTabIndex, 1);
  }

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public downloadClaims = () => {
    const { batchService } = this.props;
    batchService.downloadClaims().catch(this.handleError);
  };

  public onClaimsPageChange = (page: number) => {
    const { currentTabIndex } = this.state;
    this.loadClaims(currentTabIndex, page);
  };

  public searchClaims = () => {
    const { currentTabIndex } = this.state;
    this.loadClaims(currentTabIndex, 1);
  };

  public updateFilterField = (value: string, name?: string) => {
    if (name) {
      this.form.setValue(name, value);
    }
  };

  public updateFilterFieldWithHankaku = (value: string, name?: string) => {
    if (name) {
      this.form.setValue(name, convertZenkakuToHankaku(value));
    }
  };

  public updateSearchDate = (date: string) => {
    this.form
      .setValue(CLAIM_DATE_KEY_FIELD, date)
      .setValue(CLAIM_DATE_VALUE_YEAR_FIELD, '')
      .setValue(CLAIM_DATE_VALUE_MONTH_FIELD, '')
      .setValue(CLAIM_DATE_VALUE_DAY_FIELD, '');
  };

  public updateSearchItem = (item: string) => {
    this.form
      .setValue(CLAIM_ITEM_KEY_FIELD, item)
      .setValue(CLAIM_ITEM_VALUE_FIELD, '');

    switch (item) {
      case ClaimSearchItems.BranchCode:
      case ClaimSearchItems.Amount:
      case ClaimSearchItems.TrustAccountNumber:
        this.form.setField(CLAIM_ITEM_VALUE_FIELD, 'numeric');
        break;
      default:
        this.form.setField(CLAIM_ITEM_VALUE_FIELD);
        if (item === ClaimSearchItems.CustomerRole) {
          this.form.setValue(CLAIM_ITEM_VALUE_FIELD, CustomerRole.AccountOwner);
        }
        break;
    }
  };

  public changeTab = (newTabIndex: number) => {
    this.setState({ currentTabIndex: newTabIndex });
    this.loadClaims(newTabIndex, 1);
  };

  public claimStatusFromTabIndex(index: number): ClaimStatus {
    return CLAIM_STATUSES_LIST[index];
  }

  public loadClaims(tabIndex: number, page: number) {
    const { claimsService } = this.props;
    const params: IClaimsSearchParams = {
      page,
      claim_status: this.claimStatusFromTabIndex(tabIndex)
    };
    const year = this.form.getValue(CLAIM_DATE_VALUE_YEAR_FIELD);
    const month = this.form.getValue(CLAIM_DATE_VALUE_MONTH_FIELD);
    const day = this.form.getValue(CLAIM_DATE_VALUE_DAY_FIELD);
    if (year && month && day) {
      const dateKey = this.form.getValue(CLAIM_DATE_KEY_FIELD) as ClaimSearchDates;
      params[dateKey] = moment(`${year}-${month}-${day}`, 'YYYY-M-D').toDate();
    }
    if (this.form.getValue(CLAIM_ITEM_VALUE_FIELD)) {
      const itemKey = this.form.getValue(CLAIM_ITEM_KEY_FIELD) as ClaimSearchItems;
      params[itemKey] = this.form.getValue(CLAIM_ITEM_VALUE_FIELD);
    }
    claimsService.loadClaims(params).catch(this.handleError);
  }

  private renderDateSectorOptions() {
    return Object.keys(ClaimSearchDates).map((dateType) => {
      const val = ClaimSearchDates[dateType];
      return (
        <option key={val} value={val}>{locales.get(`claims.filter.dates.${val}`)}</option>
      );
    });
  }

  private renderItemSectorOptions() {
    return Object.keys(ClaimSearchItems).map((itemType) => {
      const val = ClaimSearchItems[itemType];
      return (
        <option key={val} value={val}>{locales.get(`claims.filter.items.${val}`)}</option>
      );
    });
  }

  private renderTab(status: ClaimStatus, index: number) {
    const { claimsService } = this.props;
    const { currentTabIndex } = this.state;
    return (
      <Tab
        key={status}
        index={index}
        onChange={this.changeTab}
        disabled={claimsService.pendingClaims}
        active={currentTabIndex === index}
        label={locales.get(`general.claim_statuses.${status.valueOf()}`)}
      />
    );
  }

  private renderTabs = () => (
    <Tabs>
      {this.renderTab(ClaimStatus.Submitted, 0)}
      {this.renderTab(ClaimStatus.InitialReview, 1)}
      {this.renderTab(ClaimStatus.AwaitingCheck, 2)}
      {this.renderTab(ClaimStatus.Checking, 3)}
      {this.renderTab(ClaimStatus.Reviewed, 4)}
      {this.renderTab(ClaimStatus.PendingExport, 5)}
      {this.renderTab(ClaimStatus.Exported, 6)}
      {this.renderTab(ClaimStatus.Paid, 7)}
      {this.renderTab(ClaimStatus.Returned, 8)}
      {this.renderTab(ClaimStatus.Cancelled, 9)}
    </Tabs>
  );

  private renderTabContent() {
    const { claimsService, dialogService } = this.props;
    const { currentTabIndex } = this.state;

    switch (this.claimStatusFromTabIndex(currentTabIndex)) {
      case ClaimStatus.Submitted:
        return (
          <ClaimsSubmittedList
            claimsService={claimsService}
            dialogService={dialogService}
            onReviewStarted={this.searchClaims}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.InitialReview:
        return (
          <ClaimsInitialReviewList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.AwaitingCheck:
        return (
          <ClaimsAwaitingCheckList
            claimsService={claimsService}
            dialogService={dialogService}
            onReviewStarted={this.searchClaims}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.Checking:
        return (
          <ClaimsCheckingList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.Reviewed:
        return (
          <ClaimsReviewedList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.PendingExport:
        return (
          <ClaimsPendingExportList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.Exported:
        return (
          <ClaimsExportedList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.Paid:
        return (
          <ClaimsPaidList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.Returned:
        return (
          <ClaimsReturnedList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      case ClaimStatus.Cancelled:
        return (
          <ClaimsCancelledList
            claimsService={claimsService}
            onPageChange={this.onClaimsPageChange}
          />
        );

      default:
        throw new Error('Unknown claim status provided');
    }
  }

  private renderRoleOptions() {
    return Object.keys(CustomerRole)
      .filter((itemType) => CustomerRole[itemType] !== CustomerRole.Observer)
      .map((itemType) => {
        const val = CustomerRole[itemType];
        return (
          <option key={val} value={val}>{locales.get(`general.customer_roles.${val}`)}</option>
        );
      });
  }

  private renderGeneralPurposeFilterFiled() {
    if (this.form.getValue(CLAIM_ITEM_KEY_FIELD) !== ClaimSearchItems.CustomerRole) {
      return (
        <FilterField
          flex="16"
          name={CLAIM_ITEM_VALUE_FIELD}
          onChange={this.updateFilterField}
          placeholder={locales.get('claims.filter.item')}
          value={this.form.getValue(CLAIM_ITEM_VALUE_FIELD)}
        />
      );
    }
    return (
      <FilterSelect
        flex="16"
        name={CLAIM_ITEM_VALUE_FIELD}
        value={this.form.getValue(CLAIM_ITEM_VALUE_FIELD)}
        onChange={this.updateFilterField}
      >
        {this.renderRoleOptions()}
      </FilterSelect>
    );
  }

  private renderFilter = () => {
    const today = new Date();
    return (
      <Filter>
        <FilterSelect
          flex="9"
          value={this.form.getValue(CLAIM_DATE_KEY_FIELD)}
          onChange={this.updateSearchDate}
        >
          {this.renderDateSectorOptions()}
        </FilterSelect>

        <FilterField
          flex="0 0 95px"
          name={CLAIM_DATE_VALUE_YEAR_FIELD}
          label={locales.get('claims.filter.year')}
          placeholder={today.getFullYear().toString()}
          onChange={this.updateFilterFieldWithHankaku}
          value={this.form.getValue(CLAIM_DATE_VALUE_YEAR_FIELD)}
        />

        <FilterField
          flex="0 0 80px"
          name={CLAIM_DATE_VALUE_MONTH_FIELD}
          label={locales.get('claims.filter.month')}
          placeholder={(today.getMonth() + 1).toString()}
          onChange={this.updateFilterFieldWithHankaku}
          value={this.form.getValue(CLAIM_DATE_VALUE_MONTH_FIELD)}
        />

        <FilterField
          flex="0 0 80px"
          name={CLAIM_DATE_VALUE_DAY_FIELD}
          label={locales.get('claims.filter.day')}
          placeholder={today.getDate().toString()}
          onChange={this.updateFilterFieldWithHankaku}
          value={this.form.getValue(CLAIM_DATE_VALUE_DAY_FIELD)}
        />

        <FilterSelect
          flex="5"
          value={this.form.getValue(CLAIM_ITEM_KEY_FIELD)}
          onChange={this.updateSearchItem}
        >
          {this.renderItemSectorOptions()}
        </FilterSelect>

        {this.renderGeneralPurposeFilterFiled()}

        <FilterButton
          iconName="search"
          onClick={this.searchClaims}
        />
      </Filter>
    );
  };

  public render() {
    const { claimsService } = this.props;

    return (
      <>
        {this.renderFilter()}
        {this.renderTabs()}
        <TabContent>
          {this.renderTabContent()}
        </TabContent>
        <Footer>
          <Button
            iconName="download"
            label={locales.get('claims.download')}
            disabled={claimsService.pendingClaims}
            onClick={this.downloadClaims}
          />
        </Footer>
      </>
    );
  }
}
