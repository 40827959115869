import { translateDateTime } from '../helpers/translate';

export enum CustomerNotificationType {
  ClaimSubmitted = 'claim_submitted',
  ClaimReturned = 'claim_returned',
  ClaimPaid = 'claim_paid',
  ClaimReturnedInsufficientFunds = 'claim_returned_insufficient_funds',
  ClaimReturnedBankStopped = 'claim_returned_bank_stopped',
  ClaimReturnedPaymentAccountError = 'claim_returned_payment_account_error',
  ClaimReturnedCancelled = 'claim_returned_cancelled',
  ClaimPaymentStoppedBest = 'claim_payment_stopped_best',
  ClaimCancelled = 'claim_cancelled',
  RegularPayment = 'regular_payment',
  RegularPaymentError = 'regular_payment_error',
  TrustAccountCancelled = 'trust_account_cancelled',
  TrustAccountFundsAdded = 'trust_account_funds_added',
  TrustAccountCancelledSoon = 'trust_account_cancelled_soon',
  Announcement = 'announcement',
  CustomerPendingConfirmation = 'customer_pending_confirmation',
  CustomerJoined = 'customer_joined',
  CustomerKickedOut = 'customer_kicked_out',
  CustomerRemoved = 'customer_removed'
}

export enum NotificationSubjectType {
  Customer = 'customer',
  Claim = 'claim',
  TrustAccount = 'trust_account',
  Guest = 'guest'
}

export interface ICustomerNotificationObject {
  id: number;
  subject: {
    type: string;
    id: number;
  };
  is_important: boolean;
  type: string;
  title: string;
  content: string;
  created_at: string;
  updated_at: string;
}

export default class CustomerNotificationModel {
  public id: number;
  public subject: {
    type: NotificationSubjectType;
    id: number;
  };

  public isImportant: boolean;
  public type: CustomerNotificationType;
  public title: string;
  public content: string;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(json: ICustomerNotificationObject) {
    this.id = json.id;
    this.subject = {
      type: json.subject.type as NotificationSubjectType,
      id: json.subject.id
    };
    this.isImportant = json.is_important;
    this.type = json.type as CustomerNotificationType;
    this.title = json.title;
    this.content = json.content;
    this.createdAt = new Date(json.created_at);
    this.updatedAt = new Date(json.updated_at);
  }

  get createdAtString() {
    return translateDateTime(this.createdAt);
  }

  get updatedAtString() {
    return translateDateTime(this.updatedAt);
  }
}
