// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".NavButton_button_1jo03 {\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  height: 56px;\n  border-top: 4px solid var(--primary-color);\n  border-bottom: 4px solid var(--primary-color);\n  cursor: pointer;\n  color: #FFF; }\n  .NavButton_button_1jo03.NavButton_active_u7h-_ {\n    border-bottom-color: #FFF; }\n  .NavButton_button_1jo03.NavButton_disabled_21GyR {\n    opacity: 0.5;\n    cursor: not-allowed; }\n", "",{"version":3,"sources":["styles.scss","../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YCLkB;EDMlB,0CAA0C;EAC1C,6CAA6C;EAC7C,eAAe;EACf,WCCsB,EAAA;EDTxB;IAWI,yBCFoB,EAAA;EDTxB;IAeI,YAAY;IACZ,mBAAmB,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.button {\n  display: flex;\n  align-items: center;\n  margin-left: 30px;\n  height: $header-height;\n  border-top: 4px solid var(--primary-color);\n  border-bottom: 4px solid var(--primary-color);\n  cursor: pointer;\n  color: $text-accent-color;\n\n  &.active {\n    border-bottom-color: $text-accent-color;\n  }\n\n  &.disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"button": "NavButton_button_1jo03",
	"active": "NavButton_active_u7h-_",
	"disabled": "NavButton_disabled_21GyR"
};
module.exports = exports;
