export interface IInviteeObject {
  name_kanji: string;
  email: string;
  message: string;
}

export default class InviteeModel {
  public nameKanji: string;
  public email: string;
  public message: string;
  constructor(json: IInviteeObject) {
    this.nameKanji = json.name_kanji;
    this.email = json.email;
    this.message = json.message;
  }
}
