import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';
// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  flex?: string;
  label?: string;
  iconName?: string;
  onClick: () => void;
  disabled?: boolean;
}

export default class FilterButton extends React.PureComponent<IProps> {
  static defaultProps = {
    className: '',
    flex: '',
    label: '',
    iconName: '',
    disabled: false
  };

  public renderIcon() {
    const { iconName, disabled } = this.props;
    if (!iconName) {
      return null;
    }
    return (
      <Icon
        className={classnames(styles.icon, { [styles.disabled]: disabled })}
        name={iconName}
      />
    );
  }

  public render() {
    const {
      className, onClick, label, flex, disabled
    } = this.props;
    const style = flex ? { flex } : {};
    const classNames = classnames(
      'filterItem',
      styles.button,
      className,
      { [styles.disabled]: disabled }
    );

    return (
      <button
        type="button"
        className={classNames}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {this.renderIcon()}
        {label && <div className={styles.label}>{label}</div>}
      </button>
    );
  }
}
