import React from 'react';
import { observer } from 'mobx-react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import Header from '../../components/Header';
import Page from '../../components/Page';
import Login from '../Login';
import NewPassword from '../NewPassword';
import ChangePassword from '../ChangePassword';
import AccountService from '../../services/AccountService';
import { AccountState } from '../../models/AccountModel';
import NavButton from '../../components/NavButton';
import locales from '../../helpers/locales';
import DialogService, { DialogType } from '../../services/DialogService';
import SettingsService from '../../services/SettingsService';

interface IProps extends RouteComponentProps<{}> {
  accountService: AccountService;
  dialogService: DialogService;
  settingsService: SettingsService;
}

@observer
class AnonymousPage extends React.Component<IProps> {
  public confirmLogout = async () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('logout.confirmation'),
      onConfirm: this.logout
    });
  };

  public logout = async () => {
    const { accountService, history } = this.props;
    await accountService.logout();
    history.replace('/');
  };

  public renderHeader() {
    const { accountService, settingsService } = this.props;
    switch (accountService.accountState) {
      case AccountState.NewPasswordRequired:
      case AccountState.PasswordExpired:
        return (
          <Header settingsService={settingsService} hideProductLink>
            <NavButton
              label={locales.get('navigation.logout')}
              onClick={this.confirmLogout}
            />
          </Header>
        );
      default:
        break;
    }
    return <Header settingsService={settingsService} hideProductLink />;
  }

  public render() {
    return (
      <>
        {this.renderHeader()}
        <Page>
          <Switch>
            <Route path="/new-password" component={NewPassword} />
            <Route path="/change-expired-password" component={ChangePassword} />
            <Route path="/login" component={Login} />
            <Redirect to="/login" />
          </Switch>
        </Page>
      </>
    );
  }
}

export default withRouter(AnonymousPage);
