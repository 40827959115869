import {
  action, IObservableArray, observable, runInAction
} from 'mobx';
import { saveAs } from 'file-saver';
import ManagedService from '../ManagedService';
import AccountService from '../AccountService';
import CustomersDao from './CustomersDao';
import CustomerModel, { CustomerRole, ICustomersSearchParams } from '../../models/CustomerModel';
import CustomerNotificationModel from '../../models/CustomerNotificationModel';
import CustomerEventModel from '../../models/CustomerEventModel';
import AccountModel from '../../models/AccountModel';
import { UserRole } from '../../models/UserModel';
import { TrustAccountLinkStatus } from '../../models/TrustAccountModel';
import SettingsService from '../SettingsService';

export enum CustomerPermissions {
  PrintQr = 'print_qr',
  GenerateQr = 'generate_qr',
  ManageAccountLink = 'manage_account_link',
  ManageAccountLock = 'manage_account_lock',
  ManageApplicationLock = 'manage_application_lock',
  StopTotp = 'stop_totp',
  GenerateQrTotp = 'generate_qr_totp'
}

interface IPermissionsMap {
  [key: string]: {
    condition?: (
      account: AccountModel, customer: CustomerModel, permission: CustomerPermissions
    ) => boolean;
    permissions?: CustomerPermissions[];
  };
}

function isQrActionableCustomer(customer: CustomerModel) {
  switch (customer.role) {
    case CustomerRole.AccountOwner:
    case CustomerRole.LegalAgent:
    case CustomerRole.Conservator:
      return true;
    default:
      return false;
  }
}

function determineCustomerPermission(_account: AccountModel, customer: CustomerModel, permission: CustomerPermissions) {
  switch (permission) {
    case CustomerPermissions.PrintQr:
      return isQrActionableCustomer(customer) &&
        customer.trustAccountLinkStatus === TrustAccountLinkStatus.PendingQr;

    case CustomerPermissions.GenerateQr:
      return isQrActionableCustomer(customer) &&
        (customer.trustAccountLinkStatus === TrustAccountLinkStatus.Initial ||
          customer.trustAccountLinkStatus === TrustAccountLinkStatus.PendingQr ||
          customer.trustAccountLinkStatus === TrustAccountLinkStatus.Resigned);

    case CustomerPermissions.ManageAccountLink:
      return customer.trustAccountLinkStatus === TrustAccountLinkStatus.Linked;

    default:
      return true;
  }
}

const PERMISSIONS_MAP: IPermissionsMap = {
  [UserRole.Worker]: {
    condition: determineCustomerPermission,
    permissions: [
      CustomerPermissions.PrintQr,
      CustomerPermissions.GenerateQr,
      CustomerPermissions.GenerateQrTotp
    ]
  },
  [UserRole.Checker]: {
    condition: determineCustomerPermission,
    permissions: [
      CustomerPermissions.PrintQr,
      CustomerPermissions.GenerateQr,
      CustomerPermissions.GenerateQrTotp
    ]
  },
  [UserRole.Administrator]: {
    condition: determineCustomerPermission,
    permissions: [
      CustomerPermissions.ManageAccountLink,
      CustomerPermissions.ManageAccountLock,
      // TODO: spec is incomplete, Cognito disabled state supposed to be obtained
      // TODO: but impossible to get it from API response.
      CustomerPermissions.ManageApplicationLock,
      CustomerPermissions.PrintQr,
      CustomerPermissions.GenerateQr,
      CustomerPermissions.GenerateQrTotp,
      CustomerPermissions.StopTotp
    ]
  },
  [UserRole.Support]: {},
  [UserRole.Corporate]: {},
  [UserRole.Department]: {},
  [UserRole.SystemAdministrator]: {}
};

export default class CustomersService extends ManagedService<CustomersDao, CustomerPermissions> {
  public accountService: AccountService;
  settings: SettingsService;
  @observable public pendingCustomers = false;
  @observable public customersCurrentPage = 0;
  @observable public customersPagesCount = 0;
  @observable public notificationsCurrentPage = 0;
  @observable public notificationsPagesCount = 0;
  @observable public customers: IObservableArray<CustomerModel> = observable<CustomerModel>([]);
  @observable public notifications: IObservableArray<CustomerNotificationModel>
    = observable<CustomerNotificationModel>([]);

  @observable public events: IObservableArray<CustomerEventModel>
    = observable<CustomerEventModel>([]);

  constructor(dao: CustomersDao, accountService: AccountService, settings: SettingsService) {
    super(dao);
    this.accountService = accountService;
    this.settings = settings;
  }

  // API_CUST01
  @action public async loadCustomers(params: ICustomersSearchParams): Promise<void> {
    const { account } = this.accountService;
    if (!account) {
      throw new Error('Attempted to use method without account has been loaded');
    }

    try {
      this.pendingCustomers = true;
      this.customers.replace([]);
      const { pagination: { current, last }, customers } = await this.dao.loadCustomers(params);

      runInAction(() => {
        this.resetPermissions();
        customers.forEach((customer: CustomerModel) => this.buildPermissions(account, customer));
        this.customers.replace(customers);
        this.customersCurrentPage = current;
        this.customersPagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST02, API_CUST03, API_CUST08
  @action public async loadCustomerById(id: number): Promise<void> {
    const { account } = this.accountService;
    if (!account) {
      throw new Error('Attempted to use method without account has been loaded');
    }

    try {
      this.pendingCustomers = true;
      const [customer, notifications, events] = await Promise.all([
        this.dao.loadCustomerById(id),
        this.dao.loadNotificationsById(id, 1),
        this.dao.loadEventsById(id)
      ]);

      runInAction(() => {
        this.resetPermissions();
        this.buildPermissions(account, customer);
        this.customers.replace([customer]);
        this.notifications.replace(notifications.notifications);
        this.events.replace(events);
        this.customersCurrentPage = 1;
        this.customersPagesCount = 1;
        this.notificationsCurrentPage = 1;
        this.notificationsPagesCount = notifications.pagination.last;
      });
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST03
  @action public async loadMoreNotificationsById(id: number, page: number) {
    const { notifications, pagination } = await this.dao.loadNotificationsById(id, page);
    runInAction(() => {
      this.notifications.replace(this.notifications.peek().concat(notifications));
      this.notificationsCurrentPage = pagination.current;
      this.notificationsPagesCount = pagination.last;
    });
  }

  // API_CUST10
  @action public async downloadQrByCustomers(customers: CustomerModel[]): Promise<void> {
    try {
      this.pendingCustomers = true;
      const blob = await this.dao.downloadQrByCustomers(
        customers.map((customer) => customer.id)
      );
      saveAs(blob, `customers${this.productFileName}.pdf`);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST11
  @action public async completeQrByCustomers(customers: CustomerModel[]): Promise<void> {
    try {
      this.pendingCustomers = true;
      await this.dao.completeQrByCustomers(customers.map((customer) => customer.id));
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST07
  @action public async downloadQrByCustomer(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      const blob = await this.dao.downloadQrByCustomer(customer.id);
      saveAs(blob, `customer${this.productFileName}.pdf`);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST12
  @action public async generateQrByCustomer(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      const blob = await this.dao.generateQrByCustomer(customer.id);
      saveAs(blob, `customer${this.productFileName}.pdf`);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST15
  @action public async generateQrTotpByCustomer(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      const blob = await this.dao.generateQrTotpByCustomer(customer.id);
      saveAs(blob, `customer2${this.productFileName}.pdf`);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST06
  @action public async toggleApplicationLock(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      await this.dao.toggleApplicationLock(customer.id, !customer.locked);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST05
  @action public async unlinkAccount(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      await this.dao.unlinkAccount(customer.id);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST14
  @action public async reinitialize(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      await this.dao.reinitialize(customer.id);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  // API_CUST09
  @action public async unlockAccount(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      await this.dao.unlockAccount(customer.email);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  @action public async stopTotp(customer: CustomerModel): Promise<void> {
    try {
      this.pendingCustomers = true;
      await this.dao.stopTotp(customer.id);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  @action public async isAccountLocked(customer: CustomerModel): Promise<boolean> {
    if (!customer.email) {
      return false;
    }

    try {
      this.pendingCustomers = true;
      return await this.dao.isAccountLocked(customer.email);
    } finally {
      runInAction(() => {
        this.pendingCustomers = false;
      });
    }
  }

  private buildPermissions(account: AccountModel, customer: CustomerModel) {
    const permissions = PERMISSIONS_MAP[account.role];
    if (!permissions.permissions) {
      return;
    }

    permissions.permissions.forEach((permission) => {
      if (!permissions.condition || permissions.condition(account, customer, permission)) {
        this.addPermission(customer, permission);
      }
    });
  }

  private get productFileName() {
    return this.settings.isProductSelectionOhitori ? '_ohitori' : '';
  }
}
