import getValue from 'get-value';
import debug from 'debug';
import jaJson from '../../static/locales/ja.json';

const logger = debug('MUTB:locales');

export default {
  get: (key: string, replacements = {}) => {
    let value = getValue(jaJson, key);

    if (!value) {
      logger(`There is no translation for the "${key}" key provided`);
      return '';
    }

    Object.keys(replacements).forEach((replacementKey) => {
      const regex = new RegExp(`{{${replacementKey}}}`, 'g');
      value = value.replace(regex, replacements[replacementKey]);
    });

    return value;
  }
};
