import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Containers/Components
import { Column } from '../../../components/table';
import Text from '../../../components/Text';
import ClaimsListTable from './ClaimsListTable';
import CustomerModel from '../../../models/CustomerModel';
import ClaimsListSubmitButton from './ClaimsListSubmitButton';
import { ButtonSize } from '../../../components/Button';
// Services
import ClaimsService, { ClaimPermissions } from '../../../services/ClaimsService';
import DialogService, { DialogType } from '../../../services/DialogService';
// Models
import ClaimModel from '../../../models/ClaimModel';
import UserModel from '../../../models/UserModel';
// Locales
import locales from '../../../helpers/locales';

// Styles
import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  claimsService: ClaimsService;
  dialogService: DialogService;
  onReviewStarted: () => void;
  onPageChange: (page: number) => void;
}

@observer
class ClaimsAwaitingCheckList extends React.Component<IProps> {
  public processClaim = async (claim?: ClaimModel) => {
    const { claimsService, dialogService, onReviewStarted } = this.props;
    if (!claim) {
      return;
    }

    try {
      await claimsService.reviewClaim(claim, ClaimPermissions.ReviewStart);
      window.open(`#/claims/${claim.id}`);
      onReviewStarted();
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message
      });
    }
  };

  public render() {
    const { claimsService, onPageChange } = this.props;

    const columns: Column[] = [{
      width: 140,
      Header: locales.get('claims.columns.secondary_reviewer'),
      id: 'secondary_reviewer',
      Cell: (row: { original: ClaimModel }) => (
        <ClaimsListSubmitButton
          label={locales.get('claims.process')}
          value={row.original}
          size={ButtonSize.Small}
          onClick={this.processClaim}
          disabled={!claimsService.hasPermission(row.original, ClaimPermissions.ReviewStart)}
        />
      )
    }, {
      Header: locales.get('general.claim.submitted_at'),
      id: 'submittedAtString',
      className: styles.tooltipCell,
      Cell: (row: { original: ClaimModel }) => (
        <Text tooltip={row.original.number || locales.get('claim.no_number')}>{row.original.submittedAtString}</Text>
      )
    }, {
      Header: locales.get('general.claim.mimamori_until_date'),
      id: 'mimamoriUntilDateString',
      Cell: (row: { original: ClaimModel }) => row.original.mimamoriUntilDateString
    }, {
      width: 50,
      Header: locales.get('general.trust_account.branch_code'),
      id: 'branchCode',
      accessor: 'customer',
      Cell: (row: { value: CustomerModel }) => row.value.trustAccount.branchCode
    }, {
      width: 80,
      Header: locales.get('general.trust_account.account_number'),
      id: 'trustAccount.number',
      accessor: 'customer',
      Cell: (row: { value: CustomerModel }) => row.value.trustAccount.number
    }, {
      Header: locales.get('general.claim.customer'),
      id: 'customer',
      accessor: 'customer',
      Cell: (row: { value: CustomerModel }) => (
        <>
          <div>{row.value.nameKanji}</div>
          <div>{row.value.nameKana}</div>
          <div>{row.value.roleString}</div>
        </>
      )
    }, {
      Header: locales.get('general.claim.date'),
      id: 'dateString',
      Cell: (row: { original: ClaimModel }) => row.original.dateString
    }, {
      width: 150,
      Header: locales.get('general.claim.amount'),
      id: 'amountString',
      Cell: (row: { original: ClaimModel }) => (
        row.original.amount < 0 ?
          <div className={styles.minus}>{row.original.amountString}</div> :
          row.original.amountString
      )
    }, {
      Header: locales.get('claims.columns.initial_reviewer'),
      id: 'initialReviewAdminUser',
      accessor: 'initialReviewAdminUser',
      Cell: (row: { value: UserModel }) => row.value.name
    }];

    return (
      <ClaimsListTable
        columns={columns}
        loading={claimsService.pendingClaims}
        data={claimsService.claims.peek()}
        currentPage={claimsService.currentPage}
        pagesCount={claimsService.pagesCount}
        onPageChange={onPageChange}
      />
    );
  }
}

export default withRouter(ClaimsAwaitingCheckList);
