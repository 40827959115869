import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Containers/Components
import Text from '../../../components/Text';
import { Column } from '../../../components/table';
import ClaimsListTable from './ClaimsListTable';
// Services
import ClaimsService from '../../../services/ClaimsService';
// Models
import CustomerModel from '../../../models/CustomerModel';
import ClaimModel from '../../../models/ClaimModel';
import UserModel from '../../../models/UserModel';
// Locales
import locales from '../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  claimsService: ClaimsService;
  onPageChange: (page: number) => void;
}

@observer
class ClaimsCheckingList extends React.Component<IProps> {
  public render() {
    const { claimsService, onPageChange } = this.props;

    const columns: Column[] = [{
      Header: locales.get('claims.columns.secondary_reviewer'),
      id: 'secondaryReviewAdminUser',
      accessor: 'secondaryReviewAdminUser',
      Cell: (row: { value: UserModel }) => row.value.name
    }, {
      Header: locales.get('general.claim.submitted_at'),
      id: 'submittedAtString',
      className: styles.tooltipCell,
      Cell: (row: { original: ClaimModel }) => (
        <Text tooltip={row.original.number || locales.get('claim.no_number')}>{row.original.submittedAtString}</Text>
      )
    }, {
      Header: locales.get('general.claim.mimamori_until_date'),
      id: 'mimamoriUntilDateString',
      Cell: (row: { original: ClaimModel }) => row.original.mimamoriUntilDateString
    }, {
      width: 50,
      Header: locales.get('general.trust_account.branch_code'),
      id: 'branchCode',
      accessor: 'customer',
      Cell: (row: { value: CustomerModel }) => row.value.trustAccount.branchCode
    }, {
      width: 80,
      Header: locales.get('general.trust_account.account_number'),
      id: 'trustAccount.number',
      accessor: 'customer',
      Cell: (row: { value: CustomerModel }) => row.value.trustAccount.number
    }, {
      Header: locales.get('general.claim.customer'),
      id: 'customer',
      accessor: 'customer',
      Cell: (row: { value: CustomerModel }) => (
        <>
          <div>{row.value.nameKanji}</div>
          <div>{row.value.nameKana}</div>
          <div>{row.value.roleString}</div>
        </>
      )
    }, {
      Header: locales.get('general.claim.date'),
      id: 'dateString',
      Cell: (row: { original: ClaimModel }) => row.original.dateString
    }, {
      width: 150,
      Header: locales.get('general.claim.amount'),
      id: 'amountString',
      Cell: (row: { original: ClaimModel }) => (
        row.original.amount < 0 ?
          <div className={styles.minus}>{row.original.amountString}</div> :
          row.original.amountString
      )
    }, {
      Header: locales.get('claims.columns.initial_reviewer'),
      id: 'initialReviewAdminUser',
      accessor: 'initialReviewAdminUser',
      Cell: (row: { value: UserModel }) => row.value.name
    }];

    return (
      <ClaimsListTable
        columns={columns}
        loading={claimsService.pendingClaims}
        data={claimsService.claims.peek()}
        currentPage={claimsService.currentPage}
        pagesCount={claimsService.pagesCount}
        onPageChange={onPageChange}
      />
    );
  }
}

export default withRouter(ClaimsCheckingList);
