// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Footer_footer_2tvn_ {\n  margin: 15px 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end; }\n  .Footer_footer_2tvn_ > button {\n    margin-left: 15px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,yBAAyB,EAAA;EAJ3B;IAOI,iBAAiB,EAAA","file":"styles.scss","sourcesContent":[".footer {\n  margin: 15px 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n\n  > button {\n    margin-left: 15px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"footer": "Footer_footer_2tvn_"
};
module.exports = exports;
