// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ImagePreview_image_3_SI8 {\n  width: 100%;\n  height: 320px;\n  background: #F7F7F7;\n  background-size: cover;\n  position: relative;\n  cursor: pointer;\n  cursor: zoom-in; }\n  .ImagePreview_image_3_SI8 .ImagePreview_magnify_scujP {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 24px;\n    color: #8E8E93; }\n  .ImagePreview_image_3_SI8:hover .ImagePreview_magnify_scujP {\n    color: #b5b5b8; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,eAAe,EAAA;EAPjB;IAUI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,cAhBmB,EAAA;EAEvB;IAmBM,cAAmC,EAAA","file":"styles.scss","sourcesContent":["$magnify-color: #8E8E93;\n\n.image {\n  width: 100%;\n  height: 320px;\n  background: #F7F7F7;\n  background-size: cover;\n  position: relative;\n  cursor: pointer; // fallback for IE\n  cursor: zoom-in;\n\n  .magnify {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 24px;\n    color: $magnify-color;\n  }\n\n  &:hover {\n    .magnify {\n      color: lighten($magnify-color, 15%);\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"image": "ImagePreview_image_3_SI8",
	"magnify": "ImagePreview_magnify_scujP"
};
module.exports = exports;
