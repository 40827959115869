// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DevTools_tools_3LZhn {\n  width: 100%;\n  flex: 0 0 36px;\n  color: #EEE;\n  background: #333;\n  border-top: 1px solid #666;\n  border-bottom: 1px solid #111;\n  padding: 0 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end; }\n  .DevTools_tools_3LZhn > * {\n    height: 26px;\n    line-height: 26px;\n    font-size: 12px;\n    font-weight: bold;\n    padding: 0 5px; }\n  .DevTools_tools_3LZhn select {\n    text-transform: capitalize; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,0BAA0B;EAC1B,6BAA6B;EAC7B,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB,EAAA;EAX3B;IAcI,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,cAAc,EAAA;EAlBlB;IAsBI,0BAA0B,EAAA","file":"styles.scss","sourcesContent":[".tools {\n  width: 100%;\n  flex: 0 0 36px;\n  color: #EEE;\n  background: #333;\n  border-top: 1px solid #666;\n  border-bottom: 1px solid #111;\n  padding: 0 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n\n  > * {\n    height: 26px;\n    line-height: 26px;\n    font-size: 12px;\n    font-weight: bold;\n    padding: 0 5px;\n  }\n\n  select {\n    text-transform: capitalize;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"tools": "DevTools_tools_3LZhn"
};
module.exports = exports;
