import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

export default class Filter extends React.PureComponent<IProps> {
  public render() {
    const { className, children } = this.props;
    return (
      <div className={classnames(className, styles.filter)}>{children}</div>
    );
  }
}
