import React from 'react';
// Styles
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
}

export default class TabContent extends React.PureComponent<IProps> {
  public render() {
    const { children } = this.props;
    return (
      <div className={styles.content}>{children}</div>
    );
  }
}
