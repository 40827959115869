import StorageDao from './StorageDao';

export default class StorageDaoImpl implements StorageDao {
  public getItem(key: string): string|null {
    return localStorage.getItem(key);
  }

  public setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
