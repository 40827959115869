import React from 'react';
import debug from 'debug';
import { configure, autorun } from 'mobx';
import { observer, Provider } from 'mobx-react';

// Components/Containers
import config from 'config.json';
import Root from './views/Root';

// Services
import services from './services';
import { DialogType } from './services/DialogService';

// App Config

// Global Styles
import './styles/global.css';
import './styles/icomoon/style.css';
import 'react-table/react-table.css';

// DevTools
import DevTools from './devtools/DevTools';

debug.enable(config.DEBUG || config.debug);

configure({
  enforceActions: 'observed'
});

// reacts to changes in settingsService.productSelection
autorun(() => {
  services.settingsService.setThemeColor();
  services.apiService.addGlobalHeader('X-Product-Type', services.settingsService.productSelectionWithDefault);
});

const logger = debug('MUTB:App');
logger('Config: ', config.env);

services.accountService.load().catch((error) => {
  services.dialogService.setDialog({
    type: DialogType.Warn,
    content: error.message
  });
});

@observer
export default class App extends React.Component {
  public renderDevTools() {
    return (
      <DevTools
        accountService={services.accountService}
        storageService={services.storageService}
      />
    );
  }

  public render() {
    return (
      <Provider {...services}>
        <>
          <Root
            accountService={services.accountService}
            dialogService={services.dialogService}
            settingsService={services.settingsService}
            apiService={services.apiService}
          />
          {config.devTools && this.renderDevTools()}
        </>
      </Provider>
    );
  }
}
