import React from 'react';

import styles from './styles.scss';

interface IProps {
  label: string;
  onClick: () => void;
}

export default class MenuButton extends React.PureComponent<IProps> {
  public render() {
    const { label, onClick } = this.props;

    return (
      <div className={styles.button} onClick={onClick}>{label}</div>
    );
  }
}
