import React from 'react';
import classnames from 'classnames';
// Components
import Modal from '../../../../components/Modal';
import Title from '../../../../components/Title';
import DescriptionList, { DescriptionListSize } from '../../../../components/DescriptionList';
// Models
import ClaimModel, { ClaimStatus } from '../../../../models/ClaimModel';
// Helpers
import locales from '../../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps {
  claim: ClaimModel;
  onClose: () => void;
}

export default class ClaimDetailsClaimInformation extends React.PureComponent<IProps> {
  static getClaimStatusColor(claim: ClaimModel) {
    switch (claim.status) {
      case ClaimStatus.Returned:
      case ClaimStatus.Cancelled:
        return styles.negative;
      default:
        return styles.positive;
    }
  }

  public render() {
    const { claim, onClose } = this.props;
    const statusClassName = classnames(
      styles.status, ClaimDetailsClaimInformation.getClaimStatusColor(claim)
    );
    return (
      <Modal closable isOpen onClose={onClose}>
        <div className={styles.information}>
          <Title className={styles.title}>{locales.get('claim.details')}</Title>
          <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Big}>
            <div className="row">
              <div className="cell">{locales.get('general.claim.status')}</div>
              <div className={classnames('cell', statusClassName)}>
                {claim.statusString}
              </div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('claim.number')}</div>
              <div className="cell">
                {claim.number || locales.get('general.none')}
              </div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.trust_account.branch_code')}
              </div>
              <div className="cell">{claim.customer.trustAccount.branchCode}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.trust_account.branch_name')}
              </div>
              <div className="cell">{claim.customer.trustAccount.branchName}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.trust_account.account_number')}
              </div>
              <div className="cell">{claim.customer.trustAccount.number}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.claim.submitted_at')}
              </div>
              <div className="cell">{claim.submittedAtString}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.claim.mimamori_until_date')}
              </div>
              <div className="cell">{claim.mimamoriUntilDateString}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.claim.expected_export_date')}
              </div>
              <div className="cell">{claim.expectedExportDateString}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.claim.paid_date')}
              </div>
              <div className="cell">{claim.paidDateString}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.claim.returned_at')}
              </div>
              <div className="cell">{claim.returnedAtString}</div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.claim.cancelled_at')}
              </div>
              <div className="cell">{claim.cancelledAtString}</div>
            </div>
          </DescriptionList>
        </div>
      </Modal>
    );
  }
}
