import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children: React.ReactNode;
}

export default class Overlay extends React.PureComponent<IProps> {
  public overlayRoot: HTMLElement;
  public container: HTMLElement;

  constructor(props: IProps) {
    super(props);
    const overlayRoot = document.getElementById('overlay-root');
    if (!overlayRoot) {
      throw new Error('#overlay-root DOM element is required to use Overlay component');
    }

    this.overlayRoot = overlayRoot;
    this.container = document.createElement('div');
  }

  public componentDidMount() {
    this.overlayRoot.appendChild(this.container);
  }

  public componentWillUnmount() {
    this.overlayRoot.removeChild(this.container);
  }

  public render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.container);
  }
}
