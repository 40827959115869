export interface IClaimReceiptObject {
  receipt_image_url: string;
}

export default class ClaimReceiptModel {
  public receiptImageUrl: string;

  constructor(json: IClaimReceiptObject) {
    this.receiptImageUrl = json.receipt_image_url;
  }
}
