import React from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
// Containers/Components
import Title from '../../components/Title';
import TextAreaField from '../../components/TextAreaField';
import Button, { ButtonSize, ButtonType } from '../../components/Button';
import Loader from '../Loader';
import Section from '../../components/Section';
import TargetCustomerOptions from '../../components/TargetCustomerOptions';
import MailMagazineSelection from '../../components/MailMagazineSelection';
// Services
import FormService from '../../services/FormService';
import DialogService, { DialogType } from '../../services/DialogService';
import NotificationsService from '../../services/NotificationsService';
import SettingsService from '../../services/SettingsService';
// Models
import AnnouncementModel from '../../models/AnnouncementModel';
// Helpers
import locales from '../../helpers/locales';
// Styles
import styles from './styles.scss';

const NOTIFICATION_FIELD = locales.get('notification.content.name');
const MAIL_MAGAZINE_CHECKBOX_DEFAULT = false;

interface IProps {
  dialogService: DialogService;
  notificationsService: NotificationsService;
  settingsService: SettingsService;
}

interface IState {
  targetTrustees: string[]|null;
  containsSelectionError: boolean;
  mailMagazineAnnouncement: boolean;
}

@inject('dialogService', 'notificationsService', 'settingsService')
@observer
export default class NotificationView extends React.Component<IProps, IState> {
  static readonly MAX_MESSAGE_LENGTH = 2000; // eslint-disable-line react/sort-comp
  static readonly AGENCIES = [
    { id: '0288', label: locales.get('notification.targets.mutb') },
    { id: '0005', label: locales.get('notification.targets.mufj') },
    { id: '0149', label: locales.get('notification.targets.shizuoka_bank') },
    { id: '0173', label: locales.get('notification.targets.114_bank') },
    { id: '0162', label: locales.get('notification.targets.nanto_bank') }
  ];

  private form: FormService;

  constructor(props: IProps) {
    super(props);
    this.form = new FormService();
    this.form.setField(NOTIFICATION_FIELD, `required|max:${NotificationView.MAX_MESSAGE_LENGTH}`);

    this.state = {
      targetTrustees: null,
      containsSelectionError: false,
      mailMagazineAnnouncement: MAIL_MAGAZINE_CHECKBOX_DEFAULT
    };
  }

  public async componentDidMount() {
    const { notificationsService, dialogService } = this.props;
    try {
      await notificationsService.loadAnnouncements();
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message
      });
    }
  }

  private onChange = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    this.form.setValue(event.currentTarget.name, event.currentTarget.value);
  };

  private onBlur = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    this.form.validate(event.currentTarget.name);
  };

  private sendNotification = async () => {
    const { notificationsService, dialogService } = this.props;
    const { targetTrustees, mailMagazineAnnouncement } = this.state;

    try {
      const message = this.form.getValue(NOTIFICATION_FIELD);
      await notificationsService.sendAnnouncement(message, targetTrustees, mailMagazineAnnouncement);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('notification.success')
      });

      this.form.setValue(NOTIFICATION_FIELD, '');
      this.setState({ targetTrustees: null, mailMagazineAnnouncement: MAIL_MAGAZINE_CHECKBOX_DEFAULT });
    } catch (error) {
      this.handleError(error);
    }
  };

  private validateTargetTrustees = (selectedValues: string[]|null) => {
    if (Array.isArray(selectedValues) && selectedValues.length === 0) {
      this.setState({ containsSelectionError: true });
      return false;
    }

    this.setState({ containsSelectionError: false });
    return true;
  };

  private confirmSendingNotification = () => {
    const { targetTrustees } = this.state;
    const { dialogService } = this.props;
    const targetTrusteesValid = this.validateTargetTrustees(targetTrustees);
    const everythingElseValid = this.form.validateAll();
    if (!(targetTrusteesValid && everythingElseValid)) {
      return;
    }
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('notification.confirmation'),
      onConfirm: this.sendNotification
    });
  };

  private updateSelectedAgencies = (selectedValues: string[]|null) => {
    const { targetTrustees } = this.state;
    this.setState({ targetTrustees: selectedValues });
    if (targetTrustees) {
      this.validateTargetTrustees(selectedValues);
    }
  };

  private updateStateFromMailMagazineCheckbox = (value: string, checked: boolean) => {
    if (checked) {
      this.setState({ mailMagazineAnnouncement: true });
    } else {
      this.setState({ mailMagazineAnnouncement: false });
    }
  };

  private handleError(error: Error) {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  }

  private mapTrusteeIdsToNames(trusteeCodes: string[]) {
    return trusteeCodes
      .map((trusteeCode) => NotificationView.AGENCIES.find((e) => e.id === trusteeCode)?.label)
      .join(', ');
  }

  private targetFor(announcement: AnnouncementModel) {
    return announcement.targetTrustees ?
      this.mapTrusteeIdsToNames(announcement.targetTrustees) :
      locales.get('notification.targets.all_customers');
  }

  private renderAnnouncements() {
    const { notificationsService: { announcements } } = this.props;
    return announcements
      .map((announcement: AnnouncementModel) => (
        <div className={styles.announcementItem} key={announcement.id}>
          <div className={styles.announcementTitle}>
            {`${locales.get('notification.announcement')}`}
            <span className={classnames(styles.targetList)}>{ ` (${this.targetFor(announcement)})`}</span>
          </div>
          <div>{announcement.content}</div>
          <div className={styles.announcementSource}>
            <div className={styles.announcementAuthor}>{announcement.adminUser.nameKanji}</div>
            <div>{announcement.updatedAtString || announcement.createdAtString}</div>
          </div>
        </div>
      ));
  }

  private renderLoader() {
    const { notificationsService } = this.props;
    if (!notificationsService.pendingAnnouncements) {
      return null;
    }
    return (
      <div className={styles.loader}>
        <div>{locales.get('loading')}</div>
      </div>
    );
  }

  public render() {
    const { notificationsService: { pendingAnnouncement: loading }, settingsService } = this.props;
    const { targetTrustees, containsSelectionError, mailMagazineAnnouncement } = this.state;

    return (
      <>
        <Loader loading={loading} />
        <div className={styles.notification}>
          <div className={styles.container} data-tag="notification-view-container">
            <Title className={styles.title}>{locales.get('notification.title')}</Title>
            <TextAreaField
              className={styles.text}
              textAreaHeight={200}
              name={NOTIFICATION_FIELD}
              placeholder={locales.get('notification.content.placeholder')}
              value={this.form.getValue(NOTIFICATION_FIELD)}
              error={this.form.getError(NOTIFICATION_FIELD)}
              onChange={this.onChange}
              onBlur={this.onBlur}
            />
            <TargetCustomerOptions
              agencies={NotificationView.AGENCIES}
              onSelectedAgenciesChange={this.updateSelectedAgencies}
              selectedTrustees={targetTrustees}
              containsSelectionError={containsSelectionError}
            />
            {
              settingsService.isProductSelectionOhitori ? (
                <MailMagazineSelection
                  onCheckBoxCheck={this.updateStateFromMailMagazineCheckbox}
                  mailMagazineAnnouncement={mailMagazineAnnouncement}
                />
              ) : ''
            }
            <Button
              type={ButtonType.Round}
              size={ButtonSize.Big}
              label={locales.get('notification.send')}
              disabled={!this.form.isFormFilled()}
              onClick={this.confirmSendingNotification}
            />
            <Section
              title={locales.get('notification.history_title')}
              className={styles.section}
              titleClassName={styles.sectionTitle}
            >
              {this.renderLoader()}
              {this.renderAnnouncements()}
            </Section>
          </div>
        </div>
      </>
    );
  }
}
