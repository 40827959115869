import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Components/Containers
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import Filter from '../../../components/Filter';
import FilterField from '../../../components/FilterField';
import FilterButton from '../../../components/FilterButton';
import PendingApprovals from './PendingApprovals';
import UsersList from './UsersList';
import FilterSelect from '../../../components/FilterSelect';
// Services
import UsersService from '../../../services/UsersService';
import FormService from '../../../services/FormService';
import { UsersSearchUserStatusGroup, IUsersSearchParams } from '../../../services/UsersService/UsersDao';
import DialogService, { DialogType } from '../../../services/DialogService';
// Models
import UserModel, { UserRole, UserStatus } from '../../../models/UserModel';
// Helpers
import locales from '../../../helpers/locales';
import { convertZenkakuToHankaku } from '../../../helpers/convert';

export enum UsersListType {
  Users = 'users_list',
  Pending = 'pending_approvals'
}

const TAB_TYPES = [
  UsersListType.Users,
  UsersListType.Pending
];

const BRANCH_CODE_FIELD = 'admin_users.filter.branch_code';
const ROLE_FIELD = 'admin_users.filter.role';
const STATUS_FIELD = 'admin_users.filter.status';
const EMPLOYEE_NUMBER_FIELD = 'admin_users.filter.employee_number';
const NAME_FIELD = 'admin_users.filter.name';

const USERS_LIST_STATUS_OPTIONS = [
  UsersSearchUserStatusGroup.ActiveAll,
  UserStatus.Deleted
];

const AWAITING_LIST_STATUS_OPTIONS = [
  UsersSearchUserStatusGroup.PendingAll,
  UserStatus.Created,
  UserStatus.Pending,
  UserStatus.PendingDelete
];

interface IProps extends RouteComponentProps<{}> {
  usersService: UsersService;
  dialogService: DialogService;
}

interface IState {
  currentTabIndex: number;
}

@inject('usersService', 'dialogService')
@observer
class UsersListView extends React.Component<IProps, IState> {
  public form: FormService;

  constructor(props: IProps) {
    super(props);

    const { location: { pathname: path } } = this.props;
    const isPendingTabSelected = path === '/users/pending';

    this.state = {
      currentTabIndex: (isPendingTabSelected ? 1 : 0)
    };

    const defaultStatusValue = isPendingTabSelected ?
      UsersSearchUserStatusGroup.PendingAll : UsersSearchUserStatusGroup.ActiveAll;

    this.form = new FormService(true)
      .setField(BRANCH_CODE_FIELD, 'numeric')
      .setField(ROLE_FIELD)
      .setField(STATUS_FIELD, '', defaultStatusValue)
      .setField(EMPLOYEE_NUMBER_FIELD, 'alphanumeric')
      .setField(NAME_FIELD);
  }

  public componentDidMount() {
    this.search();
  }

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public changeTab = (newTabIndex: number) => {
    const { history } = this.props;
    if (TAB_TYPES[newTabIndex] === UsersListType.Users) {
      this.form.setValue(STATUS_FIELD, UsersSearchUserStatusGroup.ActiveAll);
    } else {
      this.form.setValue(STATUS_FIELD, UsersSearchUserStatusGroup.PendingAll);
    }
    this.setState({ currentTabIndex: newTabIndex });
    this.searchUsersWithPage(1);

    history.push(`/users${newTabIndex ? '/pending' : ''}`);
  };

  public search = () => {
    this.searchUsersWithPage(1);
  };

  public searchUsersWithPage = (page: number) => {
    const { usersService } = this.props;
    const params: IUsersSearchParams = { page };
    if (this.form.getValue(BRANCH_CODE_FIELD)) {
      params.branch_code = this.form.getValue(BRANCH_CODE_FIELD);
    }
    if (this.form.getValue(ROLE_FIELD)) {
      params.role = this.form.getValue(ROLE_FIELD);
    }
    if (this.form.getValue(STATUS_FIELD)) {
      const isStatusGroup = Object.keys(UsersSearchUserStatusGroup)
        .filter((key) => UsersSearchUserStatusGroup[key] === this.form.getValue(STATUS_FIELD))
        .length > 0;
      if (isStatusGroup) {
        params.admin_user_status_group = this.form.getValue(STATUS_FIELD);
      } else {
        params.admin_user_status = this.form.getValue(STATUS_FIELD);
      }
    }
    if (this.form.getValue(EMPLOYEE_NUMBER_FIELD)) {
      params.employee_number = this.form.getValue(EMPLOYEE_NUMBER_FIELD);
    }
    if (this.form.getValue(NAME_FIELD)) {
      params.name_kanji = this.form.getValue(NAME_FIELD);
    }
    usersService.loadUsers(params).catch(this.handleError);
  };

  public updateFilterField = (value: string, name?: string) => {
    if (!name) {
      return;
    }
    this.form.setValue(name, value);
  };

  public updateFilterFieldWithHankaku = (value: string, name?: string) => {
    if (!name) {
      return;
    }
    this.form.setValue(name, convertZenkakuToHankaku(value));
  };

  public addUser = () => {
    // Yes, this is inconsistency. They want to edit users in the same tab, but add them in the new one.
    window.open('/#/users/new');
  };

  public editUser = (id: number) => {
    const { history } = this.props;
    history.push(`/users/${id}`);
  };

  public downloadUsers = () => {
    const { usersService } = this.props;
    usersService.downloadUsers().catch(this.handleError);
  };

  public approve = async (users: UserModel[]) => {
    const { usersService } = this.props;
    await usersService.approveRequests(users);
  };

  public reject = async (users: UserModel[]) => {
    const { usersService } = this.props;
    await usersService.rejectRequests(users);
  };

  public renderRoleOptions() {
    const options = Object.keys(UserRole).map((key) => {
      const val = UserRole[key];
      return (
        <option key={val} value={val}>{locales.get(`general.admin_user_roles.${val}`)}</option>
      );
    });
    options.unshift(
      <option key="empty" value="">{locales.get('admin_users.filter.role')}</option>
    );
    return options;
  }

  public renderStatusOptions() {
    const { currentTabIndex } = this.state;
    const options: React.ReactNodeArray = [];
    if (TAB_TYPES[currentTabIndex] === UsersListType.Users) {
      options.push(
        <option key="empty" value="">{locales.get('admin_users.filter.status')}</option>
      );
      USERS_LIST_STATUS_OPTIONS.forEach((val) => {
        if (val === UsersSearchUserStatusGroup.ActiveAll) {
          options.push(
            <option key={val} value={val}>
              {locales.get('general.admin_user_statuses.active')}
            </option>
          );
        } else {
          options.push(
            <option key={val} value={val}>
              {locales.get(`general.admin_user_statuses.${val}`)}
            </option>
          );
        }
      });
    } else {
      AWAITING_LIST_STATUS_OPTIONS.forEach((val) => {
        if (val === UsersSearchUserStatusGroup.PendingAll) {
          options.push(
            <option key={val} value={val}>{locales.get('admin_users.filter.status')}</option>
          );
        } else {
          options.push(
            <option key={val} value={val}>
              {locales.get(`general.admin_user_statuses.${val}`)}
            </option>
          );
        }
      });
    }
    return options;
  }

  public renderFilter = () => (
    <Filter>
      <FilterField
        flex="2"
        name={BRANCH_CODE_FIELD}
        value={this.form.getValue(BRANCH_CODE_FIELD)}
        placeholder={locales.get(BRANCH_CODE_FIELD)}
        onChange={this.updateFilterFieldWithHankaku}
      />
      <FilterSelect
        flex="3"
        name={ROLE_FIELD}
        value={this.form.getValue(ROLE_FIELD)}
        onChange={this.updateFilterField}
      >
        {this.renderRoleOptions()}
      </FilterSelect>
      <FilterSelect
        flex="3"
        name={STATUS_FIELD}
        value={this.form.getValue(STATUS_FIELD)}
        onChange={this.updateFilterField}
      >
        {this.renderStatusOptions()}
      </FilterSelect>
      <FilterField
        flex="3"
        name={EMPLOYEE_NUMBER_FIELD}
        value={this.form.getValue(EMPLOYEE_NUMBER_FIELD)}
        placeholder={locales.get(EMPLOYEE_NUMBER_FIELD)}
        onChange={this.updateFilterFieldWithHankaku}
      />
      <FilterField
        flex="6"
        name={NAME_FIELD}
        value={this.form.getValue(NAME_FIELD)}
        placeholder={locales.get(NAME_FIELD)}
        onChange={this.updateFilterField}
      />
      <FilterButton
        flex="4"
        iconName="search"
        label={locales.get('admin_users.filter.search')}
        onClick={this.search}
      />
    </Filter>
  );

  public renderTab = (type: UsersListType, index: number) => {
    const { usersService } = this.props;
    const { currentTabIndex } = this.state;
    return (
      <Tab
        key={type}
        index={index}
        label={locales.get(`admin_users.${TAB_TYPES[index]}.title`)}
        onChange={this.changeTab}
        disabled={usersService.pendingUsers}
        active={currentTabIndex === index}
      />
    );
  };

  public renderTabs = () => (
    <Tabs>
      {TAB_TYPES.map(this.renderTab)}
    </Tabs>
  );

  public renderTabContent() {
    const { usersService, dialogService } = this.props;
    const {
      users, pendingUsers: loading, currentPage, pagesCount
    } = usersService;
    const { currentTabIndex } = this.state;
    switch (TAB_TYPES[currentTabIndex]) {
      case UsersListType.Users:
        return (
          <UsersList
            dialogService={dialogService}
            users={users.peek()}
            loading={loading}
            onClickAddUser={this.addUser}
            onClickDownload={this.downloadUsers}
            onDoubleClick={this.editUser}
            currentPage={currentPage}
            pagesCount={pagesCount}
            onPageChange={this.searchUsersWithPage}
          />
        );
      case UsersListType.Pending:
        return (
          <PendingApprovals
            usersService={usersService}
            dialogService={dialogService}
            onPageChange={this.searchUsersWithPage}
          />
        );
      default:
        throw new Error('Invalid UsersListType was specified');
    }
  }

  public render() {
    return (
      <>
        {this.renderFilter()}
        {this.renderTabs()}
        {this.renderTabContent()}
      </>
    );
  }
}

export default withRouter(UsersListView);
