import {
  action, IObservableArray, observable, runInAction
} from 'mobx';
import Service from '../Service';
import INotificationsDao from './INotificationsDao';
import AnnouncementModel from '../../models/AnnouncementModel';

export default class NotificationsService extends Service<INotificationsDao> {
  @observable public pendingAnnouncements = false;
  @observable public pendingAnnouncement = false;
  @observable public announcements: IObservableArray<AnnouncementModel> = observable<AnnouncementModel>([]);

  // API_XXXX
  @action public async sendAnnouncement(
    message: string,
    targetTrustees: string[]|null,
    mailMagazineAnnouncement: boolean|null
  ) {
    try {
      this.pendingAnnouncement = true;
      await this.dao.sendAnnouncement(message, targetTrustees, mailMagazineAnnouncement);
      await this.loadAnnouncements();
    } finally {
      runInAction(() => {
        this.pendingAnnouncement = false;
      });
    }
  }

  @action public async loadAnnouncements() {
    try {
      this.pendingAnnouncements = true;
      this.announcements.replace([]);
      const announcements = await this.dao.loadAnnouncements();

      runInAction(() => {
        this.announcements.replace(announcements);
      });
    } finally {
      runInAction(() => {
        this.pendingAnnouncements = false;
      });
    }
  }
}
