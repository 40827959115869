import React from 'react';

// Styles
import styles from './styles.scss';

interface IProps {
  label: string;
  to: string;
}

export default class NewTabLink extends React.PureComponent<IProps> {
  public onClickLink = () => {
    const { to } = this.props;
    window.open(to);
  };

  public render() {
    const { label } = this.props;
    return (
      <div className={styles.link} onClick={this.onClickLink}>
        {label}
      </div>
    );
  }
}
