import React from 'react';

// Styles
import styles from './styles.scss';

interface IProps {
  children: string;
  className?: string;
  tooltip?: string;
}

interface IState {
  hover: boolean;
}

export default class Text extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hover: false
    };
  }

  private setHoverState = () => this.setState({ hover: true });
  private unsetHoverState = () => this.setState({ hover: false });

  private renderTooltip(message: string) {
    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.tooltip}>
          <div className={styles.tooltipMessage}>{message}</div>
        </div>
      </div>
    );
  }

  public render() {
    const { children, className, tooltip } = this.props;
    const { hover } = this.state;

    return (
      <span className={styles.textContainer} onMouseLeave={this.unsetHoverState}>
        {hover && tooltip && this.renderTooltip(tooltip)}
        <span className={className} onMouseOver={this.setHoverState}>{children}</span>
      </span>
    );
  }
}
