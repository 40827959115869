import React from 'react';
import classnames from 'classnames';
import RadioPillButton from '../RadioPillButton';
// Styles
import styles from './styles.scss';

interface IOptions {
  value: string;
  label: string;
}

interface IProps {
  options: IOptions[];
  selectedValue?: string;
  onChange?: (value: string) => void;
}

export default class RadioPillButtonBar extends React.PureComponent<IProps> {
  public handleButtonClick = (value: string) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }
  };

  public render() {
    const { options, selectedValue } = this.props;

    return (
      <div className={classnames(styles.pillButtonBar)}>
        {
          options.map((option) => (
            <div key={option.value}>
              <RadioPillButton
                value={option.value}
                label={option.label}
                selected={option.value === selectedValue}
                onClick={this.handleButtonClick}
                className={styles.pillButton}
              />
            </div>
          ))
        }
      </div>
    );
  }
}
