// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Filter_filter_3ufKX {\n  margin: 15px 0;\n  display: flex;\n  flex-direction: row; }\n  .Filter_filter_3ufKX > .filterItem {\n    height: 36px;\n    margin-left: 15px; }\n    .Filter_filter_3ufKX > .filterItem:first-child {\n      margin-left: 0; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB,EAAA;EAHrB;IAMI,YAAY;IACZ,iBAAiB,EAAA;IAPrB;MAUM,cAAc,EAAA","file":"styles.scss","sourcesContent":[".filter {\n  margin: 15px 0;\n  display: flex;\n  flex-direction: row;\n\n  > :global(.filterItem) {\n    height: 36px;\n    margin-left: 15px;\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"filter": "Filter_filter_3ufKX"
};
module.exports = exports;
