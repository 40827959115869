import StorageDao from './StorageDao';

export default class StorageService {
  public storageDao: StorageDao;

  constructor(storageDao: StorageDao) {
    this.storageDao = storageDao;
  }

  public getItem(key: string, defaultValue?: string): string|null {
    const item = this.storageDao.getItem(key);

    if (!item && defaultValue) {
      return defaultValue;
    }

    return item;
  }

  public setItem(key: string, value: string) {
    this.storageDao.setItem(key, value);
  }

  public removeItem(key: string) {
    this.storageDao.removeItem(key);
  }
}
