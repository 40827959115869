import React from 'react';
import { observable, action, runInAction } from 'mobx';

export enum DialogType {
  Warn = 'warn',
  Confirm = 'confirm'
}

interface IDialogView {
  type: DialogType;
  onConfirm?: () => Promise<void>;
  onClose?: () => void;
  content: React.ReactNode|string;
  submitLabel?: string;
  cancelLabel?: string;
  ssoError?: boolean;
  passwordChange?: boolean;
}

export default class DialogService {
  @observable public dialog: IDialogView|null = null;

  @action public setDialog(dialog: IDialogView) {
    runInAction(() => {
      this.dialog = dialog;
    });
  }

  @action public unsetDialog() {
    runInAction(() => {
      this.dialog = null;
    });
  }
}
