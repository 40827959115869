import React from 'react';
import { observer } from 'mobx-react';

import { Column } from '../../../components/table';
import TabContent from '../../../components/TabContent';
import UsersTable from './UsersTable';
import Footer from '../../../components/Footer';
import Button, { ButtonSize, ButtonType } from '../../../components/Button';
import DialogService, { DialogType } from '../../../services/DialogService';
import UserModel, { UserStatus } from '../../../models/UserModel';
import locales from '../../../helpers/locales';

import styles from './styles.scss';

interface IProps {
  dialogService: DialogService;
  loading: boolean;
  users: UserModel[];
  onDoubleClick: (id: number) => void;
  onClickDownload: () => void;
  onClickAddUser: () => void;
  currentPage: number;
  pagesCount: number;
  onPageChange: (page: number) => void;
}

@observer
export default class UsersList extends React.Component<IProps> {
  static getStatusColor(user: UserModel) {
    switch (user.adminUserStatus) {
      case UserStatus.Active:
        return styles.positive;
      case UserStatus.Deleted:
        return styles.negative;
      default:
        return '';
    }
  }

  public getRowStyle = () => ({ cursor: 'pointer' });

  public onRowDoubleClick = (user: UserModel) => {
    const { onDoubleClick, dialogService } = this.props;
    if (!user.hasRequestedChange) {
      onDoubleClick(user.id);
      return;
    }
    dialogService.setDialog({
      type: DialogType.Warn,
      content: locales.get('admin_users.users_list.non_editable_user')
    });
  };

  public render() {
    const {
      users, loading, onClickAddUser, onClickDownload, currentPage, pagesCount, onPageChange
    } = this.props;

    const columns: Column[] = [{
      Header: locales.get('general.admin_user.branch_code'),
      id: 'branchCode',
      Cell: (row: { original: UserModel }) => row.original.branchCode
    }, {
      Header: locales.get('general.admin_user.employee_number'),
      id: 'employeeNumber',
      Cell: (row: { original: UserModel }) => row.original.employeeNumber
    }, {
      Header: locales.get('general.admin_user.name_kanji'),
      id: 'name',
      Cell: (row: { original: UserModel }) => row.original.name
    }, {
      Header: locales.get('general.admin_user.role'),
      id: 'roleString',
      Cell: (row: { original: UserModel }) => row.original.roleString
    }, {
      Header: locales.get('general.admin_user.created_at'),
      id: 'createdAtDateString',
      Cell: (row: { original: UserModel }) => row.original.createdAtDateString
    }, {
      Header: locales.get('general.admin_user.revoked_at'),
      id: 'revokedAtDateString',
      Cell: (row: { original: UserModel }) => row.original.revokedAtDateString
    }, {
      Header: locales.get('general.admin_user.admin_user_status'),
      id: 'statusString',
      Cell: (row: { original: UserModel }) => (
        <span className={UsersList.getStatusColor(row.original)}>{row.original.statusString}</span>
      )
    }];

    return (
      <>
        <TabContent>
          <UsersTable
            getRowStyle={this.getRowStyle}
            onRowDoubleClick={this.onRowDoubleClick}
            columns={columns}
            loading={loading}
            data={users}
            currentPage={currentPage}
            pagesCount={pagesCount}
            onPageChange={onPageChange}
          />
        </TabContent>
        <Footer>
          <Button
            label={locales.get('admin_users.users_list.add_user')}
            iconName="plus"
            type={ButtonType.Round}
            size={ButtonSize.Normal}
            onClick={onClickAddUser}
            disabled={loading}
          />
          <Button
            label={locales.get('admin_users.users_list.download')}
            iconName="download"
            type={ButtonType.Round}
            size={ButtonSize.Normal}
            onClick={onClickDownload}
            disabled={loading}
          />
        </Footer>
      </>
    );
  }
}
