// Models
import CustomerModel, { ICustomerObject } from './CustomerModel';
import InviteeModel, { IInviteeObject } from './InviteeModel';

export enum InvitationStatus {
  Initial = 'initial',
  Pending = 'pending',
  Linked = 'linked',
  Unlinked = 'unlinked'
}

export interface IInvitationsSearchParams {
  page: number;
  trustAccountId?: number;
  trustAccountNumber?: string;
  inviteeNameKanji?: string;
}

export interface IInvitationObject {
  id: number;
  trust_account_id: number;
  inviting_customer: ICustomerObject;
  invitee: IInviteeObject;
  status: string;
  expires_at: string;
  created_at: string;
  updated_at: string;
}

export default class InvitationModel {
  public id: number;
  public trustAccountId: number;
  public invitingCustomer: CustomerModel;
  public invitee: InviteeModel;
  public status: InvitationStatus;
  public expiresAt: Date;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(json: IInvitationObject) {
    this.id = json.id;
    this.trustAccountId = json.trust_account_id;
    this.invitingCustomer = new CustomerModel(json.inviting_customer);
    this.invitee = new InviteeModel(json.invitee);
    this.status = json.status as InvitationStatus;
    this.expiresAt = new Date(json.expires_at);
    this.createdAt = new Date(json.created_at);
    this.updatedAt = new Date(json.updated_at);
  }
}
