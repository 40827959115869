// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Checkbox_checkbox_2xPBR {\n  border-radius: 0; }\n\n.Checkbox_disabled_3WJGe {\n  opacity: 0.4; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAgB,EAAA;;AAGlB;EACE,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.checkbox {\n  border-radius: 0;\n}\n\n.disabled {\n  opacity: 0.4;\n}\n"]}]);
// Exports
exports.locals = {
	"checkbox": "Checkbox_checkbox_2xPBR",
	"disabled": "Checkbox_disabled_3WJGe"
};
module.exports = exports;
