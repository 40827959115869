import React from 'react';
import classnames from 'classnames';

// Styles
import styles from './styles.scss';

import stylesBig from './stylesBig.scss';
import stylesSmall from './stylesSmall.scss';

export enum DescriptionListSize {
  Big = 'big',
  Small = 'small'
}

interface IProps {
  className?: string;
  size: DescriptionListSize;
}

export default class DescriptionList extends React.PureComponent<IProps> {
  public render() {
    const { children, className, size } = this.props;
    let stylesSize = stylesBig[DescriptionListSize.Big];
    if (size === DescriptionListSize.Small) {
      stylesSize = stylesSmall[size];
    }
    return (
      <div className={classnames(styles.list, className, stylesSize)}>
        {children}
      </div>
    );
  }
}
