import React from 'react';
import classnames from 'classnames';
import RadioPillButtonBar from '../RadioPillButtonBar';
import OptionList from '../OptionList';
// Styles
import styles from './styles.scss';
// Helpers
import locales from '../../helpers/locales';

// TODO have an enum
const ALL_CUSTOMERS_VALUE = 'all';
const BY_AGENCY_VALUE = 'agency';

interface IAgency {
  id: string;
  label: string;
}

interface IProps {
  agencies: IAgency[];
  selectedTrustees: string[]|null;
  onSelectedAgenciesChange?: (selectedvalues: string[]|null) => void;
  containsSelectionError?: boolean;
}

interface IState {
  selectedPillButtonValue: string;
}

export default class TargetCustomerOptions extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { selectedPillButtonValue: props.selectedTrustees == null ? 'all' : 'agency' };
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const newState: IState = { selectedPillButtonValue: nextProps.selectedTrustees == null ? 'all' : 'agency' };

    if (newState.selectedPillButtonValue === prevState.selectedPillButtonValue) {
      return null;
    }

    return newState;
  }

  private onTargetTypeChange(value: string) {
    const { selectedPillButtonValue } = this.state;
    const { onSelectedAgenciesChange } = this.props;

    if (value === selectedPillButtonValue) {
      return;
    }

    this.setState({ selectedPillButtonValue: value });

    if (!onSelectedAgenciesChange) {
      return;
    }

    const selectedAgencies: string[]|null = (value === BY_AGENCY_VALUE) ? [] : null;
    onSelectedAgenciesChange(selectedAgencies);
  }

  public render() {
    const {
      agencies,
      selectedTrustees,
      onSelectedAgenciesChange,
      containsSelectionError
    } = this.props;

    const { selectedPillButtonValue } = this.state;

    return (
      <div className={classnames(styles.targetCustomerOptions)}>
        <RadioPillButtonBar
          options={[
            { value: ALL_CUSTOMERS_VALUE, label: locales.get('notification.pill_buttons.all_customers') },
            { value: BY_AGENCY_VALUE, label: locales.get('notification.pill_buttons.by_agency') }
          ]}
          selectedValue={selectedPillButtonValue}
          onChange={this.onTargetTypeChange}
        />
        <OptionList
          options={agencies}
          selectedOptions={selectedTrustees || []}
          disabled={selectedPillButtonValue === ALL_CUSTOMERS_VALUE}
          onChange={onSelectedAgenciesChange}
        />
        <div className={classnames(styles.selectionErrorMessage)}>
          {containsSelectionError ? locales.get('notification.selectionErrorMessage') : ''}
        </div>
      </div>
    );
  }
}
