import React from 'react';
import {
  HashRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';
import { inject } from 'mobx-react';

import DailyProcessView from './DailyProcessView';
import NotificationView from './NotificationView';
import ReportsView from './ReportsView';

import AccountService, { AccountPermissions } from '../../services/AccountService';

interface IProps extends RouteComponentProps<{}> {
  accountService: AccountService;
}

@inject('accountService')
class UsersManager extends React.PureComponent<IProps> {
  private renderRoutes() {
    const { accountService } = this.props;
    const routes = [];
    if (accountService.hasAccountPermission(AccountPermissions.ViewDailyProcess)) {
      routes.push(<Route key="daily" path="/dashboard/daily" component={DailyProcessView} />);
    }
    if (accountService.hasAccountPermission(AccountPermissions.ViewReport)) {
      routes.push(<Route key="reports" path="/dashboard/reports" component={ReportsView} />);
    }
    if (accountService.hasAccountPermission(AccountPermissions.ViewSendNotification)) {
      routes.push(
        <Route key="notification" path="/dashboard/notification" component={NotificationView} />
      );
    }
    return routes;
  }

  public render() {
    return (
      <Router>
        <Switch>
          {this.renderRoutes()}
        </Switch>
      </Router>
    );
  }
}

export default withRouter(UsersManager);
