// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Page_page_1RbjX {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  overflow: auto; }\n\n.Page_inner_1xnza {\n  width: 100%;\n  max-width: 998px;\n  padding: 0 10px 5px 10px;\n  display: flex;\n  flex-direction: column; }\n", "",{"version":3,"sources":["styles.scss","../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EACE,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,cAAc,EAAA;;AAGhB;EACE,WAAW;EACX,gBCToB;EDUpB,wBAAwB;EACxB,aAAa;EACb,sBAAsB,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.page {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  overflow: auto;\n}\n\n.inner {\n  width: 100%;\n  max-width: $page-max-width;\n  padding: 0 10px 5px 10px;\n  display: flex;\n  flex-direction: column;\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"page": "Page_page_1RbjX",
	"inner": "Page_inner_1xnza"
};
module.exports = exports;
