import React from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

// Styles
import styles from './styles.scss';

export interface ISelectOption {
  label?: string;
  value?: string;
}

interface IProps {
  className?: string;
  name?: string;
  value: string;
  error?: string;
  placeholder?: string;
  options: ISelectOption[];
  onChange: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  onFocus?: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  onBlur: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
}

interface IState {
  isSelecting: boolean;
}

export default class SelectField extends React.Component<IProps, IState> {
  static defaultProps = {
    className: '',
    name: '',
    error: '',
    placeholder: '',
    onFocus: () => undefined
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      isSelecting: false
    };
  }

  get shouldShowMessage() {
    const { error } = this.props;
    const { isSelecting } = this.state;
    return error && !isSelecting;
  }

  public onBlur = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    this.setState({ isSelecting: false });
    const { onBlur } = this.props;
    onBlur(event);
  };

  public onFocus = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    this.setState({ isSelecting: true });
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  public renderPlaceholderOption = () => {
    const { placeholder } = this.props;
    if (!placeholder) {
      return null;
    }
    return <option value="">{placeholder}</option>;
  };

  public renderOptions = () => {
    const { options } = this.props;
    return options.map((option: ISelectOption) => (
      <option key={option.value} value={option.value}>{option.label}</option>
    ));
  };

  public renderMessage = () => {
    const { error } = this.props;
    if (!this.shouldShowMessage) {
      return null;
    }
    return (
      <div className={styles.message}>{error}</div>
    );
  };

  public render() {
    const {
      className, name, value, onChange
    } = this.props;
    return (
      <div
        className={classnames(styles.field, className, { [styles.error]: this.shouldShowMessage })}
      >
        <div className={styles.container}>
          <select
            className={styles.select}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            {this.renderPlaceholderOption()}
            {this.renderOptions()}
          </select>
          <div className={styles.dropdown}>
            <Icon className={styles.icon} name="chevron-down" />
          </div>
        </div>
        {this.renderMessage()}
      </div>
    );
  }
}
