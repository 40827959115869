import React from 'react';

interface IProps {
  name: string;
  className?: string;
}

export default class Icon extends React.PureComponent<IProps> {
  public render() {
    const { name, className } = this.props;
    return (
      <span className={`mutb-${name}-icon ${className}`} />
    );
  }
}
