import React from 'react';
import { inject, observer } from 'mobx-react';
// Containers/Components
import Title from '../../components/Title';
import Button, { ButtonType } from '../../components/Button';
import Loader from '../Loader';
// Services
import BatchService from '../../services/BatchService';
import DialogService, { DialogType } from '../../services/DialogService';
// Helpers
import locales from '../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps {
  batchService: BatchService;
  dialogService: DialogService;
}

@inject('batchService', 'dialogService')
@observer
export default class DailyProcessView extends React.Component<IProps> {
  private downloadPaymentData = async () => {
    const { batchService, dialogService } = this.props;
    try {
      await batchService.downloadPayments();
      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('daily_process.download_success')
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  private handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public render() {
    const { batchService: { pendingBatch: loading } } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <div>
          <Title className={styles.title}>{locales.get('daily_process.title')}</Title>
          <div className={styles.buttons}>
            <Button
              type={ButtonType.Round}
              label={locales.get('daily_process.download_payment_data')}
              onClick={this.downloadPaymentData}
            />
          </div>
        </div>
      </>
    );
  }
}
