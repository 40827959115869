import {
  action, IObservableArray, observable, runInAction
} from 'mobx';
import { saveAs } from 'file-saver';
import moment from 'moment';
// Services
import ManagedService from '../ManagedService';
// Models
import UserModel, { UserRole } from '../../models/UserModel';
// DAO
import UsersDao, { IUserCreationRequest, IUsersSearchParams, IUserUpdateRequest } from './UsersDao';
import AccountService from '../AccountService';
import AccountModel from '../../models/AccountModel';

export enum UserPermissions {
  ProceedWithRequest = 'proceed_with_request'
}

export default class UsersService extends ManagedService<UsersDao, UserPermissions> {
  public accountService: AccountService;
  @observable public pendingUsers = false;
  @observable public currentPage = 0;
  @observable public pagesCount = 0;
  @observable public users: IObservableArray<UserModel> = observable<UserModel>([]);

  constructor(dao: UsersDao, accountService: AccountService) {
    super(dao);
    this.accountService = accountService;
  }

  private get timestamp() {
    return moment().format('YYYYMMDDHHmmss');
  }

  // API_ADMIN02
  @action public async loadUsers(params: IUsersSearchParams): Promise<void> {
    const { account } = this.accountService;
    if (!account) {
      throw new Error('Attempted to use method without account has been loaded');
    }
    try {
      this.pendingUsers = true;
      this.users.replace([]);
      const { pagination: { current, last }, users } = await this.dao.loadUsers(params);

      this.resetPermissions();
      users.forEach((user) => this.buildPermissions(account, user));

      runInAction(() => {
        this.users.replace(users);
        this.currentPage = current;
        this.pagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  // API_ADMIN03
  @action public async requestUserCreation(request: IUserCreationRequest) {
    try {
      this.pendingUsers = true;
      await this.dao.requestUserCreation(request);
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  // API_ADMIN04
  @action public async requestUserUpdate(user: UserModel, request: IUserUpdateRequest) {
    try {
      this.pendingUsers = true;
      await this.dao.requestUserUpdate(user.id, request);
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  // API_ADMIN05
  @action public async requestUserDeletion(user: UserModel) {
    try {
      this.pendingUsers = true;
      await this.dao.requestUserDeletion(user.id);
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  // API_ADMIN06
  @action public async rejectRequests(users: UserModel[]) {
    try {
      this.pendingUsers = true;
      await this.dao.rejectRequests(users.map((user) => user.id));
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  // API_ADMIN07
  @action public async approveRequests(users: UserModel[]) {
    try {
      this.pendingUsers = true;
      await this.dao.approveRequests(users.map((user) => user.id));
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  // API_ADMIN02
  @action public async downloadUsers() {
    try {
      this.pendingUsers = true;
      const blob = await this.dao.downloadUsers();
      saveAs(blob, `users_${this.timestamp}.csv`);
    } finally {
      runInAction(() => {
        this.pendingUsers = false;
      });
    }
  }

  private buildPermissions(account: AccountModel, user: UserModel) {
    const isRequesterDifferent = account.role === UserRole.SystemAdministrator &&
    user.pendingUpdates &&
    user.pendingUpdates.requesterAdminUserId !== account.id;
    if (isRequesterDifferent) {
      this.addPermission(user, UserPermissions.ProceedWithRequest);
    }
  }
}
