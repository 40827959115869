// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CustomersList_link_Pnw5f {\n  color: var(--primary-color); }\n\n.CustomersList_positive_3DSzt {\n  color: #7ED321; }\n\n.CustomersList_negative_3aP3m {\n  color: var(--primary-color); }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,2BAA2B,EAAA;;AAG7B;EACE,cAAc,EAAA;;AAGhB;EACE,2BAA2B,EAAA","file":"styles.scss","sourcesContent":["@import \"../../../../styles/theme\";\n\n.link {\n  color: var(--primary-color);\n}\n\n.positive {\n  color: #7ED321;\n}\n\n.negative {\n  color: var(--primary-color);\n}\n"]}]);
// Exports
exports.locals = {
	"link": "CustomersList_link_Pnw5f",
	"positive": "CustomersList_positive_3DSzt",
	"negative": "CustomersList_negative_3aP3m"
};
module.exports = exports;
