import { UserRole } from '../../models/UserModel';
import ClaimModel, { ClaimStatus } from '../../models/ClaimModel';
import AccountModel from '../../models/AccountModel';

export enum ClaimPermissions {
  ReviewStart = 'start',
  ReviewAbort = 'abort',
  ReviewApproveReceipt = 'approve_receipt',
  ReviewRejectReceipt = 'reject_receipt',
  ReviewApprove = 'approve',
  ReviewReject = 'reject',
  ReviewDisagree = 'disagree',
  ReviewReset = 'reset',
  Lock = 'lock',
  Comment = 'comment',
  RejectComment = 'reject_comment'
}

interface IStatusPermissions {
  condition?: (account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => boolean;
  permissions?: ClaimPermissions[];
}

interface IRolesPermissionsMap {
  [key: string]: IStatusPermissions;
}
interface IPermissionsMap {
  [key: string]: IRolesPermissionsMap;
}

const workerPermissions = {
  // For submitted claims the worker can start the review
  [ClaimStatus.Submitted]: {
    permissions: [
      ClaimPermissions.ReviewStart
    ]
  },
  // The worker can approve or reject receipt and aboart if he is the initial reviewer
  [ClaimStatus.InitialReview]: {
    condition: (account: AccountModel, claim: ClaimModel) => (
      claim.initialReviewAdminUser ? account.id === claim.initialReviewAdminUser.id : false
    ),
    permissions: [
      ClaimPermissions.ReviewApproveReceipt,
      ClaimPermissions.ReviewRejectReceipt,
      ClaimPermissions.ReviewAbort,
      ClaimPermissions.Comment,
      ClaimPermissions.RejectComment
    ]
  },
  // The worker cannot start cliam review as a second approver
  [ClaimStatus.AwaitingCheck]: {},
  // And do not have any permissions for following claim states
  [ClaimStatus.Checking]: {},
  [ClaimStatus.Reviewed]: {},
  [ClaimStatus.PendingExport]: {},
  [ClaimStatus.Exported]: {},
  [ClaimStatus.Paid]: {},
  [ClaimStatus.Returned]: {},
  [ClaimStatus.Cancelled]: {}
};

function isContractOver(claim: ClaimModel) {
  const endDate = claim.customer.trustAccount.contractEndDate;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (endDate && endDate < today) {
    return true;
  }
  return false;
}

const checkerPermissions = {
  // For submitted claims the checker can start the review
  [ClaimStatus.Submitted]: {
    permissions: [
      ClaimPermissions.ReviewStart
    ]
  },
  // The checker can approve or reject receipt and aboart if he is the initial reviewer
  [ClaimStatus.InitialReview]: {
    condition: (account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => {
      if (permission === ClaimPermissions.Lock && isContractOver(claim)) {
        return false;
      }
      return claim.initialReviewAdminUser ?
        account.id === claim.initialReviewAdminUser.id : false;
    },
    permissions: [
      ClaimPermissions.ReviewApproveReceipt,
      ClaimPermissions.ReviewRejectReceipt,
      ClaimPermissions.ReviewAbort,
      ClaimPermissions.Lock,
      ClaimPermissions.Comment,
      ClaimPermissions.RejectComment
    ]
  },
  // The checker can start the second review if did not made initial review
  [ClaimStatus.AwaitingCheck]: {
    condition: (account: AccountModel, claim: ClaimModel) => (
      claim.initialReviewAdminUser ? account.id !== claim.initialReviewAdminUser.id : false
    ),
    permissions: [
      ClaimPermissions.ReviewStart
    ]
  },
  // The checker can approve, reject, disagree or abort if he are the second reviewer
  [ClaimStatus.Checking]: {
    condition: (account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => {
      if (permission === ClaimPermissions.Lock && isContractOver(claim)) {
        return false;
      }
      return claim.secondaryReviewAdminUser ?
        account.id === claim.secondaryReviewAdminUser.id : false;
    },
    permissions: [
      ClaimPermissions.ReviewApprove,
      ClaimPermissions.ReviewReject,
      ClaimPermissions.ReviewDisagree,
      ClaimPermissions.ReviewAbort,
      ClaimPermissions.Lock,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.Reviewed]: {
    condition: (account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => {
      if (permission === ClaimPermissions.Lock && isContractOver(claim)) {
        return false;
      }

      if (!claim.secondaryReviewAdminUser) {
        return false;
      }

      return account.id === claim.secondaryReviewAdminUser.id || permission === ClaimPermissions.ReviewReset;
    },
    permissions: [
      ClaimPermissions.ReviewReset,
      ClaimPermissions.Lock,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.PendingExport]: {
    permissions: [
      ClaimPermissions.ReviewReset,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.Exported]: {
    condition: (_account: AccountModel, claim: ClaimModel) => claim.isPaymentError,
    permissions: [
      ClaimPermissions.ReviewReset,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.Paid]: {},
  [ClaimStatus.Returned]: {},
  [ClaimStatus.Cancelled]: {}
};

export const administratorPermissions = {
  [ClaimStatus.Submitted]: {
    permissions: [
      ClaimPermissions.ReviewStart
    ]
  },
  [ClaimStatus.InitialReview]: {
    condition: (_account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => (
      !(permission === ClaimPermissions.Lock && isContractOver(claim))
    ),
    permissions: [
      ClaimPermissions.ReviewApproveReceipt,
      ClaimPermissions.ReviewRejectReceipt,
      ClaimPermissions.ReviewAbort,
      ClaimPermissions.Lock,
      ClaimPermissions.Comment,
      ClaimPermissions.RejectComment
    ]
  },
  // Unlike Worker and Checker, Administrator can approve his/her own initial review
  [ClaimStatus.AwaitingCheck]: {
    permissions: [
      ClaimPermissions.ReviewStart
    ]
  },
  // The administrator can approve, reject, disagree or abort
  [ClaimStatus.Checking]: {
    condition: (_account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => (
      !(permission === ClaimPermissions.Lock && isContractOver(claim))
    ),
    permissions: [
      ClaimPermissions.ReviewApprove,
      ClaimPermissions.ReviewReject,
      ClaimPermissions.ReviewDisagree,
      ClaimPermissions.ReviewAbort,
      ClaimPermissions.Lock,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.Reviewed]: {
    condition: (_account: AccountModel, claim: ClaimModel, permission: ClaimPermissions) => (
      !(permission === ClaimPermissions.Lock && isContractOver(claim))
    ),
    permissions: [
      ClaimPermissions.ReviewReset,
      ClaimPermissions.Lock,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.PendingExport]: {
    permissions: [
      ClaimPermissions.ReviewReset,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.Exported]: {
    condition: (_account: AccountModel, claim: ClaimModel) => claim.isPaymentError,
    permissions: [
      ClaimPermissions.ReviewReset,
      ClaimPermissions.Comment
    ]
  },
  [ClaimStatus.Paid]: {},
  [ClaimStatus.Returned]: {},
  [ClaimStatus.Cancelled]: {}
};

const noPermissions = {
  [ClaimStatus.Submitted]: {},
  [ClaimStatus.InitialReview]: {},
  [ClaimStatus.AwaitingCheck]: {},
  [ClaimStatus.Checking]: {},
  [ClaimStatus.Reviewed]: {},
  [ClaimStatus.PendingExport]: {},
  [ClaimStatus.Exported]: {},
  [ClaimStatus.Paid]: {},
  [ClaimStatus.Returned]: {},
  [ClaimStatus.Cancelled]: {}
};

export const permissions: IPermissionsMap = {
  [UserRole.Worker]: workerPermissions,
  [UserRole.Checker]: checkerPermissions,
  [UserRole.Administrator]: administratorPermissions,
  [UserRole.Support]: noPermissions,
  [UserRole.Department]: noPermissions,
  [UserRole.Corporate]: noPermissions,
  [UserRole.SystemAdministrator]: noPermissions
};
