import React, { ChangeEvent } from 'react';
import classnames from 'classnames';
// Styles
import styles from './styles.scss';

interface IProps {
  value: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  onChange?: (value: string, checked: boolean) => void;
}

export default class Checkbox extends React.PureComponent<IProps> {
  static defaultProps = {
    checked: false
  };

  public handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    if (onChange) {
      const { value, checked } = event.target;
      onChange(value, checked);
    }
  };

  public render() {
    const {
      value, label, checked, disabled
    } = this.props;

    const id = `checkbox_${value}`;

    return (
      <div>
        <input
          type="checkbox"
          id={id}
          className={classnames(styles.checkbox)}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={this.handleChange}
        />
        <label className={classnames({ [styles.disabled]: disabled })} htmlFor={id}>{label}</label>
      </div>
    );
  }
}
