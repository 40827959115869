import React from 'react';
import { observer } from 'mobx-react';
// Components
import Modal from '../../components/Modal';
import Button, { ButtonSize, ButtonType } from '../../components/Button';
// Services
import DialogService, { DialogType } from '../../services/DialogService';
import locales from '../../helpers/locales';

// Styles
import styles from './styles.scss';

interface IProps {
  dialogService: DialogService;
}

@observer
export default class Dialog extends React.Component<IProps> {
  public closeDialog = () => {
    const { dialogService } = this.props;
    const { dialog } = dialogService;
    if (dialog && dialog.onClose) {
      dialog.onClose();
    }
    // if sso login failed, redirect to '/' after clicking 'OK' button
    if (dialogService.dialog && (dialogService.dialog.ssoError || dialogService.dialog.passwordChange)) {
      window.location.href = '/';
    }
    dialogService.unsetDialog();
  };

  public onConfirm = async () => {
    const { dialogService } = this.props;
    const { dialog } = dialogService;
    if (!dialog || !dialog.onConfirm) {
      return;
    }

    dialogService.unsetDialog();
    await dialog.onConfirm();
  };

  public renderButtons() {
    const { dialogService: { dialog } } = this.props;
    if (!dialog) {
      return null;
    }
    const {
      type, onConfirm, submitLabel, cancelLabel
    } = dialog;
    switch (type) {
      case DialogType.Warn:
        return (
          <div className={styles.buttons}>
            <Button
              type={ButtonType.Round}
              size={ButtonSize.Normal}
              label={submitLabel || locales.get('modal.ok')}
              onClick={this.closeDialog}
            />
          </div>
        );
      case DialogType.Confirm:
        if (!onConfirm) {
          throw new Error('Missing onConfirm prop even though DialogType.Confirm was specified');
        }
        return (
          <div className={styles.buttons}>
            <Button
              type={ButtonType.Round}
              size={ButtonSize.Normal}
              label={cancelLabel || locales.get('modal.cancel')}
              onClick={this.closeDialog}
            />
            <Button
              type={ButtonType.Round}
              size={ButtonSize.Normal}
              label={submitLabel || locales.get('modal.ok')}
              onClick={this.onConfirm}
            />
          </div>
        );
      default:
        throw new Error('Invalid DialogType was specified');
    }
  }

  public render() {
    const { dialogService } = this.props;
    if (!dialogService.dialog) {
      return null;
    }
    const { content } = dialogService.dialog;
    return (
      <Modal isOpen onClose={this.closeDialog}>
        <div className={styles.dialog}>
          {content}
          {this.renderButtons()}
        </div>
      </Modal>
    );
  }
}
