import React from 'react';
import classnames from 'classnames';
// Components
import Overlay from '../Overlay';
// Helpers
import locales from '../../helpers/locales';
import nextTick from '../../helpers/nextTick';
// Styles
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  closable?: boolean;
}

interface IState {
  rendered: boolean;
}

export default class Modal extends React.PureComponent<IProps, IState> {
  private isModalMounted = false;
  static defaultProps = {
    closable: false,
    onClose: () => undefined
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      rendered: false
    };
  }

  public componentDidMount() {
    const { isOpen } = this.props;
    this.isModalMounted = true;
    if (isOpen) {
      this.adjustLayout();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      if (isOpen) {
        this.adjustLayout();
      } else {
        // TODO: investigate the need of the following hack (for IE11)
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ rendered: false });
      }
    }
  }

  public componentWillUnmount() {
    this.isModalMounted = false;
  }

  // Adjust the layout on the next browser tick
  // On component first render, the layout could look incorrect in the IE11
  // We delay the layout adjustment to the next tick to correct the layout after initial render
  private adjustLayout = () => {
    nextTick(() => {
      if (this.isModalMounted) {
        this.setState({ rendered: true });
      }
    });
  };

  private renderCloseLink() {
    const { onClose } = this.props;
    return (
      <div className={styles.closeLink} onClick={onClose}>
        {locales.get('modal.close')}
      </div>
    );
  }

  private renderWindow() {
    const { children, className, closable } = this.props;
    const { rendered } = this.state;

    // This is a fix to address IE11's layout calculation in case of using flexbox
    // It will adjust the height of the modal AFTER initial render.
    const style = rendered ? { height: 'auto' } : {};

    return (
      <div className={styles.window}>
        <div className={classnames(styles.content, className)} style={style}>{children}</div>
        {closable && this.renderCloseLink()}
      </div>
    );
  }

  public render() {
    const { isOpen } = this.props;

    return isOpen ? (
      <Overlay>
        <div className={styles.modal}>
          <div className={styles.overlay} />
          <div className={styles.container}>
            {this.renderWindow()}
          </div>
        </div>
      </Overlay>
    ) : null;
  }
}
