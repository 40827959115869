// Interfaces
import IBatchDao from './IBatchDao';
// Services
import ApiService from '../ApiService';

const CLAIMS_ENDPOINT = '/admin/claims';
const BATCH_ENDPOINT = '/admin/reports';

export default class BatchDaoImpl implements IBatchDao {
  public api: ApiService;

  constructor(api: ApiService) {
    this.api = api;
  }

  public async downloadPayments(): Promise<Blob> {
    const response = await this.api.post<ArrayBuffer>(`${CLAIMS_ENDPOINT}/payments`, {}, {
      responseType: 'arraybuffer'
    });
    return new Blob([response.data], { type: 'text/csv' });
  }

  public async downloadKPIs(): Promise<Blob> {
    const response = await this.api.get<ArrayBuffer>(`${BATCH_ENDPOINT}/claims/status`, {
      responseType: 'arraybuffer'
    });
    return new Blob([response.data], { type: 'text/csv' });
  }

  public async downloadClaims(): Promise<Blob> {
    const response = await this.api.get<ArrayBuffer>(`${BATCH_ENDPOINT}/claims/export`, {
      responseType: 'arraybuffer'
    });
    return new Blob([response.data], { type: 'text/csv' });
  }

  public async downloadCustomers(): Promise<Blob> {
    const response = await this.api.get<ArrayBuffer>(`${BATCH_ENDPOINT}/customers/export`, {
      responseType: 'arraybuffer'
    });

    return new Blob([response.data], { type: 'text/csv' });
  }

  public async downloadBatchJobResults(): Promise<Blob> {
    // TODO: No spec is available so far
    return new Blob(['results']);
  }
}
