import React from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'debounce';

// Components
import Section from '../../../components/Section';
// Services
import CustomersService from '../../../services/CustomersService';
import DialogService, { DialogType } from '../../../services/DialogService';
// Models
import CustomerNotificationModel from '../../../models/CustomerNotificationModel';
// Helpers
import locales from '../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps {
  customerId: number;
  customersService: CustomersService;
  dialogService: DialogService;
}

interface IState {
  isPending: boolean;
}

@observer
class CustomerNotifications extends React.Component<IProps, IState> {
  public scrollableElement?: Element;
  public contentsWrapperElement?: Element;

  public scrollNotifications = debounce(
    async () => {
      if (!this.scrollableElement || !this.contentsWrapperElement) {
        throw new Error('Element refs cannot be retrieved');
      }

      const { customerId, customersService, dialogService } = this.props;
      const { notificationsCurrentPage, notificationsPagesCount } = customersService;

      const isScrolledToBottom =
        this.scrollableElement.getBoundingClientRect().bottom >=
        this.contentsWrapperElement.getBoundingClientRect().bottom;

      const hasMoreNotifications = notificationsCurrentPage < notificationsPagesCount;

      if (!isScrolledToBottom || !hasMoreNotifications) {
        return;
      }

      try {
        this.setState({ isPending: true });
        await customersService
          .loadMoreNotificationsById(customerId, notificationsCurrentPage + 1);
      } catch (error) {
        dialogService.setDialog({
          type: DialogType.Warn,
          content: error.message
        });
      } finally {
        this.setState({ isPending: false });
      }
    },
    1000
  );

  constructor(props: IProps) {
    super(props);
    this.state = {
      isPending: false
    };
  }

  public componentDidMount() {
    const scrollableElement = document.querySelector(`.${styles.notifications}`);
    const contentsWrapperElement = document.querySelector(`.${styles.notificationsInner}`);
    if (scrollableElement && contentsWrapperElement) {
      this.scrollableElement = scrollableElement;
      this.contentsWrapperElement = contentsWrapperElement;
    }
  }

  public renderLoader() {
    const { isPending } = this.state;
    if (!isPending) {
      return null;
    }
    return (
      <div className={styles.notificationsPaging}>
        {locales.get('loading')}
      </div>
    );
  }

  public renderNotifications() {
    const { customersService: { notifications } } = this.props;

    return notifications
      .map((notification: CustomerNotificationModel) => (
        <div className={styles.sectionItem} key={notification.id}>
          <div className={styles.title}>{notification.title}</div>
          <div>{notification.content}</div>
          <div>{notification.updatedAtString || notification.createdAtString}</div>
        </div>
      ));
  }

  public render() {
    return (
      <Section
        className={styles.notifications}
        title={locales.get('customer.notifications')}
        onScroll={this.scrollNotifications}
      >
        <div className={styles.notificationsInner}>
          {this.renderLoader()}
          {this.renderNotifications()}
        </div>
      </Section>
    );
  }
}

export default CustomerNotifications;
