import React from 'react';
import classnames from 'classnames';
// Components
import Icon from '../../../../components/Icon';
// Models
import ClaimModel from '../../../../models/ClaimModel';
// Helpers
import locales from '../../../../helpers/locales';
import { translateDate } from '../../../../helpers/translate';
// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  claim: ClaimModel;
}

export default class ClaimDetailsDetails extends React.PureComponent<IProps> {
  public renderAmount(claim: ClaimModel) {
    return claim.amount < 0 ?
      <div className={styles.minus}>{claim.amountString}</div> : claim.amountString;
  }

  public render() {
    const { className, claim } = this.props;
    const { paymentAccount } = claim;
    return (
      <div className={classnames(className, styles.details)}>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {locales.get('general.claim.amount')}
          </div>
          <div>{this.renderAmount(claim)}</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {locales.get('general.claim.payee')}
          </div>
          <div>{claim.payee}</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {locales.get('general.claim.customer')}
          </div>
          <div>{claim.customer.nameKanji}</div>
          <div>{claim.customer.nameKana}</div>
          <div>{locales.get(`general.customer_roles.${claim.customer.role}`)}</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {locales.get('general.claim.date')}
          </div>
          <div>{translateDate(claim.date)}</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {
              claim.customPaymentAccount ?
                (
                  <Icon className={styles.bikkuriMarkLightOrangeCircle} name="bikkuri-mark" />
                ) : ''
            }
            {locales.get('general.claim.payment_account')}
          </div>
          <div>{`${paymentAccount.bankName}（${paymentAccount.bankCode}) `}</div>
          <div>{`${paymentAccount.branchName}（${paymentAccount.branchCode}) `}</div>
          <div>{`${paymentAccount.accountType} ${paymentAccount.accountNumberString}`}</div>
          <div>{paymentAccount.accountName}</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {locales.get('general.claim.category')}
          </div>
          <div>{claim.categoryName}</div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailTitle}>
            {locales.get('general.claim.memo')}
          </div>
          <div>{claim.memo}</div>
        </div>
      </div>
    );
  }
}
