import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Components
import Section from '../../../components/Section';
import Breadcrumb from '../../../components/Breadcrumb';
import DescriptionList, { DescriptionListSize } from '../../../components/DescriptionList';
import Button, { ButtonSize, ButtonType } from '../../../components/Button';
import LinkButton from '../../../components/LinkButton';
import Title from '../../../components/Title';
import CustomerNotifications from './CustomerNotifications';
// Services
import CustomersService, { CustomerPermissions } from '../../../services/CustomersService';
import TrustAccountsService from '../../../services/TrustAccountsService';
import DialogService, { DialogType } from '../../../services/DialogService';
import SettingsService from '../../../services/SettingsService';
// Models
import CustomerEventModel from '../../../models/CustomerEventModel';
import AccountInformation from '../AccountInformation';
import TrustAccountModel from '../../../models/TrustAccountModel';
import CustomerModel, { CustomerAccountStatus, CustomerRole } from '../../../models/CustomerModel';
import { EventSourceType } from '../../../models/EventSourceModel';
// Helpers
import locales from '../../../helpers/locales';
// Styles
import styles from './styles.scss';
import Loader from '../../Loader';

interface IParams {
  customerId: string;
}

interface IProps extends RouteComponentProps<IParams> {
  customersService: CustomersService;
  trustAccountsService: TrustAccountsService;
  dialogService: DialogService;
  settingsService: SettingsService;
}

interface IState {
  isOpenAccount: boolean;
  isAccountLocked: boolean;
}

@inject('customersService', 'trustAccountsService', 'dialogService', 'settingsService')
@observer
class CustomerDetailsView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenAccount: false,
      isAccountLocked: false
    };
  }

  public async componentDidMount() {
    const { customersService, trustAccountsService, dialogService } = this.props;
    try {
      await customersService.loadCustomerById(this.customerId);
      const { customers } = customersService;
      const customer = customers.length > 0 ? customers[0] : null;
      if (!customer) {
        return;
      }

      const result = await Promise.all([
        trustAccountsService.loadTrustAccountById(customer.trustAccount.id),
        customersService.isAccountLocked(customer)
      ]);

      this.setState({ isAccountLocked: result[1] });
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message,
        onClose: this.redirectToCustomersList
      });
    }
  }

  private get customerId() {
    const { match: { params: { customerId } } } = this.props;
    return parseInt(customerId, 10);
  }

  static getAccountStatusColor(customer: CustomerModel) {
    switch (customer.accountStatus) {
      case CustomerAccountStatus.Active:
        return styles.positive;
      case CustomerAccountStatus.Locked:
      case CustomerAccountStatus.Closed:
        return styles.negative;
      default:
        return '';
    }
  }

  public getEventLabel(event: CustomerEventModel) {
    const { customersService: { customers } } = this.props;
    if (customers.length === 0) {
      throw new Error('Invoked getLabel without customer');
    }
    if (event.source.type !== EventSourceType.Customer) {
      return locales.get(`general.customer_events.${event.source.type}.${event.name}`);
    }
    const key = event.source.id === customers[0].id ? 'self' : 'other';
    return locales.get(`general.customer_events.${event.source.type}.${event.name}.${key}`);
  }

  public redirectToCustomersList = () => {
    const { history } = this.props;
    history.push('/customers');
  };

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public openAccount = () => {
    this.setState({ isOpenAccount: true });
  };

  public closeAccount = () => {
    this.setState({ isOpenAccount: false });
  };

  public confirmUnlinkAccount = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('customer.unlink_account_message.confirm'),
      onConfirm: this.unlinkAccount
    });
  };

  public unlinkAccount = async () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke unlinkAccount without customer');
    }
    const customer = customers[0];
    try {
      await customersService.unlinkAccount(customer);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('customer.unlink_account_message.success'),
        onClose: this.reloadCustomer
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public confirmReinitializeCustomer = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('customer.reinitialize_message.confirm'),
      onConfirm: this.reinitializeCustomer
    });
  };

  public reinitializeCustomer = async () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke reinitializeCustomer without customer');
    }
    const customer = customers[0];
    try {
      await customersService.reinitialize(customer);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('customer.reinitialize_message.success'),
        onClose: this.reloadCustomer
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public confirmUnlockAccount = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('customer.unlock_account_message.confirm'),
      onConfirm: this.unlockAccount
    });
  };

  public confirmStopTotp = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('customer.stop_totp_message.confirm'),
      onConfirm: this.stopTotp
    });
  };

  public unlockAccount = async () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke unlockAccount without customer');
    }
    const [customer] = customers;
    try {
      await customersService.unlockAccount(customer);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('customer.unlock_account_message.success'),
        onClose: this.reloadCustomer
      });

      // re-check the status to update the unlock button state
      const isAccountLocked = await customersService.isAccountLocked(customer);

      this.setState({
        isAccountLocked
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public stopTotp = async () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke stopTotp without customer');
    }
    const [customer] = customers;
    try {
      await customersService.stopTotp(customer);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('customer.stop_totp_message.success'),
        onClose: this.reloadCustomer
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public confirmToggleApplicationLock = () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('confirmToggleLockApp with no customer');
    }
    const customer = customers[0];
    const localeKey = customer.locked ? 'unlock' : 'lock';
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: this.renderApplicationLockConfirmation(customer),
      onConfirm: this.toggleApplicationLock,
      submitLabel: locales.get(`customer.${localeKey}_app_message.submit`)
    });
  };

  public toggleApplicationLock = async () => {
    const { customersService, dialogService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke toggleApplicationLock without customer');
    }
    const customer = customers[0];
    try {
      await customersService.toggleApplicationLock(customer);

      const localeKey = customer.locked ? 'unlock' : 'lock';
      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get(`customer.${localeKey}_app_message.success`),
        onClose: this.reloadCustomer
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public printQr = async () => {
    const { customersService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke toggleApplicationLock without customer');
    }
    const customer = customers[0];
    try {
      await customersService.downloadQrByCustomer(customer);
    } catch (error) {
      this.handleError(error);
    }
  };

  public generateQr = async () => {
    const { customersService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke toggleApplicationLock without customer');
    }
    const customer = customers[0];
    try {
      await customersService.generateQrByCustomer(customer);
      await customersService.loadCustomerById(this.customerId);
    } catch (error) {
      this.handleError(error);
    }
  };

  public generateQrTotp = async () => {
    const { customersService } = this.props;
    const { customers } = customersService;
    if (customers.length === 0) {
      throw new Error('tried to invoke generateQrTotp without customer');
    }
    const customer = customers[0];
    try {
      await customersService.generateQrTotpByCustomer(customer);
      await customersService.loadCustomerById(this.customerId);
    } catch (error) {
      this.handleError(error);
    }
  };

  private reloadCustomer = () => {
    const { customersService } = this.props;
    customersService.loadCustomerById(this.customerId).catch(this.handleError);
  };

  private renderApplicationLockConfirmation(customer: CustomerModel) {
    const localeKey = customer.locked ? 'unlock' : 'lock';
    const title = locales.get(`customer.${localeKey}_app_message.title`);
    const description =
      locales.get(
        `customer.${localeKey}_app_message.description`,
        { customer: customer.nameKanji }
      )
        .split('\n')
        .reduce(
          (prev: React.ReactNodeArray, current: string) => {
            prev.push(<span key={current}>{current}</span>);
            prev.push(<br key={`${current}-br`} />);
            return prev;
          },
          []
        );
    return (
      <div className={styles.confirm}>
        <Title key="title" className={styles.title}>{title}</Title>
        <div key="description">{description}</div>
      </div>
    );
  }

  private renderHead(customer: CustomerModel, trustAccount: TrustAccountModel) {
    const { isOpenAccount } = this.state;
    const texts = [locales.get('customer.customer_management'), customer.nameKanji];
    return (
      <>
        <Breadcrumb texts={texts} />
        <AccountInformation
          isOpen={isOpenAccount}
          account={trustAccount}
          onClickLink={this.openAccount}
          onCloseModal={this.closeAccount}
        />
      </>
    );
  }

  private renderDetails(customer: CustomerModel) {
    const linkStatus = locales.get(`general.trust_account_link_statuses.${customer.trustAccountLinkStatus}`);

    return (
      <Section title={locales.get('customer.personal_information')}>
        <div className={styles.customerInfo}>
          <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Small}>
            <div className="row">
              <div className="cell">{locales.get('general.customer.bank_customer_number')}</div>
              <div className="cell">{customer.bankCustomerNumber}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.branch')}</div>
              <div className="cell">
                {
                  customer.branchName ?
                    `${customer.branchCode} (${customer.branchName})` :
                    `${customer.branchCode}`
                }
              </div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.bank_torihiki_number')}</div>
              <div className="cell">{customer.bankTorihikiNumber}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('customer.name_kanji')}</div>
              <div className="cell">{customer.nameKanji}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('customer.name_kana')}</div>
              <div className="cell">{customer.nameKana}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.role')}</div>
              <div className="cell">
                {locales.get(`general.customer_roles.${customer.role}`)}
              </div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.customer.address_residential_postcode')}
              </div>
              <div className="cell">{customer.addressResidentialPostcode}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.address_residential')}</div>
              <div className="cell">{customer.addressResidential}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.name_corporate')}</div>
              <div className="cell">{customer.nameCorporate}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.phone_number')}</div>
              <div className="cell">{customer.phoneNumber}</div>
            </div>
          </DescriptionList>
          <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Small}>
            <div className="row">
              <div className="cell">{locales.get('general.customer.birthday')}</div>
              <div className="cell">{customer.birthdayString}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.gender')}</div>
              {/* NOTE: Hide gender value to avoid confusions */}
              <div className="cell">{/* locales.get(`general.gender.${customer.gender}`) */}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.relationship')}</div>
              <div className="cell">{customer.relationship}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.created_at')}</div>
              <div className="cell">{customer.createdAtString}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.deleted_at')}</div>
              <div className="cell">{customer.deletedAtString}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.email')}</div>
              <div className="cell">{customer.email}</div>
            </div>
            <div className="row">
              <div className="cell">{locales.get('general.customer.account_status')}</div>
              <div className={CustomerDetailsView.getAccountStatusColor(customer)}>
                {locales.get(`general.account_statuses.${customer.accountStatus}`)}
              </div>
            </div>
            <div className="row">
              <div className="cell">
                {locales.get('general.customer.trust_account_link_status')}
              </div>
              <div className="cell">{linkStatus}</div>
            </div>
          </DescriptionList>
        </div>
      </Section>
    );
  }

  private renderEvents() {
    const { customersService: { events } } = this.props;

    return events
      .map((event: CustomerEventModel) => (
        <div className={styles.sectionItem} key={`${event.name}-${event.at.getTime()}`}>
          <div className={styles.title}>{this.getEventLabel(event)}</div>
          <div>{event.atString}</div>
        </div>
      ));
  }

  private renderCustomerButtons(customer: CustomerModel) {
    const { customersService, settingsService } = this.props;
    const localeKey = customer.locked ? 'unlock' : 'lock';
    const isObserver = customer.role === CustomerRole.Observer;

    const canApplicationLock =
      customersService.hasPermission(customer, CustomerPermissions.ManageApplicationLock);
    const canGenerateQr =
      customersService.hasPermission(customer, CustomerPermissions.GenerateQr);
    const canGenerateQrTotp =
      customersService.hasPermission(customer, CustomerPermissions.GenerateQrTotp);

    return (
      <div className={styles.customerDetailsButtonsContainer}>
        <Button
          className={styles.button}
          label={locales.get(`customer.${localeKey}_app`)}
          type={ButtonType.Round}
          size={ButtonSize.Normal}
          onClick={this.confirmToggleApplicationLock}
          disabled={!canApplicationLock}
        />
        <Button
          className={styles.button}
          label={locales.get('customer.generate_qr')}
          type={ButtonType.Round}
          size={ButtonSize.Normal}
          onClick={this.generateQr}
          disabled={!canGenerateQr}
        />
        {
          settingsService.isProductSelectionOhitori ?
            (
              <Button
                className={styles.button}
                label={locales.get('customer.generate_qr_totp')}
                type={ButtonType.Round}
                size={ButtonSize.Normal}
                onClick={this.generateQrTotp}
                disabled={!canGenerateQrTotp || isObserver}
              />
            ) : ''
        }
      </div>
    );
  }

  private renderCustomerLinkButtons(customer: CustomerModel) {
    const { customersService, settingsService } = this.props;
    const { isAccountLocked } = this.state;
    const isObserver = customer.role === CustomerRole.Observer;
    const isAccountOwner = customer.role === CustomerRole.AccountOwner;

    const canAccountUnlink =
      customersService.hasPermission(customer, CustomerPermissions.ManageAccountLink);
    const canAccountLock = customer.email &&
      customersService.hasPermission(customer, CustomerPermissions.ManageAccountLock);
    const canStopTotp =
      customersService.hasPermission(customer, CustomerPermissions.StopTotp);
    // For reinitializing customers we rely on the same permissions since those actions
    // are used in the same context/for the same purpose, just for different customer roles.
    const canReinitializeCustomer = canAccountUnlink;

    return (
      <div className={styles.customerDetailsLinksContainer}>
        <LinkButton
          className={styles.linkButton}
          label={locales.get('customer.unlink_account')}
          onClick={this.confirmUnlinkAccount}
          disabled={!canAccountUnlink || isAccountOwner}
        />
        <LinkButton
          className={styles.linkButton}
          label={locales.get('customer.reinitialize')}
          onClick={this.confirmReinitializeCustomer}
          disabled={!canReinitializeCustomer}
        />
        <LinkButton
          className={styles.linkButton}
          label={locales.get('customer.unlock_account')}
          onClick={this.confirmUnlockAccount}
          disabled={!canAccountLock || !isAccountLocked}
        />
        {
          settingsService.isProductSelectionOhitori ?
            (
              <LinkButton
                className={styles.linkButton}
                label={locales.get('customer.stop_totp')}
                onClick={this.confirmStopTotp}
                disabled={!canStopTotp || isObserver}
              />
            ) : ''
        }
      </div>
    );
  }

  public render() {
    const { customersService, trustAccountsService, dialogService } = this.props;
    const { pendingCustomers } = customersService;
    const { pendingTrustAccounts } = trustAccountsService;
    const loading = pendingCustomers || pendingTrustAccounts;
    const { customers } = customersService;
    const customer = customers.length > 0 ? customers[0] : null;
    const { trustAccount } = trustAccountsService;
    if (!customer || !trustAccount) {
      return <Loader loading />;
    }

    return (
      <>
        <Loader loading={loading} />

        <div className={styles.head}>
          {this.renderHead(customer, trustAccount)}
        </div>

        <div className={styles.body}>
          <div className={styles.left}>
            {this.renderDetails(customer)}
          </div>
          <div className={styles.right}>
            <Section title={locales.get('customer.system_activities')}>
              {this.renderEvents()}
            </Section>
            <CustomerNotifications
              customerId={this.customerId}
              customersService={customersService}
              dialogService={dialogService}
            />
          </div>
        </div>

        <div className={styles.footer}>
          {this.renderCustomerButtons(customer)}
          {this.renderCustomerLinkButtons(customer)}
        </div>
      </>
    );
  }
}

export default withRouter(CustomerDetailsView);
