import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Containers/Components
import { Column } from '../../../../components/table';
import InvitationsTable from '../../InvitationsTable';
// Models
import InvitationModel from '../../../../models/InvitationModel';
// Locales
import locales from '../../../../helpers/locales';

interface IProps extends RouteComponentProps<{}> {
  loading: boolean;
  invitations: InvitationModel[];
  currentPage: number;
  pagesCount: number;
  onPageChange: (page: number) => void;
}

@observer
class InvitationsList extends React.Component<IProps> {
  public render() {
    const {
      invitations, loading, currentPage, pagesCount, onPageChange
    } = this.props;
    const columns: Column[] = [{
      Header: locales.get('general.trust_account.number'),
      id: 'trustAccount.number',
      Cell: (row: { original: InvitationModel }) => (
        row.original.invitingCustomer.trustAccount.number
      )
    }, {
      Header: locales.get('general.invitee.name_kanji'),
      id: 'nameKanji',
      Cell: (row: { original: InvitationModel }) => row.original.invitee.nameKanji
    }, {
      Header: locales.get('general.invitee.email'),
      id: 'email',
      Cell: (row: { original: InvitationModel }) => row.original.invitee.email
    }];

    return (
      <InvitationsTable
        columns={columns}
        loading={loading}
        data={invitations}
        currentPage={currentPage}
        pagesCount={pagesCount}
        onPageChange={onPageChange}
      />
    );
  }
}

export default withRouter(InvitationsList);
