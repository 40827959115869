// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Breadcrumb_breadcrumb_3OBiZ {\n  display: flex;\n  align-items: center;\n  color: #AAA; }\n  .Breadcrumb_breadcrumb_3OBiZ .Breadcrumb_item_SMdXZ {\n    padding-left: 10px; }\n    .Breadcrumb_breadcrumb_3OBiZ .Breadcrumb_item_SMdXZ:last-child {\n      color: #3D3D3D;\n      font-weight: bold; }\n", "",{"version":3,"sources":["styles.scss","../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WCOmB,EAAA;EDVrB;IAMI,kBAAkB,EAAA;IANtB;MASM,cCDsB;MDEtB,iBAAiB,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.breadcrumb {\n  display: flex;\n  align-items: center;\n  color: $text-sub-color;\n\n  .item {\n    padding-left: 10px;\n\n    &:last-child {\n      color: $text-primary-color;\n      font-weight: bold;\n    }\n  }\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"breadcrumb": "Breadcrumb_breadcrumb_3OBiZ",
	"item": "Breadcrumb_item_SMdXZ"
};
module.exports = exports;
