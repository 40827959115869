import React from 'react';

import Icon from '../Icon';
// Styles
import styles from './styles.scss';

interface IProps {
  flex: string;
  name?: string;
  value: string;
  placeholder?: string;
  children: React.ReactNode;
  onChange: (newValue: string, name?: string) => void;
}

export default class FilterSelect extends React.PureComponent<IProps> {
  public onChange = (event: { target: HTMLSelectElement }) => {
    const { onChange, name } = this.props;
    onChange(event.target.value, name);
  };

  public render() {
    const {
      flex, name, placeholder, children, value
    } = this.props;
    const style = { flex };

    return (
      <div className="filterItem" style={style}>
        <div className={styles.select}>
          <select required name={name} value={value} onChange={this.onChange}>
            {placeholder && <option value="" hidden>{placeholder}</option>}
            {children}
          </select>
          <div className={styles.dropdown}>
            <Icon className={styles.icon} name="chevron-down" />
          </div>
        </div>
      </div>
    );
  }
}
