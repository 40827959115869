import { translateDate } from '../helpers/translate';

export interface IClaimConfirmationObject {
  id: number;
  name_kanji: string;
  created_at: string;
}

export default class ClaimConfirmationModel {
  public id: number;
  public nameKanji: string;
  public createdAt: Date;

  constructor(json: IClaimConfirmationObject) {
    this.id = json.id;
    this.nameKanji = json.name_kanji;
    this.createdAt = new Date(json.created_at);
  }

  get createdAtString() {
    return translateDate(this.createdAt);
  }
}
