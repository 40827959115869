import {
  action, computed, observable, runInAction
} from 'mobx';
// Services
import ManagedService from '../ManagedService';
import AccountDao from './AccountDao';
// Models
import AccountModel, { AccountState } from '../../models/AccountModel';
import { UserRole } from '../../models/UserModel';

export enum AccountPermissions {
  ViewCustomers = 'view_customers',
  ViewUsers = 'view_users',
  ViewClaimsList = 'view_claims_list',
  PrintAwaitingQr = 'print_awaiting_qr',
  ViewClaimDetails = 'view_claim_details',
  ViewDailyProcess = 'view_daily_process',
  ViewReport = 'view_report',
  ViewSendNotification = 'view_send_notification'
}

const PERMISSIONS_MAP = {
  [UserRole.Worker]: [
    AccountPermissions.ViewCustomers,
    AccountPermissions.ViewDailyProcess,
    AccountPermissions.ViewClaimsList,
    AccountPermissions.ViewClaimDetails,
    AccountPermissions.PrintAwaitingQr
  ],
  [UserRole.Checker]: [
    AccountPermissions.ViewCustomers,
    AccountPermissions.ViewDailyProcess,
    AccountPermissions.ViewReport,
    AccountPermissions.ViewClaimsList,
    AccountPermissions.ViewClaimDetails,
    AccountPermissions.PrintAwaitingQr
  ],
  [UserRole.Administrator]: [
    AccountPermissions.ViewCustomers,
    AccountPermissions.ViewDailyProcess,
    AccountPermissions.ViewReport,
    AccountPermissions.ViewSendNotification,
    AccountPermissions.ViewClaimsList,
    AccountPermissions.ViewClaimDetails,
    AccountPermissions.PrintAwaitingQr
  ],
  [UserRole.Support]: [
    AccountPermissions.ViewCustomers,
    AccountPermissions.ViewClaimDetails
  ],
  [UserRole.Corporate]: [
    AccountPermissions.ViewCustomers,
    AccountPermissions.ViewReport,
    AccountPermissions.ViewClaimDetails
  ],
  [UserRole.Department]: [
    AccountPermissions.ViewCustomers,
    AccountPermissions.ViewReport,
    AccountPermissions.ViewClaimsList,
    AccountPermissions.ViewClaimDetails
  ],
  [UserRole.SystemAdministrator]: [
    AccountPermissions.ViewUsers
  ]
};

export default class AccountService extends ManagedService<AccountDao, AccountPermissions> {
  @observable public accountState: AccountState = AccountState.Unknown;
  @observable public account: AccountModel|null = null;
  @observable public pendingAccount = false;

  @computed get isLoggedIn() {
    return this.accountState === AccountState.SignedIn;
  }

  constructor(dao: AccountDao) {
    super(dao);
    this.dao.addSessionTimeoutHandler(this.sessionTimeoutHandler.bind(this));
  }

  @action public setAccount(account: AccountModel|null) {
    this.account = account;
  }

  // API_ADMIN01
  @action public async load() {
    try {
      this.accountState = AccountState.Pending;
      this.pendingAccount = true;
      const state = await this.dao.load();
      const account = await this.handleResultState(state);

      runInAction(() => {
        this.accountState = state;
        this.account = account;
      });
    } catch (error) {
      runInAction(() => {
        this.accountState = AccountState.Unknown;
      });
      throw error;
    } finally {
      runInAction(() => {
        this.pendingAccount = false;
      });
    }
  }

  @action public async login(email: string, password: string, code?: string) {
    try {
      this.pendingAccount = true;
      const state = await this.dao.login(email, password, code);
      const account = await this.handleResultState(state);
      runInAction(() => {
        this.accountState = state;
        this.account = account;
      });
    } catch (error) {
      runInAction(() => {
        this.accountState = AccountState.Unknown;
      });
      throw error;
    } finally {
      runInAction(() => {
        this.pendingAccount = false;
      });
    }
  }

  @action public async ssoLogin(accessToken: string, idToken: string, refreshToken: string) {
    this.pendingAccount = true;
    const state = await this.dao.ssoLogin(accessToken, idToken, refreshToken);
    await this.handleResultState(state);
  }

  @action public async logout() {
    try {
      this.pendingAccount = true;
      const state = await this.dao.logout();
      const account = await this.handleResultState(state);
      runInAction(() => {
        this.accountState = state;
        this.account = account;
      });
    } finally {
      runInAction(() => {
        this.pendingAccount = false;
      });
    }
  }

  @action public async logoutForPasswordChange() {
    try {
      this.pendingAccount = true;
      const state = await this.dao.logoutForPasswordChange();
      const account = await this.handleResultState(state);
      runInAction(() => {
        this.accountState = state;
        this.account = account;
      });
    } finally {
      runInAction(() => {
        this.pendingAccount = false;
      });
    }
  }

  @action public async completeNewPassword(newPassword: string): Promise<void> {
    try {
      this.pendingAccount = true;

      await this.dao.completeNewPassword(newPassword);
      const state = await this.dao.logoutForPasswordChange();
      const account = await this.handleResultState(state);

      runInAction(() => {
        this.accountState = state;
        this.account = account;
      });
    } finally {
      runInAction(() => {
        this.pendingAccount = false;
      });
    }
  }

  @action public async changePassword(oldPassword: string, newPassword: string) {
    try {
      this.pendingAccount = true;
      await this.dao.changePassword(oldPassword, newPassword);
      await this.logoutForPasswordChange();
    } finally {
      runInAction(() => {
        this.pendingAccount = false;
      });
    }
  }

  public hasAccountPermission(permittedAction: AccountPermissions) {
    if (!this.account) {
      return false;
    }

    return super.hasPermission(this.account, permittedAction);
  }

  @action private sessionTimeoutHandler() {
    this.accountState = AccountState.Unknown;
  }

  private async handleResultState(state: AccountState): Promise<AccountModel|null> {
    if (state !== AccountState.SignedIn && state !== AccountState.PasswordExpired) {
      return null;
    }
    const account = await this.dao.getAccount();
    if (account) {
      this.buildPermissions(account);
    }
    return account;
  }

  private buildPermissions(account: AccountModel) {
    this.resetPermissions();
    PERMISSIONS_MAP[account.role].forEach((permission) => {
      this.addPermission(account, permission);
    });
  }
}
