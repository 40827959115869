// Services
import Service from './Service';
// Models
import Model from '../models/Model';

export default class ManagedService<DaoType, PermissionType> extends Service<DaoType> {
  public permissions = new Map<number, Set<PermissionType>>();

  public addPermission(model: Model, permission: PermissionType) {
    let permissions = this.permissions.get(model.id);
    if (!permissions) {
      permissions = new Set<PermissionType>();
      this.permissions.set(model.id, permissions);
    }

    permissions.add(permission);
  }

  public resetPermissions() {
    this.permissions.clear();
  }

  public hasPermission(model: Model, action: PermissionType): boolean {
    const permissions = this.permissions.get(model.id);
    if (!permissions) {
      return false;
    }

    return permissions.has(action);
  }
}
