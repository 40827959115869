import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export type RequestConfig = AxiosRequestConfig;
export type ApiResponse<T = any> = AxiosResponse<T>;
export type RequestInterceptor = (config: RequestConfig) => RequestConfig | Promise<RequestConfig>;
export type ResponseInterceptorFulfilled = (value: AxiosResponse) =>
  AxiosResponse<any>|Promise<AxiosResponse<any>>;
export type ResponseInterceptorRejected = (error: { response?: { status: number } }) => void;

export default class ApiService {
  static globalHeaders = new Map<string, string>();
  static globalInterceptors: RequestInterceptor[] = [];

  private axios: AxiosInstance;
  private commonHeaders = new Map<string, string>();
  private requestInterceptors: number[] = [];
  private responseInterceptors: number[] = [];

  constructor(baseURL: string, validateStatus?: (status: number) => boolean) {
    const axiosConfig: RequestConfig = { baseURL };
    if (validateStatus) {
      axiosConfig.validateStatus = validateStatus;
    }
    this.axios = axios.create(axiosConfig);

    this.addRequestInterceptor(async (cfg) => {
      /* eslint-disable no-param-reassign */
      ApiService.globalHeaders.forEach((val, name) => { cfg.headers[name] = val; });
      this.commonHeaders.forEach((val, name) => { cfg.headers[name] = val; });

      let newCfg = cfg;
      // eslint-disable-next-line no-restricted-syntax
      for (const interceptor of ApiService.globalInterceptors) {
        newCfg = await interceptor(newCfg);
      }

      return newCfg;
    });
  }

  public addRequestInterceptor(interceptor: RequestInterceptor): void {
    this.requestInterceptors.push(this.axios.interceptors.request.use(interceptor));
  }

  public clearRequestInterceptors(): void {
    this.requestInterceptors.forEach((interceptorId) => {
      this.axios.interceptors.request.eject(interceptorId);
    });
  }

  public addResponseInterceptor(
    onFulfilled: ResponseInterceptorFulfilled,
    onRejected: ResponseInterceptorRejected
  ): void {
    this.responseInterceptors.push(this.axios.interceptors.response.use(onFulfilled, onRejected));
  }

  public clearResponseInterceptors(): void {
    this.responseInterceptors.forEach((interceptorId) => {
      this.axios.interceptors.response.eject(interceptorId);
    });
  }

  public get<T>(url: string, cfg?: RequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(url, cfg);
  }

  public put<T>(url: string, data?: any, cfg?: RequestConfig): Promise<ApiResponse<T>> {
    return this.axios.put<T>(url, data, cfg);
  }

  public patch<T>(url: string, data?: any, cfg?: RequestConfig): Promise<ApiResponse<T>> {
    return this.axios.patch<T>(url, data, cfg);
  }

  public post<T>(url: string, data?: any, cfg?: RequestConfig): Promise<any> {
    return this.axios.post<T>(url, data, cfg);
  }

  public delete(url: string, cfg?: RequestConfig): Promise<ApiResponse> {
    return this.axios.delete(url, cfg);
  }

  public addDefaultHeader(name: string, value: string) {
    this.commonHeaders.set(name, value);
  }

  public deleteDefaultHeader(name: string) {
    this.commonHeaders.delete(name);
  }

  // Adds headers which will be persistent across all instances
  public addGlobalHeader(name: string, value: string) {
    ApiService.globalHeaders.set(name, value);
  }

  public deleteGlobalHeader(name: string) {
    ApiService.globalHeaders.delete(name);
  }

  public addGlobalInterceptor(interceptor: RequestInterceptor) {
    ApiService.globalInterceptors.push(interceptor);
  }

  public removeGlobalInterceptor(interceptor: RequestInterceptor) {
    ApiService.globalInterceptors = ApiService.globalInterceptors.filter((ip) => ip !== interceptor);
  }
}
