import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
// Containers/Components
import Breadcrumb from '../../../components/Breadcrumb';
import Title from '../../../components/Title';
import DescriptionList, { DescriptionListSize } from '../../../components/DescriptionList';
import InputField, { InputFieldType } from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import LinkButton from '../../../components/LinkButton';
import Button, { ButtonSize } from '../../../components/Button';
// Models
import UserModel, { UserRole } from '../../../models/UserModel';
// Services
import FormService from '../../../services/FormService';
import UsersService from '../../../services/UsersService';
import DialogService, { DialogType } from '../../../services/DialogService';
// Locales
import locales from '../../../helpers/locales';

import styles from './styles.scss';
import Loader from '../../Loader';

const ROLE_OPTIONS = Object.keys(UserRole)
  .map((key: string) => ({
    value: UserRole[key],
    label: locales.get(`general.admin_user_roles.${UserRole[key]}`)
  }));

const BRANCH_CODE_FIELD = locales.get('general.admin_user.branch_code');
const EMPLOYEE_NUMBER_FIELD = locales.get('general.admin_user.employee_number');
const NAME_FIELD = locales.get('general.admin_user.name_kanji');
const ROLE_FIELD = locales.get('general.admin_user.role');

interface IParams {
  userId: string;
}

interface IProps extends RouteComponentProps<IParams> {
  usersService: UsersService;
  dialogService: DialogService;
}

interface IState {
  originalBranchCode: string;
  originalName: string;
  originalRole: string;
}

@inject('usersService', 'dialogService')
@observer
class EditUserView extends React.Component<IProps, IState> {
  private form: FormService;

  constructor(props: IProps) {
    super(props);
    this.form = new FormService();
    this.state = {
      originalBranchCode: '',
      originalName: '',
      originalRole: ''
    };
  }

  public async componentDidMount() {
    const user = this.getUser();
    if (!user) {
      return;
    }
    this.form
      .setField(BRANCH_CODE_FIELD, 'required|digits:3', user.branchCode)
      .setField(NAME_FIELD, 'required', user.name)
      .setField(ROLE_FIELD, 'required', user.role);

    this.setState({
      originalBranchCode: user.branchCode,
      originalName: user.name,
      originalRole: user.role
    });
  }

  getUser() {
    const { match: { params: { userId } } } = this.props;
    const id = Number(userId);
    const { usersService: { users } } = this.props;

    const isNotLoaded = users.length === 0;
    if (isNotLoaded) {
      return undefined;
    }

    const editingUser = users.find((user) => user.id === id);
    return editingUser;
  }

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public changeField = (
    event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>
  ) => {
    this.form.setValue(event.currentTarget.name, event.currentTarget.value);
  };

  public blurField = (
    event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLSelectElement>
  ) => {
    this.form.validate(event.currentTarget.name);
  };

  public submitUserUpdate = () => {
    if (!this.form.validateAll()) {
      return;
    }
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      onConfirm: this.requestUserUpdate,
      content: locales.get('admin_user_edit.messages.confirmation_edit')
    });
  };

  public submitUserDeletion = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      onConfirm: this.requestUserDeletion,
      content: locales.get('admin_user_edit.messages.confirmation_delete')
    });
  };

  public requestUserUpdate = async () => {
    const { usersService, dialogService } = this.props;
    const user = this.getUser();
    if (!user) {
      throw new Error('Attempted to update user without user');
    }

    try {
      await usersService.requestUserUpdate(user, {
        branch_code: this.form.getValue(BRANCH_CODE_FIELD),
        name_kanji: this.form.getValue(NAME_FIELD),
        role: this.form.getValue(ROLE_FIELD)
      });

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('admin_user_edit.messages.success'),
        onClose: this.closeResultOnSuccess
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public requestUserDeletion = async () => {
    const { usersService, dialogService } = this.props;
    const user = this.getUser();
    if (!user) {
      throw new Error('Attempted to update user without user');
    }

    try {
      await usersService.requestUserDeletion(user);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('admin_user_edit.messages.success'),
        onClose: this.closeResultOnSuccess
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public closeResultOnSuccess = () => {
    const { history } = this.props;
    history.push('/users/pending');
  };

  hasFormChanges() {
    const { originalBranchCode, originalName, originalRole } = this.state;
    return (
      originalBranchCode !== this.form.getValue(BRANCH_CODE_FIELD) ||
      originalName !== this.form.getValue(NAME_FIELD) ||
      originalRole !== this.form.getValue(ROLE_FIELD)
    );
  }

  public renderHead() {
    const texts = [
      locales.get('navigation.users_management'),
      locales.get('admin_user_edit.breadcrumb.edit')
    ];
    return (
      <>
        <div className={styles.head}>
          <Breadcrumb texts={texts} />
        </div>
        <Title>{locales.get('admin_user_edit.title')}</Title>
      </>
    );
  }

  public renderForm(user: UserModel) {
    return (
      <div className={styles.form}>
        <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Big}>
          <div className="row">
            <div className={classnames('cell', styles.listTitle)}>{BRANCH_CODE_FIELD}</div>
            <InputField
              name={BRANCH_CODE_FIELD}
              className={classnames('cell', styles.field)}
              type={InputFieldType.Text}
              value={this.form.getValue(BRANCH_CODE_FIELD)}
              error={this.form.getError(BRANCH_CODE_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
          <div className="row">
            <div className={classnames('cell', styles.listTitle)}>{EMPLOYEE_NUMBER_FIELD}</div>
            <div>{user.employeeNumber}</div>
          </div>
          <div className={classnames('row', styles.spacer)}>
            <div className={classnames('cell', styles.listTitle)}>{NAME_FIELD}</div>
            <InputField
              name={NAME_FIELD}
              className={classnames('cell', styles.field)}
              type={InputFieldType.Text}
              value={this.form.getValue(NAME_FIELD)}
              error={this.form.getError(NAME_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
          <div className="row">
            <div className={classnames('cell', styles.listTitle)}>{ROLE_FIELD}</div>
            <SelectField
              name={ROLE_FIELD}
              className={classnames('cell', styles.field)}
              placeholder={locales.get('admin_user_edit.role.placeholder')}
              options={ROLE_OPTIONS}
              value={this.form.getValue(ROLE_FIELD)}
              error={this.form.getError(ROLE_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
        </DescriptionList>
      </div>
    );
  }

  public renderButtons() {
    return (
      <div className={styles.buttons}>
        <Button
          label={locales.get('admin_user_edit.save')}
          size={ButtonSize.Big}
          disabled={!this.hasFormChanges() || !this.form.isFormFilled()}
          onClick={this.submitUserUpdate}
        />
        <LinkButton
          label={locales.get('admin_user_edit.delete')}
          onClick={this.submitUserDeletion}
        />
      </div>
    );
  }

  public render() {
    const { usersService: { pendingUsers: loading } } = this.props;
    const user = this.getUser();

    if (!user) {
      return <Redirect to="/users" />;
    }

    return (
      <>
        <Loader loading={loading} />
        <div className={styles.user}>
          {this.renderHead()}
          {this.renderForm(user)}
          {this.renderButtons()}
        </div>
      </>
    );
  }
}

export default withRouter(EditUserView);
