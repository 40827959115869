import React from 'react';
import { observer } from 'mobx-react';
// Components
import Section from '../../../../components/Section';
import Modal from '../../../../components/Modal';
import LinkButton from '../../../../components/LinkButton';
import TextAreaField from '../../../../components/TextAreaField';
import Button, { ButtonType } from '../../../../components/Button';
// Models
import ClaimCommentModel from '../../../../models/ClaimCommentModel';
// Services
import FormService from '../../../../services/FormService';
// Helpers
import locales from '../../../../helpers/locales';
import { translateDate } from '../../../../helpers/translate';
// Styles
import styles from './styles.scss';

const COMMENT_FIELD = locales.get('claim.comment.name');

interface IProps {
  className?: string;
  disabled: boolean;
  comments: ClaimCommentModel[];
  onSaveComment: (text: string) => void;
}

interface IState {
  isOpenComment: boolean;
}

@observer
export default class ClaimDetailsComments extends React.Component<IProps, IState> {
  private form: FormService;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenComment: false
    };
    this.form = new FormService()
      .setField(COMMENT_FIELD, 'required|max:100');
  }

  public openComment = () => {
    this.form.clearField(COMMENT_FIELD);
    this.setState({
      isOpenComment: true
    });
  };

  public closeComment = () => {
    this.setState({
      isOpenComment: false
    });
  };

  public updateComment = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.form.setValue(event.currentTarget.name, event.currentTarget.value);
  };

  public validateComment = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.form.validate(event.currentTarget.name);
  };

  public addComment = () => {
    if (!this.form.validateAll()) {
      return;
    }
    const { onSaveComment } = this.props;
    onSaveComment(this.form.getValue(COMMENT_FIELD));
    this.setState({
      isOpenComment: false
    });
  };

  public renderComments = () => {
    const { comments } = this.props;
    return comments
      .map((comment: ClaimCommentModel) => (
        <div key={comment.id} className={styles.feedItem}>
          <div>{comment.comment}</div>
          <div className={styles.feedItemSub}>
            {`${translateDate(comment.createdAt)} ${comment.adminUser.name}`}
          </div>
        </div>
      ));
  };

  public renderCommentLink() {
    const { disabled } = this.props;
    if (disabled) {
      return null;
    }

    return (
      <div onClick={this.openComment}>
        <span className="mutb-pencil-icon" />
        &nbsp;
        {locales.get('claim.comments.add_comment')}
      </div>
    );
  }

  public renderCommentModal() {
    const { isOpenComment } = this.state;
    if (!isOpenComment) {
      return null;
    }
    return (
      <Modal isOpen onClose={this.closeComment}>
        <div className={styles.modalComment}>
          <TextAreaField
            className={styles.field}
            name={COMMENT_FIELD}
            textAreaHeight={200}
            placeholder={locales.get('claim.comment.placeholder')}
            value={this.form.getValue(COMMENT_FIELD)}
            error={this.form.getError(COMMENT_FIELD)}
            onChange={this.updateComment}
            onBlur={this.validateComment}
          />
          <div className={styles.modalCommentButtons}>
            <LinkButton label={locales.get('claim.comment.cancel')} onClick={this.closeComment} />
            <Button
              label={locales.get('claim.comment.save')}
              type={ButtonType.Round}
              onClick={this.addComment}
              disabled={!this.form.isFormFilled()}
            />
          </div>
        </div>
      </Modal>
    );
  }

  public render() {
    return (
      <>
        <Section
          title={locales.get('claim.comments.title')}
          actionButton={this.renderCommentLink()}
        >
          {this.renderComments()}
        </Section>
        {this.renderCommentModal()}
      </>
    );
  }
}
