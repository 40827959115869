import React from 'react';
import classnames from 'classnames';
// Styles
import styles from './styles.scss';

interface IProps {
  flex: string;
  value?: string;
  placeholder?: string;
  label?: string;
  name?: string;
  onChange: (newValue: string, fieldName?: string) => void;
  disabled?: boolean;
}

export default class FilterField extends React.PureComponent<IProps> {
  static defaultProps = {
    label: undefined,
    name: undefined,
    value: undefined,
    placeholder: undefined,
    disabled: false
  };

  public onChange = (event: { target: HTMLInputElement }) => {
    const { onChange } = this.props;
    onChange(event.target.value, event.target.name);
  };

  public clearField = () => {
    const { onChange, name } = this.props;
    onChange('', name);
  };

  public renderClearButton() {
    const { value, disabled } = this.props;
    return !disabled && value &&
      <div className={styles.clear} onClick={this.clearField}>{'\u2715'}</div>;
  }

  public render() {
    const {
      flex, name, placeholder, value, label, disabled
    } = this.props;
    const style = { flex };

    return (
      <div className={classnames('filterItem', styles.wrapper)} style={style}>
        <div className={classnames(styles.field, { [styles.disabled]: disabled })}>
          <input
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={this.onChange}
            disabled={disabled}
          />
          {this.renderClearButton()}
        </div>
        {label && <div className={styles.label}>{label}</div>}
      </div>
    );
  }
}
