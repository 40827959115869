import PaymentAccountModel, { IPaymentAccountObject } from './PaymentAccountModel';
import VaultAccountObject, { IVaultAccountObject } from './VaultAccountModel';
import { translateCurrency, translateDate } from '../helpers/translate';

export enum TrustAccountStatus {
  Active = 'active',
  Probate = 'probate',
  Cancelled = 'cancelled',
  Expired = 'expired'
}

export enum ConservatorStatus {
  None = 'none',
  Applied = 'applied',
  Selected = 'selected'
}

export enum TrustAccountLinkStatus {
  Initial = 'initial',
  PendingEmail = 'pending_email',
  PendingQr = 'pending_qr',
  Linked = 'linked',
  Resigned = 'resigned',
  KickedOut = 'kicked_out',
  Discarded = 'discarded'
}

export interface ITrustAccountObject {
  id: number;
  contract_number: string;
  bank_torihiki_number: string;
  branch_name: string;
  branch_code: string;
  name_kanji: string;
  name_kana: string;
  number: string;
  status: string;
  conservator_status: string;
  is_locked: boolean;
  initial_trust_amount: number;
  total_trust_amount: number;
  currency: string;
  balance: number;
  contract_start_date: string;
  contract_end_date: string|null;
  contract_suspended_date: string|null;
  account_owner_death_date: string|null;
  automatic_payment_amount: number;
  payment_accounts: IPaymentAccountObject[];
  vault_account?: IVaultAccountObject;
  product_type: string;
}

export default class TrustAccountModel {
  public id: number;
  public contractNumber: string;
  public bankTorihikiNumber: string;
  public branchName: string;
  public branchCode: string;
  public nameKanji: string;
  public nameKana: string;
  public number: string;
  public status: TrustAccountStatus;
  public conservatorStatus: ConservatorStatus;
  public isLocked: boolean;
  public initialTrustAmount: number;
  public totalTrustAmount: number;
  public currency: string;
  public productType: string;
  public balance: number;
  public contractStartDate: Date;
  public contractEndDate: Date|null;
  public contractSuspendedDate: Date|null;
  public accountOwnerDeathDate: Date|null;
  public automaticPaymentAmount: number;
  public paymentAccounts: PaymentAccountModel[];
  public vaultAccount: VaultAccountObject|null;

  constructor(json: ITrustAccountObject) {
    this.id = json.id;
    this.contractNumber = json.contract_number;
    this.bankTorihikiNumber = json.bank_torihiki_number;
    this.branchName = json.branch_name;
    this.branchCode = json.branch_code;
    this.nameKanji = json.name_kanji;
    this.nameKana = json.name_kana;
    this.number = json.number;
    this.status = json.status as TrustAccountStatus;
    this.conservatorStatus = json.conservator_status as ConservatorStatus;
    this.isLocked = json.is_locked;
    this.initialTrustAmount = json.initial_trust_amount;
    this.totalTrustAmount = json.total_trust_amount;
    this.currency = json.currency;
    this.balance = json.balance;
    this.contractStartDate = new Date(json.contract_start_date);
    this.contractEndDate = json.contract_end_date ? new Date(json.contract_end_date) : null;
    this.contractSuspendedDate = json.contract_suspended_date ?
      new Date(json.contract_suspended_date) : null;
    this.accountOwnerDeathDate = json.account_owner_death_date ?
      new Date(json.account_owner_death_date) : null;
    this.automaticPaymentAmount = json.automatic_payment_amount;
    this.paymentAccounts = json.payment_accounts.map((account) => new PaymentAccountModel(account));
    this.vaultAccount = json.vault_account ? new VaultAccountObject(json.vault_account) : null;
    this.productType = json.product_type;
  }

  get balanceString() {
    return translateCurrency(this.balance);
  }

  get initialTrustAmountString() {
    return translateCurrency(this.initialTrustAmount);
  }

  get totalTrustAmountString() {
    return translateCurrency(this.totalTrustAmount);
  }

  get automaticPaymentAmountString() {
    return translateCurrency(this.automaticPaymentAmount);
  }

  get contractStartDateString() {
    return translateDate(this.contractStartDate);
  }

  get contractEndDateString() {
    return translateDate(this.contractEndDate);
  }

  get contractSuspendedDateString() {
    return translateDate(this.contractSuspendedDate);
  }

  get accountOwnerDeathDateString() {
    return translateDate(this.accountOwnerDeathDate);
  }
}
