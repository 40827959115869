import React from 'react';
import { observer } from 'mobx-react';

import { Column } from '../../../components/table';
import TabContent from '../../../components/TabContent';
import locales from '../../../helpers/locales';
import UserModel from '../../../models/UserModel';
import UsersTable from './UsersTable';
import Footer from '../../../components/Footer';
import Button, { ButtonSize, ButtonType } from '../../../components/Button';
import UsersService, { UserPermissions } from '../../../services/UsersService';
import DialogService, { DialogType } from '../../../services/DialogService';

import styles from './styles.scss';

interface IProps {
  usersService: UsersService;
  dialogService: DialogService;
  onPageChange: (page: number) => void;
}

interface IState {
  isAllChecked: boolean;
  isCheckedOn: {
    [id: number]: boolean;
  };
}

@observer
export default class PendingApprovals extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const checks = {};
    const { usersService } = props;
    usersService.users.forEach((user: UserModel) => {
      if (usersService.hasPermission(user, UserPermissions.ProceedWithRequest)) {
        checks[user.id] = false;
      }
    });
    this.state = {
      isAllChecked: false,
      isCheckedOn: checks
    };
  }

  private toggleAllChecks = () => {
    const { usersService } = this.props;
    const { isAllChecked } = this.state;
    const checks = {};
    usersService.users.forEach((user: UserModel) => {
      if (usersService.hasPermission(user, UserPermissions.ProceedWithRequest)) {
        checks[user.id] = !isAllChecked;
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      isAllChecked: !prevState.isAllChecked,
      isCheckedOn: checks
    }));
  };

  private toggleCheckboxOn = (id: number) => {
    this.setState((prevState) => ({
      ...prevState,
      isCheckedOn: {
        ...prevState.isCheckedOn,
        [id]: !prevState.isCheckedOn[id]
      }
    }));
  };

  private confirmApprove = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('admin_users.pending_approvals.approve_message_confirmation'),
      onConfirm: this.approve
    });
  };

  private confirmReject = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('admin_users.pending_approvals.reject_message_confirmation'),
      onConfirm: this.reject
    });
  };

  private approve = async () => {
    const { usersService, dialogService } = this.props;
    const { isCheckedOn } = this.state;
    try {
      const users = usersService.users.filter((user) => isCheckedOn[user.id]);
      await usersService.approveRequests(users);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('admin_users.pending_approvals.approve_message_success'),
        onClose: this.reload
      });
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message
      });
    }
  };

  private reject = async () => {
    const { usersService, dialogService } = this.props;
    const { isCheckedOn } = this.state;
    try {
      const users = usersService.users.filter((user) => isCheckedOn[user.id]);
      await usersService.rejectRequests(users);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('admin_users.pending_approvals.reject_message_success'),
        onClose: this.reload
      });
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message
      });
    }
  };

  private reload = () => {
    const { onPageChange } = this.props;
    onPageChange(1);
  };

  private renderBranchCode(user: UserModel) {
    if (
      !user.pendingUpdates ||
      !user.pendingUpdates.branchCode ||
      user.branchCode === user.pendingUpdates.branchCode
    ) {
      return user.branchCode;
    }
    return (
      <>
        <div>{user.branchCode}</div>
        <div className={styles.diff}>{user.pendingUpdates.branchCode}</div>
      </>
    );
  }

  private renderEmployeeNumber(user: UserModel) {
    if (!user.pendingUpdates || !user.pendingUpdates.employeeNumber) {
      return user.employeeNumber;
    }
    return <div className={styles.diff}>{user.pendingUpdates.employeeNumber}</div>;
  }

  private renderName(user: UserModel) {
    if (
      !user.pendingUpdates ||
      !user.pendingUpdates.nameKanji ||
      user.name === user.pendingUpdates.nameKanji
    ) {
      return user.name;
    }
    return (
      <>
        <div>{user.name}</div>
        <div className={styles.diff}>{user.pendingUpdates.nameKanji}</div>
      </>
    );
  }

  private renderRole(user: UserModel) {
    if (
      !user.pendingUpdates ||
      !user.pendingUpdates.role ||
      user.role === user.pendingUpdates.role
    ) {
      return user.roleString;
    }
    return (
      <>
        <div>{user.roleString}</div>
        <div className={styles.diff}>{user.pendingUpdates.roleString}</div>
      </>
    );
  }

  public render() {
    const { usersService } = this.props;
    const { isCheckedOn, isAllChecked } = this.state;
    const {
      usersService: {
        users, pendingUsers: loading, currentPage, pagesCount
      }, onPageChange
    } = this.props;
    const isAnyCheckboxSelected = users
      .filter((user) => isCheckedOn[user.id]).length > 0;
    const columns: Column[] = [{
      className: styles.checkbox,
      headerClassName: styles.headerCheckbox,
      width: 50,
      Header: (
        <input
          type="checkbox"
          checked={isAllChecked}
          onClick={this.toggleAllChecks}
        />
      ),
      id: 'checkbox',
      Cell: (row: { value: string; original: UserModel }) => {
        const canCheck =
          usersService.hasPermission(row.original, UserPermissions.ProceedWithRequest);
        if (!canCheck) {
          return null;
        }
        const { id } = row.original;
        const toggleCheckbox = () => this.toggleCheckboxOn(id);
        return (
          <input
            type="checkbox"
            checked={isCheckedOn[id]}
            onClick={toggleCheckbox}
          />
        );
      }
    }, {
      Header: locales.get('admin_users.pending_approvals.columns.type'),
      id: 'statusString',
      Cell: (row: { original: UserModel }) => row.original.statusString
    }, {
      Header: locales.get('general.admin_user.branch_code'),
      id: 'branchCode',
      Cell: (row: { original: UserModel }) => this.renderBranchCode(row.original)
    }, {
      Header: locales.get('general.admin_user.employee_number'),
      id: 'employeeNumber',
      Cell: (row: { original: UserModel }) => this.renderEmployeeNumber(row.original)
    }, {
      Header: locales.get('general.admin_user.name_kanji'),
      id: 'name',
      Cell: (row: { original: UserModel }) => this.renderName(row.original)
    }, {
      Header: locales.get('general.admin_user.role'),
      id: 'role',
      Cell: (row: { original: UserModel }) => this.renderRole(row.original)
    }];

    return (
      <>
        <TabContent>
          <UsersTable
            columns={columns}
            loading={loading}
            data={users}
            currentPage={currentPage}
            pagesCount={pagesCount}
            onPageChange={onPageChange}
          />
        </TabContent>
        <Footer>
          <Button
            label={locales.get('admin_users.pending_approvals.reject')}
            type={ButtonType.Round}
            size={ButtonSize.Normal}
            onClick={this.confirmReject}
            disabled={loading || !isAnyCheckboxSelected}
          />
          <Button
            label={locales.get('admin_users.pending_approvals.approve')}
            type={ButtonType.Round}
            size={ButtonSize.Normal}
            onClick={this.confirmApprove}
            disabled={loading || !isAnyCheckboxSelected}
          />
        </Footer>
      </>
    );
  }
}
