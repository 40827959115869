export interface IAdminUserObject {
  id: number;
  name: string;
}

export default class AdminUserModel {
  public id: number;
  public name: string;

  constructor(json: IAdminUserObject) {
    this.id = json.id;
    this.name = json.name;
  }
}
