import React from 'react';
import classnames from 'classnames';
import styles from './styles.scss';
import locales from '../../helpers/locales';
import Checkbox from '../Checkbox';

interface IProps {
  onCheckBoxCheck: (value: string, checked: boolean) => void;
  mailMagazineAnnouncement: boolean;
}

const MAILMAGAZINE_CHECKBOX_VALUE = 'mailMagazineAnnouncement';

export default class MailMagazineSelection extends React.PureComponent<IProps> {
  public render() {
    const { onCheckBoxCheck, mailMagazineAnnouncement } = this.props;

    return (
      <div className={classnames(styles.mailMagazineSelection)}>
        <Checkbox
          checked={mailMagazineAnnouncement}
          value={MAILMAGAZINE_CHECKBOX_VALUE}
          label={locales.get('notification.mailMagazineCheckBox')}
          disabled={false}
          onChange={onCheckBoxCheck}
        />
      </div>
    );
  }
}
