import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Components
import Button, { ButtonSize } from '../../components/Button';
import Text from '../../components/Text';
// Services
import SettingsService from '../../services/SettingsService';
// Helpers
import locales from '../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  settingsService: SettingsService;
}

@inject('settingsService')
@observer
export default class ProductSelection extends React.Component<IProps> {
  static get PRODUCT_SELECTION_KEY() {
    return 'product-selection';
  }

  static get PRODUCT_TSUKAETE_ANSHIN() {
    return 'tsukaete';
  }

  static get PRODUCT_OHITORISAMA() {
    return 'ohitori';
  }

  public componentDidMount = () => {
    const { settingsService } = this.props;
    settingsService.setProductSelectionInProgress(true);
  };

  public componentWillUnmount = () => {
    const { settingsService } = this.props;
    settingsService.setProductSelectionInProgress(false);
  };

  public setProductSelectionAndRedirectToRoot = (value: string|number|undefined): void => {
    // The onClick handler of the button supports numerical values, so our function signature does as well,
    // but the semantics of product selection do not. Receiving a number here would indicate a logic error.
    if (!((typeof value === 'string') || (typeof value === 'undefined'))) {
      throw new Error('value for product selection must be string or undefined');
    }
    const { history, settingsService } = this.props;
    settingsService.setProductSelection(value);
    history.push('/');
  };

  public render() {
    return (
      <div className={styles.productSelection}>
        <div className={styles.container}>
          <div className={styles.instructionsContainer}>
            <Text>
              {locales.get('productSelection.instructions')}
            </Text>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.buttonTsukaeteAnshin}
              size={ButtonSize.Big}
              value={ProductSelection.PRODUCT_TSUKAETE_ANSHIN}
              label={locales.get('productSelection.labelTsukaeteAnshin')}
              disabled={false}
              onClick={this.setProductSelectionAndRedirectToRoot}
            />
            <Button
              className={styles.buttonOhitorisama}
              size={ButtonSize.Big}
              value={ProductSelection.PRODUCT_OHITORISAMA}
              label={locales.get('productSelection.labelOhitorisama')}
              disabled={false}
              onClick={this.setProductSelectionAndRedirectToRoot}
            />
          </div>
        </div>
      </div>
    );
  }
}
