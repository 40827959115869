// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TabContent_content_3qGHa {\n  flex: 1;\n  display: flex;\n  flex-direction: column; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,aAAa;EACb,sBAAsB,EAAA","file":"styles.scss","sourcesContent":[".content {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "TabContent_content_3qGHa"
};
module.exports = exports;
