import { action, observable, runInAction } from 'mobx';
import { saveAs } from 'file-saver';
import moment from 'moment';

import IBatchDao from './IBatchDao';
import SettingsService from '../SettingsService';

export default class BatchService {
  public dao: IBatchDao;
  public settings: SettingsService;
  @observable public pendingBatch = false;

  constructor(dao: IBatchDao, settings: SettingsService) {
    this.dao = dao;
    this.settings = settings;
  }

  private get timestamp() {
    return moment().format('YYYYMMDDHHmmss');
  }

  private get productFileName() {
    return this.settings.isProductSelectionOhitori ? '_ohitori' : '';
  }

  // API_CLAIM99
  @action public async downloadPayments() {
    try {
      this.pendingBatch = true;
      const fileName = `payments${this.productFileName}_${this.timestamp}.csv`;
      // TODO the spec is still unclear
      const blob = await this.dao.downloadPayments();
      saveAs(blob, fileName);
    } finally {
      runInAction(() => {
        this.pendingBatch = false;
      });
    }
  }

  // API_BATCH01
  @action public async downloadKPIs() {
    try {
      this.pendingBatch = true;
      const fileName = `status${this.productFileName}_${this.timestamp}.csv`;
      const blob = await this.dao.downloadKPIs();
      saveAs(blob, fileName);
    } finally {
      runInAction(() => {
        this.pendingBatch = false;
      });
    }
  }

  // API_BATCH02
  @action public async downloadClaims() {
    try {
      this.pendingBatch = true;
      const fileName = `claims${this.productFileName}_${this.timestamp}.csv`;
      const blob = await this.dao.downloadClaims();
      saveAs(blob, fileName);
    } finally {
      runInAction(() => {
        this.pendingBatch = false;
      });
    }
  }

  // API_BATCH03
  @action public async downloadCustomers() {
    try {
      this.pendingBatch = true;
      const fileName = `customers${this.productFileName}_${this.timestamp}.csv`;
      const blob = await this.dao.downloadCustomers();
      saveAs(blob, fileName);
    } finally {
      runInAction(() => {
        this.pendingBatch = false;
      });
    }
  }

  // API_BATCH04
  @action public async downloadBatchJobResults() {
    try {
      this.pendingBatch = true;
      const fileName = `results${this.productFileName}_${this.timestamp}.csv`;
      const blob = await this.dao.downloadBatchJobResults();
      saveAs(blob, fileName);
    } finally {
      runInAction(() => {
        this.pendingBatch = false;
      });
    }
  }
}
