// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".OptionList_optionList_viBpv {\n  display: flex;\n  margin-bottom: 10px;\n  max-width: 400px;\n  flex-flow: row wrap; }\n\n.OptionList_option_2WYDl {\n  flex-basis: 50%;\n  padding: 2px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB,EAAA;;AAGrB;EACE,eAAe;EACf,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.optionList {\n  display: flex;\n  margin-bottom: 10px;\n  max-width: 400px;\n  flex-flow: row wrap;\n}\n\n.option {\n  flex-basis: 50%;\n  padding: 2px;\n}\n"]}]);
// Exports
exports.locals = {
	"optionList": "OptionList_optionList_viBpv",
	"option": "OptionList_option_2WYDl"
};
module.exports = exports;
