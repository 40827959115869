// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DescriptionList_small_2Eiag .row {\n  display: table-row; }\n  .DescriptionList_small_2Eiag .row .cell {\n    display: table-cell;\n    padding: 4px 0; }\n    .DescriptionList_small_2Eiag .row .cell:first-child {\n      text-align: right;\n      padding-right: 16px;\n      font-size: 14px;\n      color: #8E8E93;\n      width: 150px;\n      min-width: 90px; }\n    .DescriptionList_small_2Eiag .row .cell:nth-child(2) {\n      font-size: 16px;\n      text-align: left;\n      color: #3D3D3D;\n      word-break: break-all; }\n", "",{"version":3,"sources":["stylesSmall.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB,EAAA;EAFtB;IAKM,mBAAmB;IACnB,cAAc,EAAA;IANpB;MASQ,iBAAiB;MACjB,mBAAmB;MACnB,eAAe;MACf,cAAc;MACd,YAAY;MACZ,eAAe,EAAA;IAdvB;MAkBQ,eAAe;MACf,gBAAgB;MAChB,cAAc;MACd,qBAAqB,EAAA","file":"stylesSmall.scss","sourcesContent":[".small {\n  :global(.row) {\n    display: table-row;\n\n    :global(.cell) {\n      display: table-cell;\n      padding: 4px 0;\n\n      &:first-child {\n        text-align: right;\n        padding-right: 16px;\n        font-size: 14px;\n        color: #8E8E93;\n        width: 150px;\n        min-width: 90px;\n      }\n\n      &:nth-child(2) {\n        font-size: 16px;\n        text-align: left;\n        color: #3D3D3D;\n        word-break: break-all;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"small": "DescriptionList_small_2Eiag"
};
module.exports = exports;
