import React from 'react';
import classnames from 'classnames';
// Components
import Icon from '../Icon';
// Styles
import styles from './styles.scss';

export enum ButtonSize {
  Small = 'small',
  Normal = 'normal',
  Big = 'big'
}

export enum ButtonType {
  Round = 'round',
  Square = 'square'
}

interface IProps<T> {
  className?: string;
  value?: T;
  label: string;
  type: ButtonType;
  size: ButtonSize;
  disabled?: boolean;
  isSSO?: boolean;
  iconName?: string;
  onClick: (value: T|undefined) => void;
}

export function button<T>() {
  class Button extends React.PureComponent<IProps<T>> {
    static defaultProps = {
      className: '',
      disabled: false,
      type: ButtonType.Round,
      size: ButtonSize.Normal
    };

    public handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      const { onClick, value, disabled } = this.props;

      if (!disabled) {
        onClick(value);
      }

      event.stopPropagation();
    };

    public render() {
      const {
        className, size, type, label, iconName, disabled, isSSO
      } = this.props;
      const classNames = classnames(
        className, isSSO ? styles.buttonSSO : styles.button, styles[size], styles[type], { [styles.disabled]: disabled }
      );
      return (
        <button
          type="button"
          className={classNames}
          onClick={this.handleClick}
        >
          {iconName ? <Icon className={styles.icon} name={iconName} /> : null}
          {label && <div>{label}</div>}
        </button>
      );
    }
  }

  return Button;
}

export default button<string|number>();
