// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CustomersListView_filter_168le {\n  justify-content: flex-start; }\n\n.CustomersListView_search_1Q9Me,\n.CustomersListView_clear_2KVsc {\n  flex: 1; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAA;;AAG7B;;EAEE,OAAO,EAAA","file":"styles.scss","sourcesContent":[".filter {\n  justify-content: flex-start;\n}\n\n.search,\n.clear {\n  flex: 1;\n}\n"]}]);
// Exports
exports.locals = {
	"filter": "CustomersListView_filter_168le",
	"search": "CustomersListView_search_1Q9Me",
	"clear": "CustomersListView_clear_2KVsc"
};
module.exports = exports;
