import moment from 'moment';
import qs from 'qs';
// Interfaces
import IClaimsDao, { IClaimsSearchParams, ILoadClaimsResponse } from './ClaimsDao';
// Services
import ApiService from '../ApiService';
import { ClaimPermissions } from './permissions';
// Models
import ClaimModel, { IClaimObject } from '../../models/ClaimModel';
import ClaimCommentModel, { IClaimCommentObject } from '../../models/ClaimCommentModel';
import ClaimEventModel, { IClaimEventObject } from '../../models/ClaimEventModel';
import ClaimReceiptModel, { IClaimReceiptObject } from '../../models/ClaimReceiptModel';
import Pagination from '../Pagination';

const CLAIMS_ENDPOINT = '/admin/claims';

interface IClaimResponse {
  claim: IClaimObject;
}

interface IClaimsResponse extends Pagination {
  claims: IClaimObject[];
}

interface IClaimCommentsResponse {
  claim_comments: IClaimCommentObject[];
}

interface IClaimEventsResponse {
  events: IClaimEventObject[];
}

export default class ClaimsDaoImpl implements IClaimsDao {
  public api: ApiService;

  constructor(api: ApiService) {
    this.api = api;
  }

  public async loadClaims(params: IClaimsSearchParams): Promise<ILoadClaimsResponse> {
    const convertedParams = {};
    Object.keys(params).forEach((key) => {
      if (params[key] instanceof Date) {
        const date = params[key];
        convertedParams[key] = moment(date).format('YYYY-M-D');
      } else {
        convertedParams[key] = params[key];
      }
    });
    const query = qs.stringify(convertedParams);
    const res = await this.api.get<IClaimsResponse>(`${CLAIMS_ENDPOINT}/search?${query}`);
    return {
      pagination: res.data.pagination,
      claims: res.data.claims.map((claimJson) => new ClaimModel(claimJson))
    };
  }

  public async loadClaimById(id: number): Promise<ClaimModel> {
    const res = await this.api.get<IClaimResponse>(`${CLAIMS_ENDPOINT}/${id}`);

    return new ClaimModel(res.data.claim);
  }

  public async loadClaimCommentsById(id: number): Promise<ClaimCommentModel[]> {
    const res = await this.api.get<IClaimCommentsResponse>(`${CLAIMS_ENDPOINT}/${id}/comments`);
    return res.data.claim_comments.map((json) => new ClaimCommentModel(json));
  }

  public async loadClaimEventsById(id: number): Promise<ClaimEventModel[]> {
    const res = await this.api.get<IClaimEventsResponse>(`${CLAIMS_ENDPOINT}/${id}/events`);
    return res.data.events.map((eventJson) => new ClaimEventModel(eventJson));
  }

  public async loadClaimReceiptById(id: number): Promise<ClaimReceiptModel> {
    const res = await this.api.get<IClaimReceiptObject>(`${CLAIMS_ENDPOINT}/${id}/receipt`);
    return new ClaimReceiptModel(res.data);
  }

  public async toggleClaimLock(id: number, locked: boolean): Promise<void> {
    await this.api.put(`${CLAIMS_ENDPOINT}/${id}/lock`, { locked });
  }

  public async reviewClaim(id: number, action: ClaimPermissions): Promise<void> {
    await this.api.post(`${CLAIMS_ENDPOINT}/${id}/reviews`, { review_action: action });
  }

  public async saveReturnedComment(id: number, message: string): Promise<void> {
    await this.api.put(`${CLAIMS_ENDPOINT}/${id}/returned_comment`, { message });
  }

  public async addComment(id: number, message: string): Promise<void> {
    await this.api.post(`${CLAIMS_ENDPOINT}/${id}/comments`, { message });
  }
}
