import React from 'react';
import { observer } from 'mobx-react';
// Services
import AccountService from '../../services/AccountService';
import StorageService from '../../services/StorageService';
// Models
import { UserRole } from '../../models/UserModel';
import { AccountState } from '../../models/AccountModel';
// Styles
import styles from './styles.scss';

export const STORE_ROLE_KEY = 'devtools_user_role';

const DEV_ACCOUNTS = {
  [UserRole.Worker]: {
    username: '1111111',
    password: 'asdASD123'
  },
  [UserRole.Checker]: {
    username: '2222222',
    password: 'asdASD123'
  },
  [UserRole.Administrator]: {
    username: '3333333',
    password: 'asdASD123'
  },
  [UserRole.Support]: {
    username: '4444444',
    password: 'asdASD123'
  },
  [UserRole.Corporate]: {
    username: '5555555',
    password: 'asdASD123'
  },
  [UserRole.Department]: {
    username: '6666666',
    password: 'asdASD123'
  },
  [UserRole.SystemAdministrator]: {
    username: '7777777',
    password: 'asdASD123'
  }
};

interface IProps {
  accountService: AccountService;
  storageService: StorageService;
}

@observer
export default class DevTools extends React.Component<IProps> {
  public currentRole: UserRole = UserRole.Worker;

  public componentDidMount() {
    const { storageService } = this.props;
    this.currentRole = storageService.getItem(STORE_ROLE_KEY, UserRole.Worker) as UserRole;
  }

  public changeAccountRole = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { accountService, storageService } = this.props;
    const newRole = event.target.value;

    storageService.setItem(STORE_ROLE_KEY, newRole);
    await accountService.logout();
    this.currentRole = newRole as UserRole;
  };

  public async componentWillReact() {
    const { accountService, storageService } = this.props;

    if (accountService.accountState === AccountState.Pending) {
      return;
    }

    const { account } = accountService;
    if (account && account.role !== this.currentRole) {
      await accountService.logout();
      return;
    }

    if (!account) {
      storageService.setItem(STORE_ROLE_KEY, this.currentRole);
      const cred = DEV_ACCOUNTS[this.currentRole];
      await accountService.login(cred.username, cred.password);
    }
  }

  public renderOptions() {
    const options: React.ReactNode[] = [];
    Object.keys(DEV_ACCOUNTS).forEach((role) => {
      const title = role.split('_').join(' ');
      options.push(<option key={role} value={role}>{title}</option>);
    });

    return options;
  }

  public renderRolesList() {
    const { storageService } = this.props;
    const currentRole = storageService.getItem(STORE_ROLE_KEY);

    return (
      <select value={currentRole || ''} onChange={this.changeAccountRole}>
        {this.renderOptions()}
      </select>
    );
  }

  public render() {
    const { accountService } = this.props;
    return (
      <div className={styles.tools}>
        {accountService.accountState !== AccountState.Pending ? this.renderRolesList() : null}
      </div>
    );
  }
}
