import locales from '../helpers/locales';
import { translateDate } from '../helpers/translate';
import Model from './Model';
import UserPendingUpdatesModel, { IUserPendingUpdatesObject } from './UserPendingUpdatesModel';

export enum UserRole {
  Worker = 'center_worker', // Role 1 in the documentation: "事務センター担当者"
  Checker = 'center_checker', // Role 2 in the documentation: "事務センター再鑑者"
  Administrator = 'center_administrator', // Role 3 in the documentation: "事務センター管理者"
  Support = 'support', // Role 4 in the documentation: "事務センター受電" or "営業店"
  Corporate = 'corporate', // Role 5 in the documentation: "本部"
  Department = 'department', // Role 6 in the documentation: "主管部"
  SystemAdministrator = 'system_administrator' // Role 7 in the documentation: "ID管理者"
}

export enum UserStatus {
  Created = 'created',
  Active = 'active',
  Pending = 'pending',
  PendingDelete = 'pending_delete',
  Deleted = 'deleted'
}

export interface IUserObject {
  id: number;
  branch_code: string;
  employee_number: string;
  name_kanji: string;
  role: string;
  admin_user_status: string;
  revoked_at: string|null;
  created_at: string;
  updated_at: string|null;
  pending_updates: IUserPendingUpdatesObject|null;
}

export default class UserModel implements Model {
  public id: number;
  public branchCode: string;
  public employeeNumber: string;
  public name: string;
  public role: UserRole;
  public adminUserStatus: UserStatus;
  public revokedAt: Date|null;
  public createdAt: Date;
  public updatedAt: Date|null;
  public pendingUpdates: UserPendingUpdatesModel|null;

  get hasRequestedChange() {
    return this.adminUserStatus !== UserStatus.Active &&
      this.adminUserStatus !== UserStatus.Deleted;
  }

  get roleString() {
    return this.role ? locales.get(`general.admin_user_roles.${this.role}`) : '';
  }

  get createdAtDateString() {
    return translateDate(this.createdAt);
  }

  get revokedAtDateString() {
    return translateDate(this.revokedAt);
  }

  get statusString() {
    // TODO: Unclear spec on "lock"
    return locales.get(`general.admin_user_statuses.${this.adminUserStatus}`);
  }

  constructor(json: IUserObject) {
    this.id = json.id;
    this.branchCode = json.branch_code;
    this.employeeNumber = json.employee_number;
    this.name = json.name_kanji;
    this.role = json.role as UserRole;
    this.adminUserStatus = json.admin_user_status as UserStatus;
    this.revokedAt = json.revoked_at ? new Date(json.revoked_at) : null;
    this.createdAt = new Date(json.created_at);
    this.updatedAt = json.updated_at ? new Date(json.updated_at) : null;
    this.pendingUpdates =
      json.pending_updates ? new UserPendingUpdatesModel(json.pending_updates) : null;
  }
}
