import React from 'react';
import {
  HashRouter as Router,
  RouteComponentProps,
  withRouter,
  Route,
  Switch
} from 'react-router-dom';
import UsersListView from './UsersListView';
import { NewUserView, EditUserView } from './UserView';

type IProps = RouteComponentProps<{}>;

class UsersManager extends React.PureComponent<IProps> {
  public render() {
    return (
      <Router>
        <Switch>
          <Route path="/users/pending" component={UsersListView} />
          <Route path="/users/new" component={NewUserView} />
          <Route path="/users/:userId" component={EditUserView} />
          <Route path="/users" component={UsersListView} />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(UsersManager);
