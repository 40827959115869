// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DescriptionList_big_1_evV .row {\n  display: table-row; }\n  .DescriptionList_big_1_evV .row .cell {\n    display: table-cell;\n    padding: 14px 0; }\n    .DescriptionList_big_1_evV .row .cell:first-child {\n      font-family: HiraginoSans-W6, sans-serif;\n      font-size: 16px;\n      text-align: right;\n      padding-right: 30px; }\n    .DescriptionList_big_1_evV .row .cell:nth-child(2) {\n      font-family: HelveticaNeue, sans-serif;\n      font-size: 18px;\n      letter-spacing: 0.4px;\n      border-bottom: 1px solid #E1E1E1; }\n", "",{"version":3,"sources":["stylesBig.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB,EAAA;EAFtB;IAKM,mBAAmB;IACnB,eAAe,EAAA;IANrB;MASQ,wCAAwC;MACxC,eAAe;MACf,iBAAiB;MACjB,mBAAmB,EAAA;IAZ3B;MAgBQ,sCAAsC;MACtC,eAAe;MACf,qBAAqB;MACrB,gCAAgC,EAAA","file":"stylesBig.scss","sourcesContent":[".big {\n  :global(.row) {\n    display: table-row;\n\n    :global(.cell) {\n      display: table-cell;\n      padding: 14px 0;\n\n      &:first-child {\n        font-family: HiraginoSans-W6, sans-serif;\n        font-size: 16px;\n        text-align: right;\n        padding-right: 30px;\n      }\n\n      &:nth-child(2) {\n        font-family: HelveticaNeue, sans-serif;\n        font-size: 18px;\n        letter-spacing: 0.4px;\n        border-bottom: 1px solid #E1E1E1;\n      }\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"big": "DescriptionList_big_1_evV"
};
module.exports = exports;
