import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import qs from 'qs';
// Containers/Components
import { Column } from '../../../../components/table';
import TransactionsTable from '../../TransactionsTable';
import NewTabLink from './NewTabLink';
// Models
import TransactionModel from '../../../../models/TransactionModel';
// Locales
import locales from '../../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  loading: boolean;
  transactions: TransactionModel[];
  currentPage: number;
  pagesCount: number;
  onPageChange: (page: number) => void;
}

@observer
class TransactionsList extends React.Component<IProps> {
  public render() {
    const {
      transactions, loading, currentPage, pagesCount, onPageChange
    } = this.props;
    const columns: Column[] = [{
      Header: locales.get('account.transactions_list.columns.claim'),
      id: 'claim',
      Cell: (row: { original: TransactionModel }) => (
        row.original.claimId && (
          <NewTabLink
            label={locales.get('account.transactions_list.see_details')}
            to={`#/claims/${row.original.claimId}?${qs.stringify({ readonly: true })}`}
          />
        )
      )
    }, {
      Header: locales.get('account.transactions_list.columns.paid_at'),
      id: 'dateString',
      Cell: (row: { original: TransactionModel }) => row.original.dateString
    }, {
      Header: locales.get('account.transactions_list.columns.category_name'),
      id: 'categoryName',
      Cell: (row: { original: TransactionModel }) => row.original.categoryName
    }, {
      Header: locales.get('account.transactions_list.columns.amount'),
      id: 'amountString',
      Cell: (row: { original: TransactionModel }) => (
        row.original.amount < 0 ?
          <span className={styles.minus}>{row.original.amountString}</span> :
          row.original.amountString
      )
    }, {
      Header: locales.get('account.transactions_list.columns.memo'),
      id: 'memo',
      Cell: (row: { original: TransactionModel }) => row.original.memo
    }];

    return (
      <TransactionsTable
        columns={columns}
        loading={loading}
        data={transactions}
        currentPage={currentPage}
        pagesCount={pagesCount}
        onPageChange={onPageChange}
      />
    );
  }
}

export default withRouter(TransactionsList);
