// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TextAreaField_field_2_nDg .TextAreaField_container_297KR {\n  position: relative; }\n  .TextAreaField_field_2_nDg .TextAreaField_container_297KR .TextAreaField_text_1VBHb {\n    width: 100%;\n    padding: 10px;\n    outline: none;\n    border: 1px solid #E1E1E1;\n    border-radius: 5px;\n    font-size: 16px; }\n\n.TextAreaField_field_2_nDg .TextAreaField_message_1E6sf {\n  display: flex;\n  align-items: center;\n  color: #D80C18;\n  font-size: 14px; }\n", "",{"version":3,"sources":["styles.scss","../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EAEI,kBAAkB,EAAA;EAFtB;IAKM,WAAW;IACX,aAAa;IACb,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,eAAe,EAAA;;AAVrB;EAeI,aAAa;EACb,mBAAmB;EACnB,cCJyB;EDKzB,eAAe,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.field {\n  .container {\n    position: relative;\n\n    .text {\n      width: 100%;\n      padding: 10px;\n      outline: none;\n      border: 1px solid #E1E1E1;\n      border-radius: 5px;\n      font-size: 16px;\n    }\n  }\n\n  .message {\n    display: flex;\n    align-items: center;\n    color: $error-massage-color;\n    font-size: 14px;\n  }\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"field": "TextAreaField_field_2_nDg",
	"container": "TextAreaField_container_297KR",
	"text": "TextAreaField_text_1VBHb",
	"message": "TextAreaField_message_1E6sf"
};
module.exports = exports;
