import UserModel, { UserRole, UserStatus } from '../../models/UserModel';
import Pagination from '../Pagination';

export enum UsersSearchUserStatusGroup {
  ActiveAll = 'active_all',
  PendingAll = 'pending_all'
}

export interface IUserCreationRequest {
  branch_code: string;
  employee_number: string;
  name_kanji: string;
  role: UserRole;
  temporary_password: string;
}

export interface IUserUpdateRequest {
  branch_code: string;
  name_kanji: string;
  role: UserRole;
}

export interface IUsersSearchParams {
  page: number;
  branch_code?: string;
  role?: string;
  name_kanji?: string;
  employee_number?: string;
  admin_user_status?: UserStatus;
  admin_user_status_group?: UsersSearchUserStatusGroup;
}

export interface ILoadUsersResponse extends Pagination {
  users: UserModel[];
}

export interface IUserExportObject {
  id: number;
  branch_code: string;
  employee_number: string;
  name_kanji: string;
  role: string;
  admin_user_status: string;
  revoked_at: string|null;
  created_at: string;
  updated_at: string|null;
}

export default interface IUsersDao {
  loadUsers(params: IUsersSearchParams): Promise<ILoadUsersResponse>;
  requestUserCreation(request: IUserCreationRequest): Promise<void>;
  requestUserUpdate(id: number, request: IUserUpdateRequest): Promise<void>;
  requestUserDeletion(id: number): Promise<void>;
  rejectRequests(ids: number[]): Promise<void>;
  approveRequests(ids: number[]): Promise<void>;
  downloadUsers(): Promise<Blob>;
}
