export interface IAnnouncementAuthorObject {
  branch_code: string;
  employee_number: string;
  name_kanji: string;
}

export default class AnnouncementAuthorModel {
  public branchCode: string;
  public employeeNumber: string;
  public nameKanji: string;

  constructor(json: IAnnouncementAuthorObject) {
    this.branchCode = json.branch_code;
    this.employeeNumber = json.employee_number;
    this.nameKanji = json.name_kanji;
  }
}
