import React from 'react';
// Components
import Section from '../../../../components/Section';
// Models
import { EventSourceType } from '../../../../models/EventSourceModel';
import ClaimEventModel from '../../../../models/ClaimEventModel';
// Helpers
import locales from '../../../../helpers/locales';
import { translateDate } from '../../../../helpers/translate';
// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  events: ClaimEventModel[];
}

export default class ClaimDetailsEvents extends React.Component<IProps> {
  public renderEventItems = () => {
    const { events } = this.props;
    return events
      .map((event: ClaimEventModel) => {
        const { source } = event;
        return (
          <div key={`${event.at.getTime()}-${event.name}`} className={styles.eventItem}>
            <div>
              <span className={styles.eventSource}>
                {locales.get(`general.claim_event_sources.${source.type}`)}
              </span>
              &nbsp;
              {source.type !== EventSourceType.BankSystem ? source.displayName : null}
              &nbsp;
              {locales.get(`general.claim_events.${event.name}`)}
            </div>
            <div className={styles.eventItemSub}>{translateDate(event.at)}</div>
          </div>
        );
      });
  };

  public render() {
    return (
      <Section title={locales.get('claim.updates')}>
        {this.renderEventItems()}
      </Section>
    );
  }
}
