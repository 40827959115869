import qs from 'qs';
// Interfaces
import Pagination from '../Pagination';
import InvitationsDao, { ILoadInvitationsResponse } from './InvitationsDao';
// Services
import ApiService from '../ApiService';
// Models
import InvitationModel, { IInvitationObject, IInvitationsSearchParams } from '../../models/InvitationModel';

interface IInvitationsResponse extends Pagination {
  invitations: IInvitationObject[];
}

const INVITATIONS_ENDPOINT = '/admin/invitations';

interface IInvitationsParamsObject {
  page: number;
  trust_account_id?: number;
  trust_account_number?: string;
  invitee_name_kanji?: string;
  email_invitation_status: 'initial';
}

export default class InvitationsDaoImpl implements InvitationsDao {
  public api: ApiService;

  constructor(api: ApiService) {
    this.api = api;
  }

  public async loadInvitations(params: IInvitationsSearchParams): Promise<ILoadInvitationsResponse> {
    const query = this.buildUrlQuery(params);
    const response = await this.api.get<IInvitationsResponse>(`${INVITATIONS_ENDPOINT}?${query}`);
    const invitations = response.data.invitations
      .map((invitation) => new InvitationModel(invitation));
    return {
      invitations,
      pagination: response.data.pagination
    };
  }

  private buildUrlQuery(params: IInvitationsSearchParams): string {
    const urlParams: IInvitationsParamsObject = {
      page: params.page,
      email_invitation_status: 'initial'
    };

    if (params.trustAccountId) {
      urlParams.trust_account_id = params.trustAccountId;
    }

    if (params.trustAccountNumber) {
      urlParams.trust_account_number = params.trustAccountNumber;
    }

    if (params.inviteeNameKanji) {
      urlParams.invitee_name_kanji = params.inviteeNameKanji;
    }

    return qs.stringify(urlParams);
  }
}
