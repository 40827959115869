import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import qs from 'qs';
import debug from 'debug';
// Containers/Components
import { Column } from '../../../../components/table';
import ClaimsTable from '../../ClaimsTable';
// Models
import ClaimModel from '../../../../models/ClaimModel';
// Locales
import locales from '../../../../helpers/locales';
// Styles
import styles from './styles.scss';

const logger = debug('MUTB:TrustAccount\'s ClaimsList');
interface IProps extends RouteComponentProps<{}> {
  loading: boolean;
  claims: ClaimModel[];
  currentPage: number;
  pagesCount: number;
  onPageChange: (page: number) => void;
}

@observer
class ClaimsList extends React.Component<IProps> {
  public openClaimDetailsPage = (claim: ClaimModel) => {
    window.open(`#/claims/${claim.id}?${qs.stringify({ readonly: true })}`);
  };

  public getRowStyle = () => ({ cursor: 'pointer' });

  public render() {
    const {
      claims, loading, currentPage, pagesCount, onPageChange
    } = this.props;
    logger('Claims', claims);
    const columns: Column[] = [{
      Header: locales.get('account.claims_list.columns.status'),
      id: 'status',
      Cell: (row: { original: ClaimModel }) => row.original.statusString
    }, {
      Header: locales.get('general.claim.submitted_at'),
      id: 'submittedAtString',
      Cell: (row: { original: ClaimModel }) => row.original.submittedAtString
    }, {
      Header: locales.get('general.claim.paid_date'),
      id: 'paidDateString',
      Cell: (row: { original: ClaimModel }) => row.original.paidDateString
    }, {
      Header: locales.get('general.claim.cancelled_at'),
      id: 'cancelledAtString',
      Cell: (row: { original: ClaimModel }) => row.original.cancelledAtString
    }, {
      Header: locales.get('account.claims_list.columns.customer'),
      id: 'customer',
      Cell: (row: { original: ClaimModel }) => (
        <>
          <div>{row.original.customer.nameKanji}</div>
          <div>{row.original.customer.nameKana}</div>
          <div>{locales.get(`general.customer_roles.${row.original.customer.role}`)}</div>
        </>
      )
    }, {
      Header: locales.get('account.claims_list.columns.date'),
      id: 'dateString',
      Cell: (row: { original: ClaimModel }) => row.original.dateString
    }, {
      Header: locales.get('general.claim.amount'),
      id: 'amountString',
      Cell: (row: { original: ClaimModel }) => (
        row.original.amount < 0 ?
          <span className={styles.minus}>{row.original.amountString}</span> :
          row.original.amountString
      )
    }, {
      Header: locales.get('account.claims_list.columns.payment_account_customer_role'),
      id: 'role',
      Cell: (row: { original: ClaimModel }) => {
        const role = row.original.paymentAccount.customerRole;
        return locales.get(`general.customer_roles.${role}`);
      }
    }];

    return (
      <ClaimsTable
        getRowStyle={this.getRowStyle}
        columns={columns}
        loading={loading}
        data={claims}
        onRowDoubleClick={this.openClaimDetailsPage}
        currentPage={currentPage}
        pagesCount={pagesCount}
        onPageChange={onPageChange}
      />
    );
  }
}

export default withRouter(ClaimsList);
