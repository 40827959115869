// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TargetCustomerOptions_targetCustomerOptions_1PRKI {\n  border-radius: 0;\n  margin-bottom: 10px;\n  width: 400px; }\n\n.TargetCustomerOptions_selectionErrorMessage_xEaNK {\n  height: 40px;\n  color: #D80C18;\n  font-size: 14px; }\n", "",{"version":3,"sources":["styles.scss","../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY,EAAA;;AAGd;EACE,YAAY;EACZ,cCK2B;EDJ3B,eAAe,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.targetCustomerOptions {\n  border-radius: 0;\n  margin-bottom: 10px;\n  width: 400px;\n}\n\n.selectionErrorMessage {\n  height: 40px;\n  color: $error-massage-color;\n  font-size: 14px;\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"targetCustomerOptions": "TargetCustomerOptions_targetCustomerOptions_1PRKI",
	"selectionErrorMessage": "TargetCustomerOptions_selectionErrorMessage_xEaNK"
};
module.exports = exports;
