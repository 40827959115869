import { ObservableMap, observable, action } from 'mobx';
import Validator from 'validatorjs';

import locales from '../../helpers/locales';

const VALIDATION_RULES = {
  zenkaku: /^[①-⑦一-龯０-９Ａ-ｚァ-ンぁ-ん！：ー—／、。’？＊＝（）＆％＄＃｜＠「」【】]+$/,
  katakana: /^[ァ-ンｧ-ﾝﾞﾟー\\-]+$/,
  hiragana: /^[ぁ-ん・ー]+$/,
  alpha_hiragana: /^[a-zA-Zぁ-ん・ー]+$/,
  hankaku: /^((?![①-⑦一-龯０-９Ａ-ｚァ-ンぁ-ん！：ー—／、。’？＊＝（）＆％＄＃｜＠「」【】]).)*$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+_@tr.mufg.jp/,
  alphanumeric: /^[a-zA-Z0-9]+$/
};

const VALIDATION_RULES_MATCH = {
  zenkaku: 'zenkaku',
  katakana: 'katakana',
  hiragana: 'hiragana',
  hankaku: 'hankaku',
  password: 'password',
  email: 'email',
  alphanumeric: 'alphanumeric'
};

interface IMessages {
  [rule: string]: string;
}

class FormService {
  static messages: IMessages = {
    required: locales.get('error_messages.required'),
    password: locales.get('error_messages.invalid'),
    digits: locales.get('error_messages.invalid'),
    email: locales.get('error_messages.invalid'),
    max: locales.get('error_messages.max'),
    min: locales.get('error_messages.invalid'),
    alphanumeric: locales.get('error_messages.alphanumeric')
  };

  static registerCustomRules() {
    const ruleTypes = Object.keys(VALIDATION_RULES_MATCH);

    ruleTypes.forEach((type) => {
      Validator.register(
        type,
        (value: string | boolean | number) => Boolean(value.toString().match(VALIDATION_RULES[type]))
      );
    });
  }

  @observable public fields = new ObservableMap<string>();
  @observable public verified = new ObservableMap<string, boolean>();
  @observable public rules = new ObservableMap<string>();
  @observable public errors = new ObservableMap<string>();
  public messages: IMessages = {};
  public validateInline: boolean;

  constructor(validateInline = false) {
    this.initForm();
    this.validateInline = validateInline;
  }

  @action public initForm(messages: IMessages = {}) {
    this.fields.replace({});
    this.rules.replace({});
    this.errors.replace({});
    this.verified.replace({});
    this.messages = messages;
    this.validateInline = false;
    return this;
  }

  @action public setField(name: string, rules = '', defaultValue = ''): FormService {
    this.fields.set(name, defaultValue);
    this.verified.set(name, false);
    if (rules) {
      this.rules.set(name, rules);
    } else {
      this.rules.delete(name);
    }
    return this;
  }

  @action public clearField(name: string) {
    this.fields.set(name, '');
    this.verified.set(name, false);
    this.errors.delete(name);
  }

  @action public reset() {
    Array.from(this.fields.keys()).forEach((fieldName) => this.clearField(fieldName));
  }

  @action public setValue(fieldName: string, fieldValue: string) {
    if (this.validateInline && this.rules.has(fieldName)) {
      const validator = new Validator(
        {
          [fieldName]: fieldValue
        },
        {
          [fieldName]: this.rules.get(fieldName)
        }
      );

      if (validator.fails()) {
        return this;
      }
    }
    this.fields.set(fieldName, fieldValue);
    return this;
  }

  @action public validate(...fieldNames: string[]): boolean {
    const values = {};
    const rules = {};
    fieldNames.forEach((fieldName) => {
      values[fieldName] = this.fields.get(fieldName);
      rules[fieldName] = this.rules.get(fieldName);
    });

    const validator = new Validator(
      values,
      rules,
      {
        ...FormService.messages,
        ...this.messages
      }
    );

    if (validator.fails()) {
      fieldNames
        .forEach((fieldName: string) => {
          const error = validator.errors.first(fieldName) || '';
          this.errors.set(fieldName, error);
        });
      return false;
    }
    fieldNames
      .forEach((fieldName: string) => {
        this.verified.set(fieldName, true);
        this.errors.set(fieldName, '');
      });
    return true;
  }

  @action public validateAll(): boolean {
    return this.validate(...Object.keys(this.fields.toJSON()));
  }

  public getValue(fieldName: string) {
    return this.fields.get(fieldName) || '';
  }

  public getError(fieldName: string) {
    return this.errors.get(fieldName) || '';
  }

  public isFormVerified() {
    return Array.from(this.fields.keys()).every((fieldName) => (
      this.verified.get(fieldName) && !this.errors.get(fieldName)
    ));
  }

  public isFormFilled() {
    return Array.from(this.fields.keys()).every((fieldName) => (
      !this.rules.get(fieldName).includes('required') || this.fields.get(fieldName)
    ));
  }
}

FormService.registerCustomRules();
export default FormService;
