import INotificationsDao from './INotificationsDao';
// Services
import ApiService from '../ApiService';
// Models
import AnnouncementModel, { IAnnouncementObject } from '../../models/AnnouncementModel';

interface IAnnouncementsResponse {
  announcements: IAnnouncementObject[];
}

const ANNOUNCEMENTS_ENDPOINT = '/admin/announcements';

export default class NotificationsDaoImpl implements INotificationsDao {
  private api: ApiService;

  constructor(api: ApiService) {
    this.api = api;
  }

  public async sendAnnouncement(
    message: string,
    targetTrustees: string[]|null,
    mailMagazineAnnouncement: boolean|null
  ): Promise<void> {
    await this.api.post<IAnnouncementsResponse>(ANNOUNCEMENTS_ENDPOINT, {
      content: message,
      target_trustees: targetTrustees,
      mail_magazine_announcement: mailMagazineAnnouncement
    });
  }

  public async loadAnnouncements(): Promise<AnnouncementModel[]> {
    const res = await this.api.get<IAnnouncementsResponse>(ANNOUNCEMENTS_ENDPOINT);
    return res.data.announcements.map((json) => new AnnouncementModel(json));
  }
}
