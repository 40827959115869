import React from 'react';
import { observer } from 'mobx-react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
// Containers/Components
import { Column } from '../../../../components/table';
import CustomersTable from '../../CustomersTable';
// Models
import CustomerModel, {
  CustomerAccountStatus, CustomerRole
} from '../../../../models/CustomerModel';
// Locales
import locales from '../../../../helpers/locales';
import { TrustAccountLinkStatus } from '../../../../models/TrustAccountModel';
// Styles
import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  loading: boolean;
  customers: CustomerModel[];
  currentPage: number;
  pagesCount: number;
  onPageChange: (page: number) => void;
}

@observer
class CustomersList extends React.Component<IProps> {
  static getAccountStatusColor(customer: CustomerModel) {
    switch (customer.accountStatus) {
      case CustomerAccountStatus.Active:
        return styles.positive;
      case CustomerAccountStatus.Locked:
      case CustomerAccountStatus.Closed:
        return styles.negative;
      default:
        return '';
    }
  }

  static getTrustAccountLinkStatusColor(customer: CustomerModel) {
    switch (customer.trustAccountLinkStatus) {
      case TrustAccountLinkStatus.Linked:
        return styles.positive;
      case TrustAccountLinkStatus.KickedOut:
      case TrustAccountLinkStatus.Resigned:
      case TrustAccountLinkStatus.Discarded:
        return styles.negative;
      default:
        return '';
    }
  }

  public render() {
    const {
      customers, loading, currentPage, pagesCount, onPageChange
    } = this.props;
    const columns: Column[] = [{
      width: 70,
      Header: locales.get('general.customer.branch'),
      id: 'branchCode',
      Cell: (row: { original: CustomerModel }) => {
        if (row.original.role === CustomerRole.Observer) {
          return '';
        }
        return row.original.branchCode;
      }
    }, {
      Header: locales.get('general.customer.bank_torihiki_number'),
      id: 'bankTorihikiNumber',
      Cell: (row: { original: CustomerModel }) => {
        if (row.original.role === CustomerRole.Observer) {
          return '';
        }
        return row.original.bankTorihikiNumber;
      }
    }, {
      Header: locales.get('general.trust_account.account_number'),
      id: 'trustAccount.number',
      Cell: (row: { original: CustomerModel }) => (
        <>
          <div>{row.original.trustAccount.number}</div>
          <Link
            className={styles.link}
            to={`/customers/accounts/${row.original.trustAccount.id}`}
            target="_blank"
          >
            {locales.get('customers.contract')}
          </Link>
        </>
      )
    }, {
      Header: locales.get('general.customer.name_kanji'),
      id: 'nameKanji',
      Cell: (row: { original: CustomerModel }) => (
        <>
          <div>{row.original.nameKanji}</div>
          <Link className={styles.link} to={`/customers/${row.original.id}`} target="_blank">
            {locales.get('customers.details')}
          </Link>
        </>
      )
    }, {
      Header: locales.get('general.customer.name_kana'),
      id: 'nameKana',
      Cell: (row: { original: CustomerModel }) => row.original.nameKana
    }, {
      Header: locales.get('general.customer.role'),
      Cell: (row: { original: CustomerModel }) => (
        <>
          <div>{row.original.roleString}</div>
          <div>{row.original.roleNumberString}</div>
        </>
      )
    }, {
      Header: locales.get('general.customer.account_status'),
      id: 'accountStatus',
      Cell: (row: { original: CustomerModel }) => {
        switch (row.original.trustAccountLinkStatus) {
          case TrustAccountLinkStatus.Initial:
          case TrustAccountLinkStatus.PendingQr:
            return null;

          default:
            return (
              <span className={CustomersList.getAccountStatusColor(row.original)}>
                {locales.get(`general.account_statuses.${row.original.accountStatus}`)}
              </span>
            );
        }
      }
    }, {
      Header: locales.get('general.customer.trust_account_link_status'),
      id: 'trustAccountLinkStatus',
      Cell: (row: { original: CustomerModel }) => {
        const localesKeyPrefix = 'general.trust_account_link_statuses';
        return (
          <span className={CustomersList.getTrustAccountLinkStatusColor(row.original)}>
            {locales.get(`${localesKeyPrefix}.${row.original.trustAccountLinkStatus}`)}
          </span>
        );
      }
    }];

    return (
      <CustomersTable
        columns={columns}
        loading={loading}
        data={customers}
        currentPage={currentPage}
        pagesCount={pagesCount}
        onPageChange={onPageChange}
      />
    );
  }
}

export default withRouter(CustomersList);
