import React from 'react';
// Components
import Modal from '../Modal';
import Title from '../Title';
import Text from '../Text';
// Styles
import styles from './styles.scss';

interface IImageAnnotation {
  title: string;
  body: string;
}

interface IProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  annotation?: IImageAnnotation;
}

interface IPosition {
  x: number;
  y: number;
}

interface IState {
  isDragging: boolean;
  baseImageOffset: IPosition;
  baseContainerOffset: IPosition;
}

export default class ImageViewer extends React.PureComponent<IProps, IState> {
  public imageRef: React.RefObject<HTMLImageElement>;
  public containerRef: React.RefObject<HTMLDivElement>;
  public isInsideImage = false;

  constructor(props: IProps) {
    super(props);
    this.imageRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = {
      isDragging: false,
      baseImageOffset: { x: 0, y: 0 },
      baseContainerOffset: { x: 0, y: 0 }
    };
  }

  public componentDidMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      this.registerListeners();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      if (isOpen) {
        this.registerListeners();
      } else {
        this.releaseListeners();
      }
    }
  }

  public enterImage = () => {
    this.isInsideImage = true;
  };

  public leaveImage = () => {
    this.isInsideImage = false;
  };

  public startDragging = (event: MouseEvent) => {
    const el = this.containerRef.current;
    if (!this.isInsideImage || !el) {
      return;
    }

    this.setState({
      isDragging: true,
      baseImageOffset: {
        x: event.screenX,
        y: event.screenY
      },
      baseContainerOffset: {
        x: el.scrollLeft,
        y: el.scrollTop
      }
    });
  };

  public stopDragging = () => {
    const { isDragging } = this.state;
    if (isDragging) {
      this.setState({ isDragging: false });
    }
  };

  public drag = (event: MouseEvent) => {
    const { isDragging, baseImageOffset, baseContainerOffset } = this.state;
    const el = this.containerRef.current;

    if (!isDragging || !el) {
      return;
    }

    const offset: IPosition = {
      x: baseImageOffset.x - event.screenX,
      y: baseImageOffset.y - event.screenY
    };

    el.scrollTop = baseContainerOffset.y + offset.y;
    el.scrollLeft = baseContainerOffset.x + offset.x;
  };

  public registerListeners() {
    const el = this.imageRef.current;
    if (el) {
      el.draggable = false;
      document.addEventListener('mousedown', this.startDragging);
      document.addEventListener('mouseup', this.stopDragging);
      document.addEventListener('mousemove', this.drag);
    }
  }

  public releaseListeners() {
    document.removeEventListener('mousedown', this.startDragging);
    document.removeEventListener('mouseup', this.stopDragging);
    document.addEventListener('mousemove', this.drag);
  }

  private renderAnnotation(annotation: IImageAnnotation) {
    return (
      <div className={styles.annotation}>
        <Title className={styles.annotationTitle}>{annotation.title}</Title>
        <Text className={styles.annotationBody}>{annotation.body}</Text>
      </div>
    );
  }

  public render() {
    const {
      isOpen,
      onClose,
      url,
      annotation
    } = this.props;

    return (
      <Modal closable className={styles.viewer} isOpen={isOpen} onClose={onClose}>
        <div ref={this.containerRef} className={styles.container}>
          { annotation ? this.renderAnnotation(annotation) : '' }
          <img
            src={url}
            ref={this.imageRef}
            onMouseEnter={this.enterImage}
            onMouseLeave={this.leaveImage}
          />
        </div>
      </Modal>
    );
  }
}
