import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Containers/Components
import { Column } from '../../../../components/table';
import CustomersTable from '../../CustomersTable';
// Models
import CustomerModel, { CustomerAccountStatus } from '../../../../models/CustomerModel';
import { TrustAccountLinkStatus } from '../../../../models/TrustAccountModel';
// Services
import TrustAccountsService from '../../../../services/TrustAccountsService';
import DialogService, { DialogType } from '../../../../services/DialogService';
// Locales
import locales from '../../../../helpers/locales';
// Styles
import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  trustAccountId: number;
  trustAccountsService: TrustAccountsService;
  dialogService: DialogService;
}

@observer
class TrustAccountMembers extends React.Component<IProps> {
  public async componentDidMount() {
    const { trustAccountId, trustAccountsService, dialogService } = this.props;
    try {
      await trustAccountsService.loadCustomersById(trustAccountId, 1);
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message,
        onClose: this.redirectToCustomersList
      });
    }
  }

  static getAccountStatusColor(customer: CustomerModel) {
    switch (customer.accountStatus) {
      case CustomerAccountStatus.Active:
        return styles.positive;
      case CustomerAccountStatus.Locked:
      case CustomerAccountStatus.Closed:
        return styles.negative;
      default:
        return '';
    }
  }

  static getTrustAccountLinkStatusColor(customer: CustomerModel) {
    switch (customer.trustAccountLinkStatus) {
      case TrustAccountLinkStatus.Linked:
        return styles.positive;
      case TrustAccountLinkStatus.KickedOut:
      case TrustAccountLinkStatus.Resigned:
      case TrustAccountLinkStatus.Discarded:
        return styles.negative;
      default:
        return '';
    }
  }

  public getRowStyle = () => ({ cursor: 'pointer' });

  public redirectToCustomersList = () => {
    const { history } = this.props;
    history.push('/customers');
  };

  public openCustomerDetailsPage = (customer: CustomerModel) => {
    const { history } = this.props;
    history.push(`/customers/${customer.id}`);
  };

  public changeMembersPage = async (page: number) => {
    const { trustAccountsService, trustAccountId } = this.props;
    await trustAccountsService.loadCustomersById(trustAccountId, page);
  };

  public render() {
    const { trustAccountsService } = this.props;
    const columns: Column[] = [{
      width: 70,
      Header: locales.get('general.customer.branch'),
      id: 'branchCode',
      Cell: (row: { original: CustomerModel }) => row.original.branchCode
    }, {
      Header: locales.get('general.customer.bank_torihiki_number'),
      id: 'bankTorihikiNumber',
      Cell: (row: { original: CustomerModel }) => row.original.bankTorihikiNumber
    }, {
      width: 150,
      Header: locales.get('account.contract_group.columns.name'),
      id: 'name',
      Cell: (row: { original: CustomerModel }) => (
        <>
          <div>{row.original.nameKanji}</div>
          <div>{row.original.nameKana}</div>
          <div>{locales.get(`general.customer_roles.${row.original.role}`)}</div>
        </>
      )
    }, {
      width: 200,
      Header: locales.get('account.contract_group.columns.address'),
      id: 'address',
      Cell: (row: { original: CustomerModel }) => (
        <>
          <div>{row.original.addressResidentialPostcode}</div>
          <div>{row.original.addressResidential}</div>
        </>
      )
    }, {
      Header: locales.get('general.customer.phone_number'),
      id: 'phoneNumber',
      Cell: (row: { original: CustomerModel }) => row.original.phoneNumber
    }, {
      width: 70,
      Header: locales.get('general.customer.gender'),
      id: 'gender'
      // NOTE: Hide gender value to avoid confusions
      // MUSK is not sending gender in their file (though there is a field for it) at present
      // but there is high chance they want to set gender in the filed in near future (for business analysis purpose).
      // Cell: (row: { original: CustomerModel }) =>
      //   locales.get(`general.gender.${row.original.gender}`)
    }, {
      width: 70,
      Header: locales.get('general.customer.relationship'),
      id: 'relationship',
      Cell: (row: { original: CustomerModel }) => row.original.relationship
    }, {
      Header: locales.get('general.customer.account_status'),
      id: 'accountStatus',
      Cell: (row: { original: CustomerModel }) => {
        const status = row.original.accountStatus;
        return (
          <span className={TrustAccountMembers.getAccountStatusColor(row.original)}>
            {locales.get(`general.account_statuses.${status}`)}
          </span>
        );
      }
    }, {
      Header: locales.get('general.customer.trust_account_link_status'),
      id: 'trustAccountLinkStatus',
      Cell: (row: { original: CustomerModel }) => {
        const status = row.original.trustAccountLinkStatus;
        return (
          <span className={TrustAccountMembers.getTrustAccountLinkStatusColor(row.original)}>
            {locales.get(`general.trust_account_link_statuses.${status}`)}
          </span>
        );
      }
    }];

    return (
      <CustomersTable
        getRowStyle={this.getRowStyle}
        columns={columns}
        loading={trustAccountsService.pendingTrustAccounts}
        data={trustAccountsService.customers.peek()}
        onRowDoubleClick={this.openCustomerDetailsPage}
        currentPage={trustAccountsService.customersCurrentPage}
        pagesCount={trustAccountsService.customersPagesCount}
        onPageChange={this.changeMembersPage}
      />
    );
  }
}

export default withRouter(TrustAccountMembers);
