// TODO STUB API does not return the custmer role. Confirm which one, swagger or API is correct
import { CustomerRole } from './CustomerModel';

export interface IPaymentAccountObject {
  id: number;
  bank_name: string;
  bank_code: string;
  branch_name: string;
  branch_code: string;
  account_name: string;
  account_number: string;
  account_type: string;
  customer_role: string;
}

export default class PaymentAccountModel {
  public id: number;
  public bankName: string;
  public bankCode: string;
  public branchName: string;
  public branchCode: string;
  public accountName: string;
  public accountNumber: string;
  public accountType: string;
  public customerRole: CustomerRole;

  constructor(json: IPaymentAccountObject) {
    this.id = json.id;
    this.bankName = json.bank_name;
    this.bankCode = json.bank_code;
    this.branchName = json.branch_name;
    this.branchCode = json.branch_code;
    this.accountName = json.account_name;
    this.accountNumber = json.account_number;
    this.accountType = json.account_type;
    this.customerRole = json.customer_role as CustomerRole;
  }

  get accountNumberString() {
    if (/^0.+/.test(this.accountNumber)) {
      return this.accountNumber.substr(1);
    }
    return this.accountNumber;
  }
}
