import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Components
import AnonymousPage from '../AnonymousPage';
import AuthenticatedPage from '../AuthenticatedPage';
import Dialog from '../Dialog';
// Services
import AccountService from '../../services/AccountService';
import DialogService from '../../services/DialogService';
import SettingsService from '../../services/SettingsService';
import ApiService from '../../services/ApiService';
// Models
import { AccountState } from '../../models/AccountModel';
// Styles
import styles from './styles.scss';
import Loader from '../Loader';

interface IProps {
  accountService: AccountService;
  dialogService: DialogService;
  settingsService: SettingsService;
  apiService: ApiService;
}

@inject('accountService', 'dialogService', 'settingsService')
@observer
export default class Root extends React.Component<IProps> {
  public renderAuthenticatedPage() {
    const {
      accountService,
      dialogService,
      settingsService,
      apiService
    } = this.props;

    return (
      <AuthenticatedPage
        accountService={accountService}
        dialogService={dialogService}
        settingsService={settingsService}
        apiService={apiService}
      />
    );
  }

  public renderAnonymousPage() {
    const { accountService, dialogService, settingsService } = this.props;
    return (
      <AnonymousPage
        accountService={accountService}
        dialogService={dialogService}
        settingsService={settingsService}
      />
    );
  }

  public render() {
    const { accountService, dialogService } = this.props;
    if (accountService.accountState === AccountState.Pending) {
      return <Loader loading />;
    }

    return (
      <Router>
        <>
          <div className={styles.root}>
            {accountService.isLoggedIn ? this.renderAuthenticatedPage() : this.renderAnonymousPage()}
            <Dialog dialogService={dialogService} />
          </div>
        </>
      </Router>
    );
  }
}
