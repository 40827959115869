import React from 'react';
import classnames from 'classnames';
// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  url: string;
  onMagnify: (url: string) => void;
}

export default class ImagePreview extends React.PureComponent<IProps> {
  static defaultProps = {
    className: ''
  };

  public handleClick = () => {
    const { url, onMagnify } = this.props;
    onMagnify(url);
  };

  public render() {
    const { className, url } = this.props;
    const style = {
      backgroundImage: `url(${url})`
    };

    return (
      <div className={classnames(styles.image, className)} style={style} onClick={this.handleClick}>
        <div className={classnames('mutb-magnify-icon', styles.magnify)} />
      </div>
    );
  }
}
