import React from 'react';
import classnames from 'classnames';
import {
  Link, Route, RouteComponentProps, withRouter
} from 'react-router-dom';

import styles from './styles.scss';
import locales from '../../helpers/locales';

interface IProps extends RouteComponentProps<{}> {
  label: string;
  to: string;
  disabled?: boolean;
}

// TODO: by messing with the exports in NavLink, we could probably just extend that here.
//       right now it is being exported withRouter(), which causes errors when trying to
//       `extend` it here
class ProductSelectionLink extends React.PureComponent<IProps> {
  static defaultProps = {
    disabled: false
  };

  public render() {
    const {
      to,
      label: currentProductLabel,
      disabled
    } = this.props;
    const buttonLabel = locales.get('productSelection.menuButtonLabel');

    const linkClassNames = classnames(
      styles.link,
      styles.squareButton,
      {
        [styles.disabled]: disabled
      }
    );

    return (
      <Route path={to}>
        <div className={styles.productSelectionLink}>
          <div className={styles.productLabel}>
            {currentProductLabel}
          </div>
          <div className={linkClassNames}>
            {disabled ? <div>{buttonLabel}</div> : <Link to={to}>{buttonLabel}</Link>}
          </div>
        </div>
      </Route>
    );
  }
}

export default withRouter(ProductSelectionLink);
