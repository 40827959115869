import React from 'react';
import classnames from 'classnames';
import ReactTable, { Column, RowInfo } from 'react-table';

// Components
import locales from '../../helpers/locales';

// Styles
import styles from './styles.scss';

export { Column } from 'react-table';

interface IProps<T> {
  data: T[];
  loading: boolean;
  columns: Column[];
  currentPage?: number;
  pagesCount?: number;
  onPageChange?: (page: number) => void;
  onRowDoubleClick?: (model: T) => void;
  getRowStyle?: (row: T) => React.CSSProperties;
}

interface IOptions<T> {
  defaultProps: {
    onRowDoubleClick?: (model: T) => void;
    getRowStyle?: (row: T) => React.CSSProperties;
  };
}

const DOUBLE_CLICK_TIMEOUT = 300;

export default function table<T>(options: IOptions<T> = { defaultProps: {} }) {
  class Table extends React.PureComponent<IProps<T>> {
    private doubleClickedRow: RowInfo|null = null;
    private doubleClickTimer = 0;
    static defaultProps = options.defaultProps;

    public onPageChange = (page: number) => {
      const { onPageChange } = this.props;
      if (!onPageChange) {
        throw new Error('this.onPageChange was invoked without passing onPageChange prop');
      }
      // NOTE: page parameter starts from 0 on React Table's onPageChange
      onPageChange(page + 1);
    };

    private resetDoubleClick = () => {
      this.doubleClickTimer = 0;
      this.doubleClickedRow = null;
    };

    private handleDoubleClick = (rowInfo: RowInfo) => {
      if (this.doubleClickedRow) {
        clearTimeout(this.doubleClickTimer);

        const { onRowDoubleClick } = this.props;
        if (onRowDoubleClick && this.doubleClickedRow === rowInfo) {
          onRowDoubleClick(rowInfo.original as T);
        }

        this.resetDoubleClick();
      } else {
        this.doubleClickTimer = window.setTimeout(this.resetDoubleClick, DOUBLE_CLICK_TIMEOUT);
        this.doubleClickedRow = rowInfo;
      }
    };

    private getRowProps = (_state: void, rowInfo?: RowInfo) => {
      const { getRowStyle } = this.props;
      if (!rowInfo || !getRowStyle) {
        return {};
      }
      const style = getRowStyle(rowInfo.original);
      return { style };
    };

    private makeCellProps = (_state: void, rowInfo?: RowInfo) => ({
      onClick: (_event: void, handleOriginal: () => void) => {
        const { onRowDoubleClick } = this.props;
        // Handle only populated row clicks
        if (rowInfo && onRowDoubleClick) {
          this.handleDoubleClick(rowInfo || null);
        }

        // Call the default onClick handler for table to work correctly
        if (handleOriginal) {
          handleOriginal();
        }
      }
    });

    public render() {
      const {
        currentPage, pagesCount, onPageChange, data, loading, columns
      } = this.props;

      let paginationProps = {};
      if (onPageChange) {
        paginationProps = {
          // NOTE: page parameter starts from 0 on React Table's onPageChange
          page: currentPage && currentPage - 1,
          pages: pagesCount,
          manual: true,
          showPagination: true,
          showPageSizeOptions: false,
          previousText: locales.get('pagination.previous'),
          nextText: locales.get('pagination.next'),
          pageText: locales.get('pagination.page'),
          ofText: locales.get('pagination.of'),
          onPageChange: this.onPageChange
        };
      }
      return (
        <ReactTable
          noDataText=""
          sortable={false}
          showPagination={false}
          data={data}
          loading={loading}
          columns={columns}
          defaultPageSize={0}
          loadingText={locales.get('loading')}
          className={classnames(styles.table, '-highlight')}
          getTdProps={this.makeCellProps}
          {...paginationProps}
          getTrProps={this.getRowProps}
        />
      );
    }
  }

  return Table;
}
