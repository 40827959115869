import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
// Components
import Button, { ButtonType } from '../../../components/Button';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import ImagePreview from '../../../components/ImagePreview';
import ClaimDetailsHeaderContent from './ClaimDetailsHeaderContent';
import ImageViewer from '../../../components/ImageViewer';
import ClaimDetailsClaimInformation from './ClaimDetailsClaimInformation';
import ClaimDetailsDetails from './ClaimDetailsDetails';
import ClaimDetailsReturnComment from './ClaimDetailsReturnComment';
import ClaimDetailsFamilyReactions from './ClaimDetailsFamilyReactions';
import ClaimDetailsComments from './ClaimDetailsComments';
import ClaimDetailsEvents from './ClaimDetailsEvents';
import Loader from '../../Loader';
// Services
import ClaimsService, { ClaimPermissions } from '../../../services/ClaimsService';
import DialogService, { DialogType } from '../../../services/DialogService';
import SettingsService from '../../../services/SettingsService';
// Models
import ClaimModel from '../../../models/ClaimModel';
import PaymentAccountModel from '../../../models/PaymentAccountModel';
// Helpers
import locales from '../../../helpers/locales';

// Styles
import styles from './styles.scss';

interface IParams {
  claimId: string;
}

interface IProps extends RouteComponentProps<IParams> {
  claimsService: ClaimsService;
  dialogService: DialogService;
  settingsService: SettingsService;
}

interface IState {
  isOpenViewer: boolean;
  isOpenDetails: boolean;
  returnedComment: string;
  isReadOnly: boolean;
}

@inject('claimsService', 'dialogService', 'settingsService')
@observer
class ClaimView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpenViewer: false,
      isOpenDetails: false,
      returnedComment: '',
      isReadOnly: false
    };
  }

  public async componentDidMount() {
    const {
      claimsService,
      dialogService,
      match: { params: { claimId } },
      location: { search }
    } = this.props;
    const queryParams = qs.parse(search.replace('?', ''));
    this.setState({
      isReadOnly: queryParams.readonly === 'true'
    });

    try {
      await claimsService.loadClaimById(Number(claimId));
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message,
        onClose: this.redirectToClaimsList
      });
    }
  }

  public redirectToClaimsList = () => {
    const { history } = this.props;
    history.push('/claims');
  };

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public openDetails = () => {
    this.setState({
      isOpenDetails: true
    });
  };

  public closeDetails = () => {
    this.setState({
      isOpenDetails: false
    });
  };

  public magnifyReceipt = () => {
    this.setState({
      isOpenViewer: true
    });
  };

  public addComment = async (message: string) => {
    const { claimsService } = this.props;
    const { claims } = claimsService;
    const claim = claims[0];
    try {
      await claimsService.addComment(claim, message);
      await claimsService.loadClaimById(claim.id);
    } catch (error) {
      this.handleError(error);
    }
  };

  public onChangeRejectionComment = (comment: string) => {
    this.setState({
      returnedComment: comment
    });
  };

  public closeWindow = () => {
    window.close();
  };

  public closeImageViewer = () => {
    this.setState({
      isOpenViewer: false
    });
  };

  public approveReceipt = () => {
    this.confirmReview(ClaimPermissions.ReviewApproveReceipt);
  };

  public rejectReceipt = () => {
    this.confirmReview(ClaimPermissions.ReviewRejectReceipt);
  };

  public approve = () => {
    this.confirmReview(ClaimPermissions.ReviewApprove);
  };

  public reject = () => {
    this.confirmReview(ClaimPermissions.ReviewReject);
  };

  public disagree = () => {
    this.confirmReview(ClaimPermissions.ReviewDisagree);
  };

  public abort = () => {
    this.confirmReview(ClaimPermissions.ReviewAbort);
  };

  public reset = () => {
    this.confirmReview(ClaimPermissions.ReviewReset);
  };

  public confirmSaveReturnedComment = () => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get('claim.messages.save_confirmation'),
      onConfirm: async () => {
        await this.saveReturnComment();
      }
    });
  };

  public confirmToggleLock = async () => {
    const { claimsService, dialogService } = this.props;
    const { claims } = claimsService;
    const claim = claims[0];

    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get(`claim.messages.${claim.locked ? 'unlock' : 'lock'}_confirmation`),
      onConfirm: async () => {
        await this.toggleClaimLock();
      }
    });
  };

  public confirmReview(action: ClaimPermissions) {
    const { settingsService } = this.props;

    const actionsLocalizedByProduct = [
      ClaimPermissions.ReviewApproveReceipt,
      ClaimPermissions.ReviewRejectReceipt
    ];

    let productKey: string|null = null;

    if (actionsLocalizedByProduct.includes(action)) {
      productKey = settingsService.productSelectionWithDefault;
    }

    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: locales.get(`claim.messages.${action}_confirmation${productKey ? `.${productKey}` : ''}`),
      onConfirm: async () => {
        await this.reviewClaim(action);
      }
    });
  }

  public async reviewClaim(action: ClaimPermissions) {
    const { claimsService, dialogService } = this.props;
    const { returnedComment } = this.state;
    const { claims } = claimsService;
    const claim = claims[0];
    try {
      if (claim.returnedComment !== returnedComment) {
        await claimsService.saveReturnedComment(claim, returnedComment);
      }
      await claimsService.reviewClaim(claim, action);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get(`claim.messages.${action}_success`),
        onClose: this.closeWindow
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  public async toggleClaimLock() {
    const { claimsService, dialogService } = this.props;
    const { claims } = claimsService;
    const claim = claims[0];

    try {
      await claimsService.toggleClaimLock(claim);

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get(`claim.messages.${claim.locked ? 'unlock' : 'lock'}_success`),
        onClose: this.closeWindow
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  public async saveReturnComment() {
    const { claimsService } = this.props;
    const { returnedComment } = this.state;
    const { claims } = claimsService;
    const claim = claims[0];
    try {
      await claimsService.saveReturnedComment(claim, returnedComment);
    } catch (error) {
      this.handleError(error);
    }
  }

  private buildAnnotationBody(paymentAccount: PaymentAccountModel) {
    const {
      bankName,
      bankCode,
      branchName,
      branchCode,
      accountType,
      accountNumberString,
      accountName
    } = paymentAccount;

    return [
      bankName,
      bankCode,
      branchName,
      branchCode,
      accountType,
      accountNumberString,
      accountName
    ]
      .filter((element) => element && element.trim().length > 0)
      .join(' ');
  }

  public renderDetailsInformationModal() {
    const { claimsService: { claims } } = this.props;
    const { isOpenDetails } = this.state;
    if (claims.length === 0 || !isOpenDetails) {
      return null;
    }
    const claim = claims[0];
    return <ClaimDetailsClaimInformation claim={claim} onClose={this.closeDetails} />;
  }

  public renderReceiptButtons(claim: ClaimModel) {
    const { returnedComment, isReadOnly } = this.state;
    const { claimsService, settingsService } = this.props;
    const canApproveReceipt =
      claimsService.hasPermission(claim, ClaimPermissions.ReviewApproveReceipt);
    const canRejectReceipt =
      claimsService.hasPermission(claim, ClaimPermissions.ReviewRejectReceipt);
    const doesRejectionCommentExist = Boolean(returnedComment);
    const productSelection = settingsService.productSelectionWithDefault;

    return (
      <>
        <Button
          label={locales.get(`claim.accept_receipt.${productSelection}`)}
          type={ButtonType.Square}
          onClick={this.approveReceipt}
          disabled={isReadOnly || doesRejectionCommentExist || !canApproveReceipt}
        />
        <Button
          label={locales.get(`claim.deny_receipt.${productSelection}`)}
          type={ButtonType.Square}
          onClick={this.rejectReceipt}
          disabled={isReadOnly || !doesRejectionCommentExist || !canRejectReceipt}
        />
      </>
    );
  }

  public renderApproveButton(claim: ClaimModel) {
    const { returnedComment, isReadOnly } = this.state;
    const { claimsService } = this.props;
    const canApprove = claimsService.hasPermission(claim, ClaimPermissions.ReviewApprove);

    return (
      <Button
        label={locales.get('claim.complete_review')}
        type={ButtonType.Square}
        onClick={this.approve}
        disabled={isReadOnly || !canApprove || Boolean(returnedComment)}
      />
    );
  }

  public renderRejectButton(claim: ClaimModel) {
    const { returnedComment, isReadOnly } = this.state;
    const { claimsService } = this.props;
    const canReject = claimsService.hasPermission(claim, ClaimPermissions.ReviewReject);

    return (
      <Button
        label={locales.get('claim.return_to_customer')}
        type={ButtonType.Square}
        onClick={this.reject}
        disabled={isReadOnly || !returnedComment || !canReject}
      />
    );
  }

  public renderLockAndUnlockButton(claim: ClaimModel) {
    const { isReadOnly } = this.state;
    const { claimsService } = this.props;
    const canToggleLock = claimsService.hasPermission(claim, ClaimPermissions.Lock);
    const label = claim.locked ? locales.get('claim.unlock') : locales.get('claim.lock');

    return (
      <Button
        label={label}
        type={ButtonType.Square}
        onClick={this.confirmToggleLock}
        disabled={isReadOnly || !canToggleLock}
      />
    );
  }

  public renderButtons(claim: ClaimModel) {
    const { isReadOnly } = this.state;
    const { claimsService } = this.props;

    const canDisagree = claimsService.hasPermission(claim, ClaimPermissions.ReviewDisagree);
    const canAbort = claimsService.hasPermission(claim, ClaimPermissions.ReviewAbort);
    const canReset = claimsService.hasPermission(claim, ClaimPermissions.ReviewReset);

    return (
      <div className={styles.buttons}>
        <div className={styles.buttonsRow}>
          {this.renderReceiptButtons(claim)}
        </div>
        <div className={styles.buttonsRow}>
          {this.renderApproveButton(claim)}
          {this.renderRejectButton(claim)}
          <Button
            label={locales.get('claim.restart_review')}
            type={ButtonType.Square}
            onClick={this.disagree}
            disabled={isReadOnly || !canDisagree}
          />
        </div>
        <div className={styles.buttonsRow}>
          <Button
            label={locales.get('claim.dissociate')}
            type={ButtonType.Square}
            onClick={this.abort}
            disabled={isReadOnly || !canAbort}
          />
          {this.renderLockAndUnlockButton(claim)}
          <Button
            label={locales.get('claim.reset')}
            type={ButtonType.Square}
            onClick={this.reset}
            disabled={isReadOnly || !canReset}
          />
        </div>
      </div>
    );
  }

  public renderDetails() {
    const { claimsService, settingsService } = this.props;
    const {
      claims, receipt, comments, events
    } = claimsService;
    const { isOpenViewer, isReadOnly } = this.state;
    if (claims.length === 0) {
      return null;
    }
    const claim = claims[0];

    let paymentAccountAnnotation;
    if (claim.customPaymentAccount) {
      paymentAccountAnnotation = {
        title: locales.get('general.claim.payment_account'),
        body: this.buildAnnotationBody(claim.paymentAccount)
      };
    }

    const canComment = claimsService.hasPermission(claim, ClaimPermissions.Comment);
    const canRejectComment = claimsService.hasPermission(claim, ClaimPermissions.RejectComment);
    return (
      <>
        <div className={styles.head}>
          <ClaimDetailsHeaderContent claim={claim} onClickLink={this.openDetails} />
        </div>

        {
          claim.customPaymentAccount ?
            (
              <div className={styles.customPaymentAccountWarning}>
                <Icon className={styles.bikkuriMarkWhiteCircle} name="bikkuri-mark" />
                <Text>{locales.get('claim.custom_payment_account_warning')}</Text>
              </div>
            ) : ''
        }

        <div className={styles.body}>
          <div className={styles.left}>

            <div className={styles.leftUpper}>

              <div className={styles.receiptFamily}>
                <ImagePreview
                  className={styles.receipt}
                  url={receipt ? receipt.receiptImageUrl : ''}
                  onMagnify={this.magnifyReceipt}
                />
                <ClaimDetailsFamilyReactions className={styles.family} claim={claim} />
              </div>
              <ClaimDetailsDetails className={styles.details} claim={claim} />

            </div>

            <div>
              <ClaimDetailsReturnComment
                claim={claim}
                disabled={isReadOnly || !canRejectComment}
                onChange={this.onChangeRejectionComment}
                saveReturnComment={this.confirmSaveReturnedComment}
                settingsService={settingsService}
              />
            </div>

          </div>
          <div className={styles.right}>

            <div className={styles.rightUpper}>
              <ClaimDetailsComments
                className={styles.comments}
                comments={comments}
                disabled={isReadOnly || !canComment}
                onSaveComment={this.addComment}
              />
              <ClaimDetailsEvents
                className={styles.events}
                events={events}
              />
            </div>
            <div className={styles.rightLower}>
              {this.renderButtons(claim)}
            </div>

          </div>
        </div>
        <ImageViewer
          isOpen={isOpenViewer}
          onClose={this.closeImageViewer}
          url={receipt ? receipt.receiptImageUrl : ''}
          annotation={paymentAccountAnnotation}
        />
        {this.renderDetailsInformationModal()}
      </>
    );
  }

  public renderLoader() {
    const { claimsService: { pendingClaims: loading } } = this.props;
    return <Loader loading={loading} />;
  }

  public render() {
    return (
      <>
        {this.renderDetails()}
        {this.renderLoader()}
      </>
    );
  }
}

export default withRouter(ClaimView);
