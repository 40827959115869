import EventSourceModel, { IEventSourceObject } from './EventSourceModel';

export enum ClaimEventName {
  Create = 'create',
  Update = 'update',
  Submit = 'submit',
  ReviewStart = 'review.start',
  ReviewAbort = 'review.abort',
  ReviewApproveReceipt = 'review.approve_receipt',
  ReviewRejectReceipt = 'review.reject_receipt',
  ReviewApprove = 'review.approve',
  ReviewReject = 'review.reject',
  ReviewDisagree = 'review.disagree',
  PaymentDone = 'payment.done',
  PaymentRevoked = 'payment.revoked',
  PaymentInsufficientFundsFail = 'payment.fail.insufficient_funds',
  PaymentAccountInvalidFail = 'payment.fail.account_invalid',
  Lock = 'lock',
  Unlock = 'unlock',
  Cancel = 'cancel'
}

export interface IClaimEventObject {
  name: string;
  at: string;
  source: IEventSourceObject;
}

export default class ClaimEventModel {
  public name: ClaimEventName;
  public at: Date;
  public source: EventSourceModel;

  constructor(json: IClaimEventObject) {
    this.name = json.name as ClaimEventName;
    this.at = new Date(json.at);
    this.source = new EventSourceModel(json.source);
  }
}
