// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Loader_loader_2vXMs {\n  text-align: center; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA","file":"styles.scss","sourcesContent":[".loader {\n  text-align: center;\n}\n"]}]);
// Exports
exports.locals = {
	"loader": "Loader_loader_2vXMs"
};
module.exports = exports;
