import { translateCurrency, translateDate } from '../helpers/translate';

export interface ITransactionObject {
  id: number;
  date: string;
  amount: number;
  currency: string;
  memo: string;
  category_name: string;
  claim_id: number;
  created_at: string;
  updated_at: string;
}

export default class TransactionModel {
  public id: number;
  public date: Date;
  public amount: number;
  public currency: string;
  public memo: string;
  public categoryName: string;
  public claimId: number;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(json: ITransactionObject) {
    this.id = json.id;
    this.date = new Date(json.date);
    this.amount = json.amount;
    this.currency = json.currency;
    this.memo = json.memo;
    this.categoryName = json.category_name;
    this.claimId = json.claim_id;
    this.createdAt = new Date(json.created_at);
    this.updatedAt = new Date(json.updated_at);
  }

  get dateString() {
    return translateDate(this.date);
  }

  get amountString() {
    return translateCurrency(this.amount);
  }
}
