import React from 'react';
import classnames from 'classnames';

// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  titleClassName?: string;
  title: string;
  actionButton?: React.ReactNode;
  onScroll?: () => void;
}

export default class Section extends React.PureComponent<IProps> {
  static defaultProps = {
    onScroll: () => undefined
  };

  private onScroll = () => {
    const { onScroll } = this.props;
    if (!onScroll) {
      return;
    }
    onScroll();
  };

  public renderActionButton() {
    const { actionButton } = this.props;
    if (!actionButton) {
      return null;
    }
    return <div className={styles.action}>{actionButton}</div>;
  }

  public render() {
    const {
      children, title, className, titleClassName
    } = this.props;
    return (
      <div className={classnames(styles.feed, className)}>
        <div className={classnames(styles.title, titleClassName)}>{title}</div>
        {this.renderActionButton()}
        <div className={styles.content} onScroll={this.onScroll}>
          {children}
        </div>
      </div>
    );
  }
}
