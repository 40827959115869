// Models
import CustomerModel, { ICustomerObject } from './CustomerModel';
import UserModel, { IUserObject } from './UserModel';
import { translateCurrency, translateDate } from '../helpers/translate';
import PaymentAccountModel, { IPaymentAccountObject } from './PaymentAccountModel';
import ClaimConfirmationModel, { IClaimConfirmationObject } from './ClaimConfirmationModel';
import locales from '../helpers/locales';

export enum ClaimStatus {
  Created = 'created',
  Submitted = 'submitted',
  InitialReview = 'initial_review',
  AwaitingCheck = 'awaiting_check',
  Checking = 'checking',
  Reviewed = 'reviewed',
  Returned = 'returned',
  PendingExport = 'pending_export',
  Exported = 'exported',
  Paid = 'paid',
  Cancelled = 'cancelled'
}

export enum ClaimSearchDates {
  SubmittedAt = 'submitted_at',
  MimamoriUntilDate = 'mimamori_until_date',
  ReturnedAt = 'returned_at',
  ExpectedExportDate = 'expected_export_date',
  ExpectedPaymentDate = 'expected_payment_date',
  PaidDate = 'paid_date',
  DeletedAt = 'deleted_at'
}

export enum ClaimSearchItems {
  InitialReviewerKanjiName = 'initial_reviewer_kanji_name',
  SecondaryReviewerKanjiName = 'secondary_reviewer_kanji_name',
  BranchCode = 'branch_code',
  TrustAccountNumber = 'trust_account_number',
  CustomerNameKanji = 'customer_name_kanji',
  CustomerNameKana = 'customer_name_kana',
  CustomerRole = 'customer_role',
  Amount = 'amount'
}

export interface IClaimObject {
  id: number;
  initial_review_admin_user: IUserObject;
  secondary_review_admin_user: IUserObject;
  locked: boolean;
  status: string;
  date: string;
  payee: string;
  amount: number;
  currency: string;
  memo: string;
  category_name: string;
  returned_comment: string;
  claim_confirmations: IClaimConfirmationObject[];
  customer: ICustomerObject;
  is_payment_error: boolean;
  is_pending_export: boolean;
  submitted_at: string|null;
  mimamori_until_date: string|null;
  expected_export_date: string|null;
  expected_payment_date: string|null;
  returned_at: string|null;
  reviewed_at: string|null;
  paid_date: string|null;
  deleted_at: string|null;
  cancelled_at: string|null;
  created_at: string;
  updated_at: string;
  payment_account: IPaymentAccountObject;
  number: string|null;
  custom_payment_account: boolean;
}

export default class ClaimModel {
  public id: number;
  public initialReviewAdminUser: UserModel|null;
  public secondaryReviewAdminUser: UserModel|null;
  public locked: boolean;
  public status: ClaimStatus;
  public date: Date;
  public payee: string;
  public amount: number;
  public currency: string;
  public memo: string;
  public categoryName: string;
  public returnedComment: string;
  public claimConfirmations: ClaimConfirmationModel[];
  public customer: CustomerModel;
  public isPaymentError: boolean;
  public isPendingExport: boolean;
  public submittedAt: Date|null;
  public mimamoriUntilDate: Date|null;
  public expectedExportDate: Date|null;
  public expectedPaymentDate: Date|null;
  public returnedAt: Date|null;
  public reviewedAt: Date|null;
  public paidDate: Date|null;
  public deletedAt: Date|null;
  public cancelledAt: Date|null;
  public createdAt: Date;
  public updatedAt: Date|null;
  public paymentAccount: PaymentAccountModel;
  public number: string|null;
  public customPaymentAccount: boolean;

  constructor(json: IClaimObject) {
    this.id = json.id;
    this.initialReviewAdminUser = json.initial_review_admin_user ?
      new UserModel(json.initial_review_admin_user) :
      null;

    this.secondaryReviewAdminUser = json.secondary_review_admin_user ?
      new UserModel(json.secondary_review_admin_user) :
      null;
    this.locked = json.locked;
    this.status = json.is_pending_export ? ClaimStatus.PendingExport : json.status as ClaimStatus;
    this.date = new Date(json.date);
    this.payee = json.payee;
    this.amount = json.amount;
    this.currency = json.currency;
    this.memo = json.memo;
    this.categoryName = json.category_name;
    this.returnedComment = json.returned_comment;
    this.claimConfirmations = json.claim_confirmations.map(
      (confirmation) => new ClaimConfirmationModel(confirmation)
    );
    this.customer = new CustomerModel(json.customer);
    this.isPaymentError = json.is_payment_error;
    this.isPendingExport = json.is_pending_export;
    this.submittedAt = json.submitted_at ? new Date(json.submitted_at) : null;
    this.mimamoriUntilDate = json.mimamori_until_date ? new Date(json.mimamori_until_date) : null;
    this.expectedExportDate =
      json.expected_export_date ? new Date(json.expected_export_date) : null;
    this.expectedPaymentDate =
      json.expected_payment_date ? new Date(json.expected_payment_date) : null;
    this.returnedAt = json.returned_at ? new Date(json.returned_at) : null;
    this.reviewedAt = json.reviewed_at ? new Date(json.reviewed_at) : null;
    this.paidDate = json.paid_date ? new Date(json.paid_date) : null;
    this.deletedAt = json.deleted_at ? new Date(json.deleted_at) : null;
    this.cancelledAt = json.cancelled_at ? new Date(json.cancelled_at) : null;
    this.createdAt = new Date(json.created_at);
    this.updatedAt = json.updated_at ? new Date(json.updated_at) : null;
    this.paymentAccount = new PaymentAccountModel(json.payment_account);
    this.number = json.number;
    this.customPaymentAccount = json.custom_payment_account;
  }

  get dateString() {
    return translateDate(this.date);
  }

  get submittedAtString() {
    return translateDate(this.submittedAt);
  }

  get expectedExportDateString() {
    return translateDate(this.expectedExportDate);
  }

  get expectedPaymentDateString() {
    return translateDate(this.expectedPaymentDate);
  }

  get mimamoriUntilDateString() {
    return translateDate(this.mimamoriUntilDate);
  }

  get paidDateString() {
    return translateDate(this.paidDate);
  }

  get returnedAtString() {
    return translateDate(this.returnedAt);
  }

  get cancelledAtString() {
    return translateDate(this.cancelledAt);
  }

  get amountString() {
    return translateCurrency(this.amount);
  }

  get statusString() {
    return locales.get(`general.claim_statuses.${this.status}`);
  }
}
