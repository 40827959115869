import React from 'react';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Containers/Components
import Breadcrumb from '../../../components/Breadcrumb';
import Title from '../../../components/Title';
import DescriptionList, { DescriptionListSize } from '../../../components/DescriptionList';
import InputField, { InputFieldType } from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import Button, { ButtonSize } from '../../../components/Button';
import Loader from '../../Loader';
// Models
import { UserRole } from '../../../models/UserModel';
// Services
import FormService from '../../../services/FormService';
import UsersService from '../../../services/UsersService';
import DialogService, { DialogType } from '../../../services/DialogService';
// Locales
import locales from '../../../helpers/locales';

import styles from './styles.scss';

const ROLE_OPTIONS = Object.keys(UserRole)
  .map((key: string) => ({
    value: UserRole[key],
    label: locales.get(`general.admin_user_roles.${UserRole[key]}`)
  }));

const BRANCH_CODE_FIELD = locales.get('general.admin_user.branch_code');
const EMPLOYEE_NUMBER_FIELD = locales.get('general.admin_user.employee_number');
const NAME_FIELD = locales.get('general.admin_user.name_kanji');
const ROLE_FIELD = locales.get('general.admin_user.role');
const PASSWORD_FIELD = locales.get('admin_user_new.password');

interface IProps extends RouteComponentProps<{}> {
  usersService: UsersService;
  dialogService: DialogService;
}

@inject('usersService', 'dialogService')
@observer
class NewUserView extends React.Component<IProps> {
  private form: FormService;

  constructor(props: IProps) {
    super(props);
    this.form = new FormService()
      .setField(BRANCH_CODE_FIELD, 'required|digits:3')
      .setField(EMPLOYEE_NUMBER_FIELD, 'required|alphanumeric|min:7|max:15')
      .setField(NAME_FIELD, 'required')
      .setField(ROLE_FIELD, 'required')
      .setField(PASSWORD_FIELD, 'required|password');
  }

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public changeField = (event: React.SyntheticEvent<HTMLInputElement|HTMLSelectElement>) => {
    this.form.setValue(event.currentTarget.name, event.currentTarget.value);
  };

  public blurField = (event: React.SyntheticEvent<HTMLInputElement|HTMLSelectElement>) => {
    this.form.validate(event.currentTarget.name);
  };

  public submitUserCreation = () => {
    if (!this.form.validateAll()) {
      return;
    }
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Confirm,
      onConfirm: this.requestUserCreation,
      content: locales.get('admin_user_new.messages.confirmation')
    });
  };

  public requestUserCreation = async () => {
    const { dialogService, usersService } = this.props;
    try {
      await usersService.requestUserCreation({
        branch_code: this.form.getValue(BRANCH_CODE_FIELD),
        employee_number: this.form.getValue(EMPLOYEE_NUMBER_FIELD),
        name_kanji: this.form.getValue(NAME_FIELD),
        role: this.form.getValue(ROLE_FIELD),
        temporary_password: this.form.getValue(PASSWORD_FIELD)
      });

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('admin_user_new.messages.success'),
        onClose: this.closeRegistrationResultOnSuccess
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public closeRegistrationResultOnSuccess = () => {
    if (window.opener) {
      window.close();
      return;
    }
    const { history } = this.props;
    history.push('/users/pending');
  };

  public renderHead() {
    const texts = [
      locales.get('navigation.users_management'),
      locales.get('admin_user_new.breadcrumb.new_registration')
    ];
    return (
      <>
        <div className={styles.head}>
          <Breadcrumb texts={texts} />
        </div>
        <Title>{locales.get('admin_user_new.title')}</Title>
      </>
    );
  }

  public renderForm() {
    return (
      <div className={styles.form}>
        <DescriptionList className={styles.descriptionList} size={DescriptionListSize.Big}>
          <div className="row">
            <div className={classnames(styles.listTitle, 'cell')}>{BRANCH_CODE_FIELD}</div>
            <InputField
              name={BRANCH_CODE_FIELD}
              className={classnames(styles.field, 'cell')}
              type={InputFieldType.Text}
              value={this.form.getValue(BRANCH_CODE_FIELD)}
              error={this.form.getError(BRANCH_CODE_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
          <div className="row">
            <div className={classnames(styles.listTitle, 'cell')}>{EMPLOYEE_NUMBER_FIELD}</div>
            <InputField
              name={EMPLOYEE_NUMBER_FIELD}
              className={classnames(styles.field, 'cell')}
              type={InputFieldType.Text}
              value={this.form.getValue(EMPLOYEE_NUMBER_FIELD)}
              error={this.form.getError(EMPLOYEE_NUMBER_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
          <div className="row">
            <div className={classnames(styles.listTitle, 'cell')}>{NAME_FIELD}</div>
            <InputField
              name={NAME_FIELD}
              className={classnames(styles.field, 'cell')}
              type={InputFieldType.Text}
              value={this.form.getValue(NAME_FIELD)}
              error={this.form.getError(NAME_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
          <div className="row">
            <div className={classnames(styles.listTitle, 'cell')}>{ROLE_FIELD}</div>
            <SelectField
              name={ROLE_FIELD}
              className={classnames(styles.field, 'cell')}
              placeholder={locales.get('admin_user_new.role.placeholder')}
              options={ROLE_OPTIONS}
              value={this.form.getValue(ROLE_FIELD)}
              error={this.form.getError(ROLE_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
          <div className="row">
            <div className={classnames(styles.listTitle, 'cell')}>{PASSWORD_FIELD}</div>
            <InputField
              name={PASSWORD_FIELD}
              className={classnames(styles.field, 'cell')}
              type={InputFieldType.Password}
              value={this.form.getValue(PASSWORD_FIELD)}
              error={this.form.getError(PASSWORD_FIELD)}
              onChange={this.changeField}
              onBlur={this.blurField}
            />
          </div>
        </DescriptionList>
      </div>
    );
  }

  public renderButton() {
    return (
      <div className={styles.buttons}>
        <Button
          label={locales.get('admin_user_new.register')}
          size={ButtonSize.Big}
          disabled={!this.form.isFormFilled()}
          onClick={this.submitUserCreation}
        />
      </div>
    );
  }

  public render() {
    const { usersService: { pendingUsers: loading } } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <div className={styles.user}>
          {this.renderHead()}
          {this.renderForm()}
          {this.renderButton()}
        </div>
      </>
    );
  }
}

export default withRouter(NewUserView);
