// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ClaimsListView_minus_3VmvM::first-letter {\n  font-size: 25px; }\n\n.ClaimsListView_tooltipCell_2yD1h {\n  overflow: inherit !important; }\n\n.ClaimsListView_expectedDate_tnqt2 span {\n  display: block;\n  font-size: 10px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAAA;EAEI,eAAe,EAAA;;AAInB;EACE,4BAA4B,EAAA;;AAG9B;EAEI,cAAc;EACd,eAAe,EAAA","file":"styles.scss","sourcesContent":[".minus {\n  &::first-letter {\n    font-size: 25px;\n  }\n}\n\n.tooltipCell {\n  overflow: inherit !important;\n}\n\n.expectedDate {\n  span {\n    display: block;\n    font-size: 10px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"minus": "ClaimsListView_minus_3VmvM",
	"tooltipCell": "ClaimsListView_tooltipCell_2yD1h",
	"expectedDate": "ClaimsListView_expectedDate_tnqt2"
};
module.exports = exports;
