import {
  action, observable, runInAction, IObservableArray
} from 'mobx';

import ClaimModel from '../../models/ClaimModel';
import ClaimsDao, { IClaimsSearchParams } from './ClaimsDao';
import ClaimCommentModel from '../../models/ClaimCommentModel';
import ClaimEventModel from '../../models/ClaimEventModel';
import ClaimReceiptModel from '../../models/ClaimReceiptModel';
import AccountModel from '../../models/AccountModel';
// Services
import AccountService from '../AccountService';
import ManagedService from '../ManagedService';
// Permissions
import { permissions, ClaimPermissions } from './permissions';

export { ClaimPermissions } from './permissions';

export default class ClaimsService extends ManagedService<ClaimsDao, ClaimPermissions> {
  public accountService: AccountService;
  @observable public pendingClaims = false;
  @observable public currentPage = 0;
  @observable public pagesCount = 0;
  @observable public claims: IObservableArray<ClaimModel> = observable<ClaimModel>([]);
  @observable public comments: IObservableArray<ClaimCommentModel> = observable<ClaimCommentModel>([]);
  @observable public events: IObservableArray<ClaimEventModel> = observable<ClaimEventModel>([]);
  @observable public receipt: ClaimReceiptModel|null = null;

  constructor(dao: ClaimsDao, accountService: AccountService) {
    super(dao);
    this.accountService = accountService;
  }

  // API_CLAIM01
  @action public async loadClaims(params: IClaimsSearchParams): Promise<void> {
    const { account } = this.accountService;
    if (!account) {
      throw new Error('Attempted to use method without account has been loaded');
    }

    try {
      this.pendingClaims = true;
      this.claims.replace([]);
      const { pagination: { current, last }, claims } = await this.dao.loadClaims(params);

      runInAction(() => {
        this.resetPermissions();
        claims.map((claim) => this.buildPermissions(account, claim));
        this.claims.replace(claims);
        this.currentPage = current;
        this.pagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingClaims = false;
      });
    }
  }

  // API_CLAIM02, API_CLAIM04, API_CLAIM05, API_CLAIM08
  @action public async loadClaimById(id: number): Promise<void> {
    const { account } = this.accountService;
    if (!account) {
      throw new Error('Attempted to use method without account has been loaded');
    }

    try {
      this.pendingClaims = true;
      const [claim, comments, events, receipt] = await Promise.all([
        this.dao.loadClaimById(id),
        this.dao.loadClaimCommentsById(id),
        this.dao.loadClaimEventsById(id),
        this.dao.loadClaimReceiptById(id)
      ]);

      runInAction(() => {
        this.resetPermissions();
        this.buildPermissions(account, claim);
        this.claims.replace([claim]);
        this.comments.replace(comments);
        this.events.replace(events);
        this.receipt = receipt;
      });
    } finally {
      runInAction(() => {
        this.pendingClaims = false;
      });
    }
  }

  // API_CLAIM09
  @action public async reviewClaim(
    claim: ClaimModel, claimAction: ClaimPermissions, returnedComment: string|null = null
  ) {
    try {
      this.pendingClaims = true;
      await this.dao.reviewClaim(claim.id, claimAction, returnedComment);
    } finally {
      runInAction(() => {
        this.pendingClaims = false;
      });
    }
  }

  // API_CLAIM06
  @action public async addComment(claim: ClaimModel, message: string) {
    try {
      this.pendingClaims = true;
      await this.dao.addComment(claim.id, message);
    } finally {
      runInAction(() => {
        this.pendingClaims = false;
      });
    }
  }

  // API_CLAIM11
  @action public async saveReturnedComment(claim: ClaimModel, message: string) {
    try {
      this.pendingClaims = true;
      await this.dao.saveReturnedComment(claim.id, message);
    } finally {
      runInAction(() => {
        this.pendingClaims = false;
      });
    }
  }

  // API_CLAIM10
  @action public async toggleClaimLock(claim: ClaimModel) {
    try {
      this.pendingClaims = true;
      await this.dao.toggleClaimLock(claim.id, !claim.locked);
    } finally {
      runInAction(() => {
        this.pendingClaims = false;
      });
    }
  }

  private buildPermissions(account: AccountModel, claim: ClaimModel) {
    const statusPermissions = permissions[account.role][claim.status];
    if (!statusPermissions.permissions) {
      return;
    }

    statusPermissions.permissions.forEach((permission) => {
      if (!statusPermissions.condition || statusPermissions.condition(account, claim, permission)) {
        this.addPermission(claim, permission as ClaimPermissions);
      }
    });
  }
}
