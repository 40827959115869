import { translateDateTime } from '../helpers/translate';
import AnnouncementAuthorModel, { IAnnouncementAuthorObject } from './AnnouncementAuthorModel';

export interface IAnnouncementObject {
  id: number;
  content: string;
  target_trustees: string[]|null;
  created_at: string;
  updated_at: string;
  admin_user: IAnnouncementAuthorObject;
}

export default class AnnouncementModel {
  public id: number;
  public content: string;
  public targetTrustees: string[]|null;
  public createdAt: Date;
  public updatedAt: Date;
  public adminUser: AnnouncementAuthorModel;

  constructor(json: IAnnouncementObject) {
    this.id = json.id;
    this.content = json.content;
    this.targetTrustees = json.target_trustees;
    this.createdAt = new Date(json.created_at);
    this.updatedAt = new Date(json.updated_at);
    this.adminUser = new AnnouncementAuthorModel(json.admin_user);
  }

  get createdAtString() {
    return translateDateTime(this.createdAt);
  }

  get updatedAtString() {
    return translateDateTime(this.updatedAt);
  }
}
