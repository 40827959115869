import React from 'react';
import classnames from 'classnames';

// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
}

export default class Title extends React.PureComponent<IProps> {
  public render() {
    const { children, className } = this.props;
    return <div className={classnames(styles.title, className)}>{children}</div>;
  }
}
