import React from 'react';
import classnames from 'classnames';
// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

export default class LinkButton extends React.PureComponent<IProps> {
  static defaultProps = {
    className: '',
    disabled: false
  };

  public handleClick = () => {
    const { onClick, disabled } = this.props;

    if (!disabled) {
      onClick();
    }
  };

  public render() {
    const { className, label, disabled } = this.props;
    const classNames = classnames(className, styles.button, { [styles.disabled]: disabled });
    return (
      <button
        type="button"
        className={classNames}
        onClick={this.handleClick}
        disabled={disabled}
      >
        {label}
      </button>
    );
  }
}
