import {
  action, IObservableArray, observable, runInAction
} from 'mobx';
import InvitationModel, { IInvitationsSearchParams } from '../../models/InvitationModel';
import InvitationsDao from './InvitationsDao';

export default class InvitationsService {
  public dao: InvitationsDao;
  @observable public pendingInvitations = false;
  @observable public currentPage = 0;
  @observable public pagesCount = 0;
  @observable public invitations: IObservableArray<InvitationModel> = observable<InvitationModel>([]);

  constructor(dao: InvitationsDao) {
    this.dao = dao;
  }

  // APP_INV01
  @action public async loadInvitations(params: IInvitationsSearchParams): Promise<void> {
    try {
      this.pendingInvitations = true;
      this.invitations.replace([]);
      const { pagination: { current, last }, invitations } = await this.dao.loadInvitations(params);

      runInAction(() => {
        this.invitations.replace(invitations);
        this.currentPage = current;
        this.pagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingInvitations = false;
      });
    }
  }
}
