import locales from '../../helpers/locales';

export default async function sharedServicesErrorHandler(error: { response?: { status: number } }) {
  if (!error.response) {
    throw new Error(locales.get('error_messages.connection_error'));
  }
  const { status } = error.response;
  switch (status) {
    case 400:
      throw new Error(locales.get('error_messages.bad_request'));
    case 401:
      throw new Error(locales.get('error_messages.unauthenticated'));
    case 403:
      throw new Error(locales.get('error_messages.unauthorized'));
    case 404:
      throw new Error(locales.get('error_messages.not_found'));
    default:
      if (status >= 500) {
        throw new Error(locales.get('error_messages.server_error'));
      }
      throw new Error(locales.get('error_messages.connection_error'));
  }
}
