import moment from 'moment';
import locales from './locales';

function translateDate(date: Date|null) {
  if (!date) {
    return '';
  }
  return moment(date).format(locales.get('general.date'));
}

function translateDateTime(dateTime: Date|null) {
  if (!dateTime) {
    return '';
  }
  return moment(dateTime).format(locales.get('general.datetime'));
}

function translateCurrency(amount: number) {
  const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY'
  });
  return formatter.format(amount);
}

export {
  translateDate,
  translateDateTime,
  translateCurrency
};
