import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react';

import Icon from '../Icon';
import ProductSelectionLink from '../ProductSelectionLink';

import SettingsService from '../../services/SettingsService';

import styles from './styles.scss';
import locales from '../../helpers/locales';

interface IProps {
  children?: React.ReactNode;
  settingsService: SettingsService;
  hideProductLink?: boolean;
}

@observer
export default class Header extends React.PureComponent<IProps> {
  public render() {
    const { children, settingsService, hideProductLink } = this.props;

    return (
      <Router>
        <div className={styles.header}>
          <div className={styles.content}>
            <div className={styles.logoContainer}>
              <Icon className={styles.logo} name="logo" />
            </div>
            <div className={styles.productSelection}>
              {
                (settingsService.productSelectionInProgress || hideProductLink) ? '' : (
                  <ProductSelectionLink
                    label={locales.get(`navigation.${settingsService.productSelectionWithDefault}`)}
                    to="/product-selection"
                    disabled={false}
                  />
                )
              }
            </div>
            <div className={styles.menu}>
              {children}
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
