import qs from 'qs';
// Interfaces
import CustomersDao, { ILoadCustomersResponse, ILoadCustomerNotificationsResponse } from './CustomersDao';
import Pagination from '../Pagination';
// Services
import ApiService from '../ApiService';
import SettingsService from '../SettingsService';
// Models
import CustomerModel, { ICustomerObject, ICustomersSearchParams } from '../../models/CustomerModel';
import CustomerNotificationModel, { ICustomerNotificationObject } from '../../models/CustomerNotificationModel';
import CustomerEventModel, { ICustomerEventObject } from '../../models/CustomerEventModel';
import { TrustAccountLinkStatus } from '../../models/TrustAccountModel';

const CUSTOMERS_ENDPOINT = '/admin/customers';
const UNLOCK_ENDPOINT = '/lock/unlock';
const IS_ACCOUNT_LOCKED_ENDPOINT = '/lock/is_locked';

interface ICustomersResponse extends Pagination {
  customers: ICustomerObject[];
}

interface ICustomerNotificationsResponse extends Pagination {
  notifications: ICustomerNotificationObject[];
}

interface ICustomerEventsResponse {
  events: ICustomerEventObject[];
}

export interface ICustomersQueryParams {
  page: number;
  branch_code?: string;
  bank_torihiki_number?: string;
  account_number?: string;
  customer_name_kanji?: string;
  customer_name_kana?: string;
  trust_account_link_status?: TrustAccountLinkStatus;
}

interface IIsAccountLockedRespnose {
  islocked: boolean;
}

export default class CustomersDaoImpl implements CustomersDao {
  public api: ApiService;
  private settings: SettingsService;

  constructor(api: ApiService, settingsService: SettingsService) {
    this.api = api;
    this.settings = settingsService;
  }

  private namespacedLambdaEndpointFor = (endpoint: string): string => {
    const base = this.settings.isProductSelectionOhitori ? '/hitori' : '';
    return `${base}${endpoint}`;
  };

  public async loadCustomers(params: ICustomersSearchParams): Promise<ILoadCustomersResponse> {
    const queryParams: ICustomersQueryParams = {
      page: params.page
    };

    if (params.branchCode) {
      queryParams.branch_code = params.branchCode.trim();
    }
    if (params.bankTorihikiNumber) {
      queryParams.bank_torihiki_number = params.bankTorihikiNumber.trim();
    }
    if (params.accountNumber) {
      queryParams.account_number = params.accountNumber.trim();
    }
    if (params.customerNameKanji) {
      queryParams.customer_name_kanji = params.customerNameKanji.trim();
    }
    if (params.customerNameKana) {
      queryParams.customer_name_kana = params.customerNameKana.trim();
    }
    if (params.trustAccountLinkStatus) {
      queryParams.trust_account_link_status = params.trustAccountLinkStatus;
    }

    const query = qs.stringify(queryParams);
    const res = await this.api.get<ICustomersResponse>(`${CUSTOMERS_ENDPOINT}/search?${query}`);
    return {
      pagination: res.data.pagination,
      customers: res.data.customers.map(
        (customerJson) => new CustomerModel(customerJson, this.settings.isProductSelectionOhitori)
      )
    };
  }

  public async loadCustomerById(id: number): Promise<CustomerModel> {
    const res = await this.api.get<ICustomerObject>(`${CUSTOMERS_ENDPOINT}/${id}`);
    return new CustomerModel(res.data);
  }

  public async loadNotificationsById(
    id: number, page: number
  ): Promise<ILoadCustomerNotificationsResponse> {
    const query = qs.stringify({ page });
    const res = await this.api.get<ICustomerNotificationsResponse>(
      `${CUSTOMERS_ENDPOINT}/${id}/notifications?${query}`
    );
    const notifications = res.data.notifications
      .map((notification) => new CustomerNotificationModel(notification));

    return {
      notifications,
      pagination: res.data.pagination
    };
  }

  public async loadEventsById(id: number): Promise<CustomerEventModel[]> {
    const res = await this.api.get<ICustomerEventsResponse>(`${CUSTOMERS_ENDPOINT}/${id}/events`);
    return res.data.events.map((event) => new CustomerEventModel(event));
  }

  public async downloadQrByCustomers(ids: number[]): Promise<Blob> {
    const query = qs.stringify({ ids }, { arrayFormat: 'brackets' });
    const response = await this.api.get<ArrayBuffer>(`${CUSTOMERS_ENDPOINT}/qr?${query}`, {
      responseType: 'arraybuffer'
    });
    return new Blob([response.data], { type: 'application/pdf' });
  }

  public async completeQrByCustomers(ids: number[]): Promise<void> {
    await this.api.post(`${CUSTOMERS_ENDPOINT}/qr_complete`, { ids });
  }

  public async downloadQrByCustomer(id: number): Promise<Blob> {
    const response = await this.api.get<ArrayBuffer>(`${CUSTOMERS_ENDPOINT}/${id}/qr`, {
      responseType: 'arraybuffer'
    });
    return new Blob([response.data], { type: 'application/pdf' });
  }

  public async generateQrByCustomer(id: number): Promise<Blob> {
    const response = await this.api.post<ArrayBuffer>(
      `${CUSTOMERS_ENDPOINT}/${id}/invitation`,
      null,
      {
        responseType: 'arraybuffer'
      }
    );
    return new Blob([response.data], { type: 'application/pdf' });
  }

  public async toggleApplicationLock(id: number, locked: boolean): Promise<void> {
    await this.api.put(`${CUSTOMERS_ENDPOINT}/${id}/lock`, { locked });
  }

  public async unlinkAccount(id: number): Promise<void> {
    await this.api.delete(`${CUSTOMERS_ENDPOINT}/${id}/trust_account`);
  }

  public async reinitialize(id: number): Promise<void> {
    await this.api.patch(`${CUSTOMERS_ENDPOINT}/${id}/reinitialize`);
  }

  public async unlockAccount(email: string): Promise<void> {
    await this.api.post(this.namespacedLambdaEndpointFor(UNLOCK_ENDPOINT), { email });
  }

  public async isAccountLocked(email: string): Promise<boolean> {
    const response = await this.api.post<IIsAccountLockedRespnose>(
      this.namespacedLambdaEndpointFor(IS_ACCOUNT_LOCKED_ENDPOINT),
      { email }
    );
    return response.data.islocked;
  }

  public async stopTotp(id: number): Promise<void> {
    await this.api.patch(`${CUSTOMERS_ENDPOINT}/${id}/stop_totp`);
  }

  public async generateQrTotpByCustomer(id: number): Promise<Blob> {
    const response = await this.api.get<ArrayBuffer>(
      `${CUSTOMERS_ENDPOINT}/${id}/qr_totp`,
      {
        responseType: 'arraybuffer'
      }
    );
    return new Blob([response.data], { type: 'application/pdf' });
  }
}
