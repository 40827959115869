import React from 'react';
import classnames from 'classnames';

// Styles
import styles from './styles.scss';

interface IProps {
  className?: string;
  texts: string[];
}

export default class Breadcrumb extends React.PureComponent<IProps> {
  public render() {
    const { texts, className = '' } = this.props;
    const elements = [];
    for (let i = 0; i < texts.length; i += 1) {
      elements.push(<span key={i} className={styles.item}>{texts[i]}</span>);
      if (i < texts.length - 1) {
        elements.push(
          <span key={`>${i}`} className={classnames('mutb-chevron-right-icon', styles.item)} />
        );
      }
    }
    return <div className={classnames(styles.breadcrumb, className)}>{elements}</div>;
  }
}
