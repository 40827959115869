export interface IEventSourceObject {
  id: number|null;
  type: string;
  path: string|null;
  display_name: string|null;
}

export enum EventSourceType {
  Customer = 'customer',
  AdminUser = 'admin_user',
  BankSystem = 'bank_system'
}

export default class EventSourceModel {
  public id: number|null;
  public type: EventSourceType;
  public path: string|null;
  public displayName: string|null;

  constructor(json: IEventSourceObject) {
    this.id = json.id;
    this.type = json.type as EventSourceType;
    this.path = json.path;
    this.displayName = json.display_name;
  }
}
