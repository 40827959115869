import {
  action, IObservableArray, observable, runInAction
} from 'mobx';
import { saveAs } from 'file-saver';
import TrustAccountsDao from './TrustAccountsDao';
import TransactionModel from '../../models/TransactionModel';
import ClaimModel from '../../models/ClaimModel';
import { UserRole } from '../../models/UserModel';
import ManagedService from '../ManagedService';
import AccountModel from '../../models/AccountModel';
import TrustAccountModel from '../../models/TrustAccountModel';
import AccountService from '../AccountService';
import CustomerModel from '../../models/CustomerModel';
import SettingsService from '../SettingsService';

export enum TrustAccountPermissions {
  PrintQr = 'print_qr',
  ManageApplicationLock = 'manage_application_lock'
}

const PERMISSIONS_MAP = {
  [UserRole.Worker]: [
    TrustAccountPermissions.PrintQr
  ],
  [UserRole.Checker]: [
    TrustAccountPermissions.PrintQr
  ],
  [UserRole.Administrator]: [
    TrustAccountPermissions.PrintQr,
    TrustAccountPermissions.ManageApplicationLock
  ],
  [UserRole.Support]: [] as TrustAccountPermissions[],
  [UserRole.Corporate]: [] as TrustAccountPermissions[],
  [UserRole.Department]: [] as TrustAccountPermissions[],
  [UserRole.SystemAdministrator]: [] as TrustAccountPermissions[]
};

export default class TrustAccountsService
  extends ManagedService<TrustAccountsDao, TrustAccountPermissions> {
  public accountService: AccountService;
  settings: SettingsService;
  @observable public pendingTrustAccounts = false;
  @observable public transactionsCurrentPage = 0;
  @observable public transactionsPagesCount = 0;
  @observable public claimsCurrentPage = 0;
  @observable public claimsPagesCount = 0;
  @observable public customersCurrentPage = 0;
  @observable public customersPagesCount = 0;
  @observable public trustAccount: TrustAccountModel|null = null;
  @observable public transactions: IObservableArray<TransactionModel> = observable<TransactionModel>([]);
  @observable public claims: IObservableArray<ClaimModel> = observable<ClaimModel>([]);
  @observable public customers: IObservableArray<CustomerModel> = observable<CustomerModel>([]);

  constructor(dao: TrustAccountsDao, accountService: AccountService, settings: SettingsService) {
    super(dao);
    this.accountService = accountService;
    this.settings = settings;
  }

  // API_TRUST01
  @action public async loadTrustAccountById(id: number): Promise<void> {
    const { account } = this.accountService;
    if (!account) {
      throw new Error('Attempted to use method without account has been loaded');
    }
    try {
      this.pendingTrustAccounts = true;
      const trustAccount = await this.dao.loadTrustAccountById(id);

      runInAction(() => {
        this.trustAccount = trustAccount;
        this.resetPermissions();
        this.buildPermissions(account, trustAccount);
      });
    } finally {
      runInAction(() => {
        this.pendingTrustAccounts = false;
      });
    }
  }

  // API_TRUST04
  @action public async loadTransactionsById(id: number, page: number, showVaultAccount = false): Promise<void> {
    try {
      this.pendingTrustAccounts = true;
      this.transactions.replace([]);
      const { pagination: { current, last }, transactions } =
        await this.dao.loadTransactionsById(id, page, showVaultAccount);

      runInAction(() => {
        this.transactions.replace(transactions);
        this.transactionsCurrentPage = current;
        this.transactionsPagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingTrustAccounts = false;
      });
    }
  }

  // API_TRUST05
  @action public async loadClaimsById(id: number, page: number): Promise<void> {
    try {
      this.pendingTrustAccounts = true;
      this.claims.replace([]);
      const { pagination: { current, last }, claims } = await this.dao.loadClaimsById(id, page);

      runInAction(() => {
        this.claims.replace(claims);
        this.claimsCurrentPage = current;
        this.claimsPagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingTrustAccounts = false;
      });
    }
  }

  // API_TRUST06
  @action public async loadCustomersById(id: number, page: number): Promise<void> {
    try {
      this.pendingTrustAccounts = true;
      this.customers.replace([]);
      const { pagination: { current, last }, customers } =
        await this.dao.loadCustomersById(id, page);

      runInAction(() => {
        this.customers.replace(customers);
        this.customersCurrentPage = current;
        this.customersPagesCount = last;
      });
    } finally {
      runInAction(() => {
        this.pendingTrustAccounts = false;
      });
    }
  }

  // API_TRUST03
  @action public async toggleApplicationLock(trustAccount: TrustAccountModel) {
    try {
      this.pendingTrustAccounts = true;
      await this.dao.toggleApplicationLock(trustAccount.id, !trustAccount.isLocked);
    } finally {
      runInAction(() => {
        this.pendingTrustAccounts = false;
      });
    }
  }

  // API_TRUST02
  @action public async downloadQr(trustAccount: TrustAccountModel): Promise<void> {
    try {
      this.pendingTrustAccounts = true;
      const fileName = `customers${this.productFileName}.pdf`;
      const blob = await this.dao.downloadQr(trustAccount.id);
      saveAs(blob, fileName);
    } finally {
      runInAction(() => {
        this.pendingTrustAccounts = false;
      });
    }
  }

  private buildPermissions(account: AccountModel, trustAccount: TrustAccountModel) {
    const permissions = PERMISSIONS_MAP[account.role];
    if (!permissions) {
      return;
    }
    permissions.forEach((permission) => {
      this.addPermission(trustAccount, permission);
    });
  }

  private get productFileName() {
    return this.settings.isProductSelectionOhitori ? '_ohitori' : '';
  }
}
