import { CSSProperties } from 'react';
import table from '../../../components/table';
import ClaimModel from '../../../models/ClaimModel';

export const ROW_CURSOR_TYPE = 'pointer';
export const PAYMENT_ERROR_BACKGROUND = '#FBCCCC';

const onRowDoubleClick = (claim: ClaimModel) => {
  window.open(`#/claims/${claim.id}`);
};

const getRowStyle = (claim: ClaimModel) => {
  const rowStyles: CSSProperties = { cursor: ROW_CURSOR_TYPE };
  if (claim.isPaymentError) {
    rowStyles.background = PAYMENT_ERROR_BACKGROUND;
  }
  return rowStyles;
};

export default table<ClaimModel>({ defaultProps: { onRowDoubleClick, getRowStyle } });
