import React from 'react';
import Modal from '../../components/Modal';
import locales from '../../helpers/locales';

// Styles
import styles from './styles.scss';

interface IProps {
  loading: boolean;
}

export default class Loader extends React.Component<IProps> {
  public render() {
    const { loading } = this.props;
    if (!loading) {
      return null;
    }
    return (
      <Modal className={styles.loader} isOpen={loading}>
        {locales.get('loading')}
      </Modal>
    );
  }
}
