import React from 'react';
import { inject } from 'mobx-react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps,
  HashRouter as Router
} from 'react-router-dom';
// Components
import ClaimsListView from './ClaimsListView';
import ClaimDetailsView from './ClaimDetailsView';
// Services
import AccountService, { AccountPermissions } from '../../services/AccountService';

interface IProps extends RouteComponentProps<{}> {
  accountService: AccountService;
}

@inject('accountService')
class ClaimsManager extends React.PureComponent<IProps> {
  public renderRoutes(): React.ReactNodeArray {
    const { accountService } = this.props;
    const routes = [];

    if (
      accountService.hasAccountPermission(AccountPermissions.ViewClaimDetails) ||
      accountService.hasAccountPermission(AccountPermissions.ViewClaimsList)
    ) {
      routes.push(<Route key="details" path="/claims/:claimId" component={ClaimDetailsView} />);
    }

    if (accountService.hasAccountPermission(AccountPermissions.ViewClaimsList)) {
      routes.push(<Route key="list" path="/claims" component={ClaimsListView} />);
    }

    routes.push(<Redirect key="default" to="/" />);

    return routes;
  }

  public render() {
    return (
      <Router>
        <Switch>
          {this.renderRoutes()}
        </Switch>
      </Router>
    );
  }
}

export default withRouter(ClaimsManager);
