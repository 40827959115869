import React from 'react';
import classnames from 'classnames';
// Components
// Models
// Helpers
import { observer } from 'mobx-react';
import locales from '../../../../helpers/locales';
import Button, { ButtonSize } from '../../../../components/Button';
import FormService from '../../../../services/FormService';
import SettingsService from '../../../../services/SettingsService';
import ClaimModel from '../../../../models/ClaimModel';
// Styles
import styles from './styles.scss';

import templates from '../../../../../static/return-templates.json';

const RETURN_COMMENT_FIELD = 'claim.returnComment';

interface IProps {
  className?: string;
  claim: ClaimModel;
  saveReturnComment: () => void;
  onChange: (text: string) => void;
  disabled: boolean;
  settingsService: SettingsService;
}

@observer
export default class ClaimDetailsReturnComment extends React.Component<IProps> {
  private form: FormService;

  constructor(props: IProps) {
    super(props);
    const { onChange, claim: { returnedComment } } = props;
    this.form = new FormService()
      .setField(RETURN_COMMENT_FIELD, 'max:1000', returnedComment);
    onChange(returnedComment);
  }

  public saveReturnComment = () => {
    const { saveReturnComment } = this.props;
    if (!this.form.validateAll()) {
      return;
    }
    saveReturnComment();
  };

  public onChange = (event: React.FormEvent<HTMLSelectElement|HTMLTextAreaElement>) => {
    const { onChange } = this.props;
    const oldValue = this.form.getValue(RETURN_COMMENT_FIELD);
    this.form.setValue(RETURN_COMMENT_FIELD, event.currentTarget.value);
    if (oldValue !== this.form.getValue(RETURN_COMMENT_FIELD)) {
      onChange(this.form.getValue(RETURN_COMMENT_FIELD));
    }
  };

  public onBlur = () => {
    this.form.validate(RETURN_COMMENT_FIELD);
  };

  public renderReturnCommentTemplates = (productSelection: string) => {
    const templatesForCurrentProduct = templates[productSelection];
    return Object.keys(templatesForCurrentProduct)
      .map((key: string) => <option key={key} value={templatesForCurrentProduct[key]}>{key}</option>);
  };

  public renderTemplatesDropdown() {
    const { disabled, settingsService } = this.props;
    if (disabled) {
      return null;
    }

    const productSelection = settingsService.productSelectionWithDefault;

    return (
      <div className={styles.select}>
        <select onChange={this.onChange} value="initial">
          <option value="initial">
            {locales.get('claim.return_comment.select')}
          </option>
          {this.renderReturnCommentTemplates(productSelection)}
        </select>
      </div>
    );
  }

  public renderError() {
    const error = this.form.getError(RETURN_COMMENT_FIELD);
    if (!error) {
      return null;
    }
    return <div className={styles.message}>{error}</div>;
  }

  public render() {
    const { className, disabled } = this.props;
    const value = this.form.getValue(RETURN_COMMENT_FIELD);
    return (
      <div className={classnames(className, styles.returnComment)}>
        <div className={styles.head}>
          <div className={styles.heading}>
            {locales.get('claim.return_comment.title')}
          </div>
          {this.renderTemplatesDropdown()}
        </div>
        <textarea
          value={value}
          readOnly={disabled}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        <div className={styles.button}>
          {this.renderError()}
          <Button
            disabled={disabled}
            size={ButtonSize.Small}
            onClick={this.saveReturnComment}
            label={locales.get('claim.return_comment.save')}
          />
        </div>
      </div>
    );
  }
}
