// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".rt-table .rt-thead .rt-tr .rt-th.UsersListView_headerCheckbox_tfpaE {\n  padding-left: 16px; }\n\n.UsersListView_checkbox_FzoOY {\n  align-items: center; }\n\n.UsersListView_diff_2Bnvp {\n  color: var(--primary-color); }\n\n.UsersListView_positive_3zVRm {\n  color: #7ED321; }\n\n.UsersListView_negative_2_nTh {\n  color: #99686D; }\n", "",{"version":3,"sources":["styles.scss","../../../styles/_theme.scss"],"names":[],"mappings":"AAEA;EAKU,kBAAkB,EAAA;;AAO5B;EACE,mBAAmB,EAAA;;AAGrB;EACE,2BAA2B,EAAA;;AAG7B;EACE,cCV6B,EAAA;;ADa/B;EACE,cCb6B,EAAA","file":"styles.scss","sourcesContent":["@import \"../../../styles/theme\";\n\n:global(.rt-table) {\n  :global(.rt-thead) {\n    :global(.rt-tr) {\n      :global(.rt-th) {\n        &.headerCheckbox {\n          padding-left: 16px;\n        }\n      }\n    }\n  }\n}\n\n.checkbox {\n  align-items: center;\n}\n\n.diff {\n  color: var(--primary-color);\n}\n\n.positive {\n  color: $positive-status-color;\n}\n\n.negative {\n  color: $negative-status-color;\n}\n","// Layout\n$header-height: 56px;\n$page-max-width: 998px;\n$modal-max-width: 898px;\n$page-content-padding: 0 10px;\n\n// Color Scheme\n$background-color: #FFF;\n$divider-color: #E1E1E1;\n$content-divider-color: #F7F7F7;\n$text-primary-color: #3D3D3D;\n$text-accent-color: #FFF;\n$text-sub-color: #AAA;\n$positive-status-color: #7ED321;\n$negative-status-color: #99686D;\n$error-massage-color: #D80C18;\n$warning-text-color: #E67700;\n$warning-background-color: #F8D8B6;\n\n// Filter\n$filter-text-size: 13px;\n$filter-placeholder-color: #8E8E93;\n$filter-color: #8E8E93;\n$filter-background-color: rgba(142, 142, 147, 0.12);\n$filter-clear-color: #8E8E93;\n\n// Tooltip\n$tooltip-background-color: #666;\n$tooltip-text-color: #FFF;\n"]}]);
// Exports
exports.locals = {
	"headerCheckbox": "UsersListView_headerCheckbox_tfpaE",
	"checkbox": "UsersListView_checkbox_FzoOY",
	"diff": "UsersListView_diff_2Bnvp",
	"positive": "UsersListView_positive_3zVRm",
	"negative": "UsersListView_negative_2_nTh"
};
module.exports = exports;
