import { translateCurrency } from '../helpers/translate';

export interface IVaultAccountObject {
  number: string;
  balance: number;
  initial_vault_amount: number;
  total_vault_amount: number;
}

export default class VaultAccountObject {
  public number: string;
  public balance: number;
  public initialVaultAmount: number;
  public totalVaultAmount: number;

  constructor(json: IVaultAccountObject) {
    this.number = json.number;
    this.balance = json.balance;
    this.initialVaultAmount = json.initial_vault_amount;
    this.totalVaultAmount = json.total_vault_amount;
  }

  get balanceString() {
    return translateCurrency(this.balance);
  }

  get initialVaultAmountString() {
    return translateCurrency(this.initialVaultAmount);
  }

  get totalVaultAmountString() {
    return translateCurrency(this.totalVaultAmount);
  }
}
