import React from 'react';
import classnames from 'classnames';
// Styles
import styles from './styles.scss';

interface IProps {
  label: string;
  value: string;
  selected: boolean;
  onClick?: (value: string) => void;
  className?: string;
}

export default class RadioPillButton extends React.PureComponent<IProps> {
  static defaultProps = {
    selected: false
  };

  public handleClick = () => {
    const { onClick, value } = this.props;

    if (onClick) {
      onClick(value);
    }
  };

  public render() {
    const { label, selected, className } = this.props;

    return (
      <button
        type="button"
        className={classnames(className, styles.pillButton, { [styles.selected]: selected })}
        onClick={this.handleClick}
      >
        <div className={classnames(styles.label)}>
          {label}
        </div>
      </button>
    );
  }
}
