import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Containers/Components
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import TabContent from '../../../components/TabContent';
import Footer from '../../../components/Footer';
import Button from '../../../components/Button';
import Breadcrumb from '../../../components/Breadcrumb';
import { ClaimsList, TransactionsList, TrustAccountMembers } from './List';
import AccountInformation from '../AccountInformation';
import Title from '../../../components/Title';
import InvitationsList from './List/InvitationsList';
// Services
import TrustAccountsService, {
  TrustAccountPermissions
} from '../../../services/TrustAccountsService';
import DialogService, { DialogType } from '../../../services/DialogService';
import InvitationsService from '../../../services/InvitationsService';
import SettingsService from '../../../services/SettingsService';
// Models
import TrustAccountModel from '../../../models/TrustAccountModel';
// Locales
import locales from '../../../helpers/locales';
// Styles
import styles from './styles.scss';

enum TabType {
  ContractGroup = 'contract_group',
  Invitations = 'invitations_list',
  Transactions = 'transactions_list',
  VaultTransactions = 'vault_transactions_list',
  Claims = 'claims_list'
}

const TSUKAETE_TAB_TYPES = [
  TabType.ContractGroup,
  TabType.Invitations,
  TabType.Transactions,
  TabType.Claims
];

const OHITORI_TAB_TYPES = [
  TabType.ContractGroup,
  TabType.Invitations,
  TabType.Transactions,
  TabType.VaultTransactions,
  TabType.Claims
];

interface IParams {
  trustAccountId: string;
}

interface IProps extends RouteComponentProps<IParams> {
  invitationsService: InvitationsService;
  trustAccountsService: TrustAccountsService;
  dialogService: DialogService;
  settingsService: SettingsService;
}

interface IState {
  currentTabIndex: number;
  isOpenAccount: boolean;
}

@inject('invitationsService', 'trustAccountsService', 'dialogService', 'settingsService')
@observer
class AccountDetailsView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTabIndex: 0,
      isOpenAccount: false
    };
  }

  public async componentDidMount() {
    const { trustAccountsService, dialogService } = this.props;
    try {
      await trustAccountsService.loadTrustAccountById(this.trustAccountId);
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message,
        onClose: this.redirectToCustomersList
      });
    }
  }

  get trustAccountId(): number {
    const { match: { params: { trustAccountId } } } = this.props;
    return parseInt(trustAccountId, 10);
  }

  public redirectToCustomersList = () => {
    const { history } = this.props;
    history.push('/customers');
  };

  public handleError = (error: Error) => {
    const { dialogService } = this.props;
    dialogService.setDialog({
      type: DialogType.Warn,
      content: error.message
    });
  };

  public openAccount = () => {
    this.setState({
      isOpenAccount: true
    });
  };

  public closeAccount = () => {
    this.setState({
      isOpenAccount: false
    });
  };

  public confirmToggleApplicationLock = () => {
    const { trustAccountsService, dialogService } = this.props;
    const { trustAccount } = trustAccountsService;
    if (!trustAccount) {
      throw new Error('toggleApplicationLock with no trustAccount');
    }
    const localeKey = trustAccount.isLocked ? 'unlock' : 'lock';
    dialogService.setDialog({
      type: DialogType.Confirm,
      content: this.renderApplicationLockConfirmation(trustAccount),
      onConfirm: this.toggleApplicationLock,
      submitLabel: locales.get(`account.contract_group.${localeKey}_app_message.submit`)
    });
  };

  public toggleApplicationLock = async () => {
    const { trustAccountsService, dialogService } = this.props;
    const { trustAccount } = trustAccountsService;
    if (!trustAccount) {
      throw new Error('tried to invoke toggleApplicationLock without trustAccount');
    }
    try {
      await trustAccountsService.toggleApplicationLock(trustAccount);

      const localeKey = trustAccount.isLocked ? 'unlock' : 'lock';
      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get(`account.contract_group.${localeKey}_app_message.success`),
        onClose: this.reloadAccountDetails
      });
    } catch (error) {
      this.handleError(error);
    }
  };

  public downloadQr = () => {
    const { trustAccountsService } = this.props;
    const { trustAccount } = trustAccountsService;
    if (!trustAccount) {
      throw new Error('tried to invoke downloadQr without trustAccount');
    }
    trustAccountsService.downloadQr(trustAccount).catch(this.handleError);
  };

  public onInvitationsPageChange = (page: number) => {
    const { trustAccountsService, invitationsService } = this.props;
    const { trustAccount } = trustAccountsService;
    if (!trustAccount) {
      throw new Error('tried to invoke onInvitationsPageChange without trustAccount');
    }
    invitationsService
      .loadInvitations({ page, trustAccountId: this.trustAccountId })
      .catch(this.handleError);
  };

  public onTransactionsPageChange = (page: number, showVaultAcccount = false) => {
    const { trustAccountsService } = this.props;
    trustAccountsService
      .loadTransactionsById(this.trustAccountId, page, showVaultAcccount)
      .catch(this.handleError);
  };

  public onVaultTransactionsPageChange = (page: number) => {
    this.onTransactionsPageChange(page, true);
  };

  public onClaimsPageChange = (page: number) => {
    const { trustAccountsService } = this.props;
    trustAccountsService
      .loadClaimsById(this.trustAccountId, page)
      .catch(this.handleError);
  };

  public tabTypes = () => {
    const { settingsService } = this.props;

    return settingsService.isProductSelectionOhitori ? OHITORI_TAB_TYPES : TSUKAETE_TAB_TYPES;
  };

  public changeTab = (newTabIndex: number) => {
    const { invitationsService, trustAccountsService } = this.props;
    const { trustAccount } = trustAccountsService;
    if (!trustAccount) {
      throw new Error('Showing this view without valid trust account');
    }
    switch (this.tabTypes()[newTabIndex]) {
      case TabType.ContractGroup:
        trustAccountsService
          .loadCustomersById(this.trustAccountId, 1)
          .catch(this.handleError);
        break;
      case TabType.Invitations:
        invitationsService
          .loadInvitations({ trustAccountNumber: trustAccount.number, page: 1 })
          .catch(this.handleError);
        break;
      case TabType.Transactions:
        trustAccountsService
          .loadTransactionsById(this.trustAccountId, 1)
          .catch(this.handleError);
        break;
      case TabType.VaultTransactions:
        trustAccountsService
          .loadTransactionsById(this.trustAccountId, 1, true)
          .catch(this.handleError);
        break;
      case TabType.Claims:
        trustAccountsService
          .loadClaimsById(this.trustAccountId, 1)
          .catch(this.handleError);
        break;
      default:
        throw new Error('Invalid TabType was specified');
    }
    this.setState({ currentTabIndex: newTabIndex });
  };

  private reloadAccountDetails = () => {
    const { trustAccountsService } = this.props;
    trustAccountsService
      .loadTrustAccountById(this.trustAccountId)
      .catch(this.handleError);
  };

  public renderApplicationLockConfirmation(trustAccount: TrustAccountModel) {
    const localeKey = trustAccount.isLocked ? 'unlock' : 'lock';
    const title = locales.get(
      `account.contract_group.${localeKey}_app_message.title`,
      { customer: trustAccount.nameKanji }
    );
    const description =
      locales.get(
        `account.contract_group.${localeKey}_app_message.description`,
        { customer: trustAccount.nameKanji }
      )
        .split('\n')
        .reduce(
          (prev: React.ReactNodeArray, current: string) => {
            prev.push(<span key={current}>{current}</span>);
            prev.push(<br key={`${current}-br`} />);
            return prev;
          },
          []
        );
    return (
      <div className={styles.confirm}>
        <Title key="title" className={styles.title}>{title}</Title>
        <div key="description">{description}</div>
      </div>
    );
  }

  public renderHead() {
    const { trustAccountsService: { trustAccount } } = this.props;
    const { isOpenAccount } = this.state;
    if (!trustAccount) {
      return null;
    }
    const texts = [trustAccount.nameKanji, locales.get('account.details')];

    return (
      <>
        <div className={styles.breadcrumbWrapper}>
          <Breadcrumb texts={texts} />
          <span className={styles.account}>
            {locales.get('account.customer', { customer: trustAccount.bankTorihikiNumber })}
          </span>
        </div>
        <AccountInformation
          isOpen={isOpenAccount}
          account={trustAccount}
          onClickLink={this.openAccount}
          onCloseModal={this.closeAccount}
        />
      </>
    );
  }

  public renderTab = (type: TabType, index: number) => {
    const {
      trustAccountsService: { pendingTrustAccounts }, invitationsService: { pendingInvitations }
    } = this.props;
    const { currentTabIndex } = this.state;
    return (
      <Tab
        key={type}
        index={index}
        onChange={this.changeTab}
        disabled={pendingTrustAccounts || pendingInvitations}
        active={currentTabIndex === index}
        label={locales.get(`account.${type.valueOf()}.title`)}
      />
    );
  };

  public renderTabs = () => (
    <Tabs>
      {this.tabTypes().map(this.renderTab)}
    </Tabs>
  );

  public renderTabContent = () => {
    const { invitationsService, trustAccountsService, dialogService } = this.props;
    const { currentTabIndex } = this.state;
    switch (this.tabTypes()[currentTabIndex]) {
      case TabType.ContractGroup:
        return (
          <TrustAccountMembers
            trustAccountId={this.trustAccountId}
            trustAccountsService={trustAccountsService}
            dialogService={dialogService}
          />
        );
      case TabType.Invitations:
        return (
          <InvitationsList
            invitations={invitationsService.invitations}
            loading={invitationsService.pendingInvitations}
            currentPage={invitationsService.currentPage}
            pagesCount={invitationsService.pagesCount}
            onPageChange={this.onInvitationsPageChange}
          />
        );
      case TabType.Transactions:
        return (
          <TransactionsList
            transactions={trustAccountsService.transactions.peek()}
            loading={trustAccountsService.pendingTrustAccounts}
            currentPage={trustAccountsService.transactionsCurrentPage}
            pagesCount={trustAccountsService.transactionsPagesCount}
            onPageChange={this.onTransactionsPageChange}
          />
        );
      case TabType.VaultTransactions:
        return (
          <TransactionsList
            transactions={trustAccountsService.transactions.peek()}
            loading={trustAccountsService.pendingTrustAccounts}
            currentPage={trustAccountsService.transactionsCurrentPage}
            pagesCount={trustAccountsService.transactionsPagesCount}
            onPageChange={this.onVaultTransactionsPageChange}
          />
        );
      case TabType.Claims:
        return (
          <ClaimsList
            claims={trustAccountsService.claims.peek()}
            loading={trustAccountsService.pendingTrustAccounts}
            currentPage={trustAccountsService.claimsCurrentPage}
            pagesCount={trustAccountsService.claimsPagesCount}
            onPageChange={this.onClaimsPageChange}
          />
        );
      default:
        throw new Error('Invalid TabType was specified');
    }
  };

  public renderButtons() {
    const { trustAccountsService } = this.props;
    const { pendingTrustAccounts: loading } = trustAccountsService;
    const { trustAccount } = trustAccountsService;
    const { currentTabIndex } = this.state;
    if (this.tabTypes()[currentTabIndex] !== TabType.ContractGroup || !trustAccount) {
      return null;
    }

    const canLock = trustAccountsService.hasPermission(
      trustAccount, TrustAccountPermissions.ManageApplicationLock
    );
    const canPrintQr = trustAccountsService.hasPermission(
      trustAccount, TrustAccountPermissions.PrintQr
    );

    const localeKey = trustAccount.isLocked ? 'unlock' : 'lock';
    return (
      <>
        <Button
          label={locales.get(`account.contract_group.${localeKey}_app`)}
          onClick={this.confirmToggleApplicationLock}
          disabled={loading || !canLock}
        />
        <Button
          label={locales.get('account.contract_group.print_qr')}
          onClick={this.downloadQr}
          disabled={loading || !canPrintQr}
        />
      </>
    );
  }

  public render() {
    return (
      <>
        <div className={styles.head}>
          {this.renderHead()}
        </div>
        {this.renderTabs()}
        <TabContent>
          {this.renderTabContent()}
        </TabContent>
        <Footer>
          {this.renderButtons()}
        </Footer>
      </>
    );
  }
}

export default withRouter(AccountDetailsView);
