// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".NewPassword_password_dyqst {\n  padding-bottom: 40px; }\n  .NewPassword_password_dyqst .NewPassword_title_3VP5e {\n    margin-top: 39px;\n    margin-bottom: 34px; }\n  .NewPassword_password_dyqst .NewPassword_inputs_24_4f {\n    width: 580px; }\n  .NewPassword_password_dyqst .NewPassword_form_JbsCS {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%; }\n  .NewPassword_password_dyqst .NewPassword_buttons_xgZQ3 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 30px; }\n    .NewPassword_password_dyqst .NewPassword_buttons_xgZQ3 button {\n      width: 300px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EAGE,oBAAoB,EAAA;EAHtB;IAMI,gBAAgB;IAChB,mBAAmB,EAAA;EAPvB;IAWI,YAVgB,EAAA;EADpB;IAeI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW,EAAA;EAlBf;IAsBI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB,EAAA;IAzBpB;MA4BM,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.password {\n  $form-width: 580px;\n\n  padding-bottom: 40px;\n\n  .title {\n    margin-top: 39px;\n    margin-bottom: 34px;\n  }\n\n  .inputs {\n    width: $form-width;\n  }\n\n  .form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n  }\n\n  .buttons {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 30px;\n\n    button {\n      width: 300px;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"password": "NewPassword_password_dyqst",
	"title": "NewPassword_title_3VP5e",
	"inputs": "NewPassword_inputs_24_4f",
	"form": "NewPassword_form_JbsCS",
	"buttons": "NewPassword_buttons_xgZQ3"
};
module.exports = exports;
