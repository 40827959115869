// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MailMagazineSelection_mailMagazineSelection_3Taiu {\n  text-align: center;\n  border-radius: 0;\n  margin-bottom: 10px;\n  width: 400px; }\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import \"../../styles/theme\";\n\n.mailMagazineSelection {\n  text-align: center;\n  border-radius: 0;\n  margin-bottom: 10px;\n  width: 400px;\n}\n"]}]);
// Exports
exports.locals = {
	"mailMagazineSelection": "MailMagazineSelection_mailMagazineSelection_3Taiu"
};
module.exports = exports;
