// Models
import TrustAccountModel, {
  ConservatorStatus,
  TrustAccountLinkStatus,
  ITrustAccountObject,
  TrustAccountStatus
} from './TrustAccountModel';
import { translateDate } from '../helpers/translate';
import locales from '../helpers/locales';

export enum CustomerAccountStatus {
  Active = 'active',
  Locked = 'locked',
  Closed = 'closed'
}

export enum CustomerRole {
  AccountOwner = 'account_owner',
  Conservator = 'conservator',
  LegalAgent = 'legal_agent',
  Observer = 'observer'
}

export enum Gender {
  Male = 'M',
  Female = 'F'
}

export enum RoleNumber {
  Role1 = 'role1',
  Role2 = 'role2',
  Role3 = 'role3',
  Role4 = 'role4',
  Role5 = 'role5',
  Role6 = 'role6',
  Role7 = 'role7',
  Role8 = 'role8'
}

enum ProductType {
  Tsukaete = 'tsukaete',
  Ohitori = 'ohitori'
}

export interface ICustomersSearchParams {
  page: number;
  branchCode?: string;
  bankTorihikiNumber?: string;
  accountNumber?: string;
  customerNameKanji?: string;
  customerNameKana?: string;
  trustAccountLinkStatus?: TrustAccountLinkStatus;
}

const ROLE_NUMBER_MAPPING = {
  // つかえて安心
  [ProductType.Tsukaete]: {
    [TrustAccountStatus.Active]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role1,
        [ConservatorStatus.Applied]: RoleNumber.Role4,
        [ConservatorStatus.Selected]: RoleNumber.Role4
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role2,
        [ConservatorStatus.Applied]: RoleNumber.Role4,
        [ConservatorStatus.Selected]: RoleNumber.Role2
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role2,
        [ConservatorStatus.Applied]: RoleNumber.Role2,
        [ConservatorStatus.Selected]: RoleNumber.Role2
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role3,
        [ConservatorStatus.Selected]: RoleNumber.Role3,
        [ConservatorStatus.Applied]: RoleNumber.Role3
      }
    },
    [TrustAccountStatus.Probate]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role6,
        [ConservatorStatus.Selected]: RoleNumber.Role6,
        [ConservatorStatus.Applied]: RoleNumber.Role6
      }
    },
    [TrustAccountStatus.Cancelled]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role6,
        [ConservatorStatus.Selected]: RoleNumber.Role6,
        [ConservatorStatus.Applied]: RoleNumber.Role6
      }
    },
    [TrustAccountStatus.Expired]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      }
    }
  },
  // おひとりさま
  [ProductType.Ohitori]: {
    [TrustAccountStatus.Active]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role1,
        [ConservatorStatus.Applied]: RoleNumber.Role4,
        [ConservatorStatus.Selected]: RoleNumber.Role4
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role8,
        [ConservatorStatus.Applied]: RoleNumber.Role8,
        [ConservatorStatus.Selected]: RoleNumber.Role8
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role2,
        [ConservatorStatus.Applied]: RoleNumber.Role2,
        [ConservatorStatus.Selected]: RoleNumber.Role2
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role3,
        [ConservatorStatus.Selected]: RoleNumber.Role3,
        [ConservatorStatus.Applied]: RoleNumber.Role3
      }
    },
    [TrustAccountStatus.Probate]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role8,
        [ConservatorStatus.Selected]: RoleNumber.Role8,
        [ConservatorStatus.Applied]: RoleNumber.Role8
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role6,
        [ConservatorStatus.Selected]: RoleNumber.Role6,
        [ConservatorStatus.Applied]: RoleNumber.Role6
      }
    },
    [TrustAccountStatus.Cancelled]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role5,
        [ConservatorStatus.Selected]: RoleNumber.Role5,
        [ConservatorStatus.Applied]: RoleNumber.Role5
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role6,
        [ConservatorStatus.Selected]: RoleNumber.Role6,
        [ConservatorStatus.Applied]: RoleNumber.Role6
      }
    },
    [TrustAccountStatus.Expired]: {
      [CustomerRole.AccountOwner]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      },
      [CustomerRole.LegalAgent]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      },
      [CustomerRole.Conservator]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      },
      [CustomerRole.Observer]: {
        [ConservatorStatus.None]: RoleNumber.Role7,
        [ConservatorStatus.Selected]: RoleNumber.Role7,
        [ConservatorStatus.Applied]: RoleNumber.Role7
      }
    }
  }
};

export interface ICustomerObject {
  // customer_summary_def
  id: number;
  bank_customer_number: string;
  name_kanji: string;
  name_kana: string;
  name_corporate: string;
  phone_number: string;
  address_residential: string;
  address_residential_postcode: string;
  address_residential_country: string;
  address_mailing: string;
  address_mailing_postcode: string;
  address_mailing_country: string;
  gender: string;
  relationship: string;
  role: string;
  account_status: string;
  trust_account_link_status: string;
  trust_account: ITrustAccountObject;
  // customer_def
  bank_torihiki_number: string;
  branch_code: string;
  branch_name: string;
  role_code: string;
  locked: boolean;
  email: string;
  birthday: string;
  created_at: string;
  deleted_at: string|null;
}

export default class CustomerModel {
  // customer_summary_def
  public id: number;
  public bankCustomerNumber: string;
  public nameKanji: string;
  public nameKana: string;
  public nameCorporate: string;
  public phoneNumber: string;
  public addressResidential: string;
  public addressResidentialPostcode: string;
  public addressResidentialCountry: string;
  public addressMailing: string;
  public addressMailingPostcode: string;
  public addressMailingCountry: string;
  public gender: Gender;
  public relationship: string;
  public role: CustomerRole;
  public accountStatus: CustomerAccountStatus;
  public trustAccountLinkStatus: TrustAccountLinkStatus;
  public trustAccount: TrustAccountModel;
  public productType: ProductType;
  // customer_def
  public bankTorihikiNumber: string;
  public branchCode: string;
  public branchName: string;
  public roleCode: string;
  public locked: boolean;
  public email: string;
  public birthday: Date;
  public createdAt: Date;
  public deletedAt: Date|null;

  constructor(json: ICustomerObject, isProductSelectionOhitori?: boolean) {
    // customer_summary_def
    this.id = json.id;
    this.bankCustomerNumber = json.bank_customer_number;
    this.nameKanji = json.name_kanji;
    this.nameKana = json.name_kana;
    this.nameCorporate = json.name_corporate;
    this.phoneNumber = json.phone_number;
    this.addressResidential = json.address_residential;
    this.addressResidentialPostcode = json.address_residential_postcode;
    this.addressResidentialCountry = json.address_residential_country;
    this.addressMailing = json.address_mailing;
    this.addressMailingPostcode = json.address_mailing_postcode;
    this.addressMailingCountry = json.address_mailing_country;
    this.gender = json.gender as Gender;
    this.relationship = json.relationship;
    this.role = json.role as CustomerRole;
    this.accountStatus = json.account_status as CustomerAccountStatus;
    this.trustAccountLinkStatus = json.trust_account_link_status as TrustAccountLinkStatus;
    this.trustAccount = new TrustAccountModel(json.trust_account);
    this.productType = isProductSelectionOhitori === true ? ProductType.Ohitori : ProductType.Tsukaete;
    // customer_def
    this.bankTorihikiNumber = json.bank_torihiki_number;
    this.branchCode = json.branch_code;
    this.branchName = json.branch_name;
    this.roleCode = json.role_code;
    this.locked = json.locked;
    this.email = json.email;
    this.birthday = new Date(json.birthday);
    this.createdAt = new Date(json.created_at);
    this.deletedAt = json.deleted_at ? new Date(json.deleted_at) : null;
  }

  get birthdayString() {
    return translateDate(this.birthday);
  }

  get createdAtString() {
    return translateDate(this.createdAt);
  }

  get deletedAtString() {
    return translateDate(this.deletedAt);
  }

  get roleString() {
    if (!this.role) {
      return locales.get(`general.customer_roles.${CustomerRole.Observer}`);
    }
    return locales.get(`general.customer_roles.${this.role}`);
  }

  get roleNumber() {
    if (
      !ROLE_NUMBER_MAPPING[this.productType] ||
      !ROLE_NUMBER_MAPPING[this.productType][this.trustAccount.status] ||
      !ROLE_NUMBER_MAPPING[this.productType][this.trustAccount.status][this.role] ||
      !ROLE_NUMBER_MAPPING[this.productType][this.trustAccount.status][this.role][this.trustAccount.conservatorStatus]
    ) {
      return '';
    }

    return ROLE_NUMBER_MAPPING[
      this.productType][this.trustAccount.status][this.role][this.trustAccount.conservatorStatus];
  }

  get roleNumberString() {
    if (!this.roleNumber) {
      return '';
    }
    return locales.get(`general.customer_role_numbers.${this.roleNumber}`);
  }
}
