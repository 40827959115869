import {
  action, observable
} from 'mobx';
// Services
import StorageService from '../StorageService';
import StorageDaoImpl from '../StorageService/StorageDaoImpl';

import ProductSelection from '../../views/ProductSelection';

const SUPPORTED_PRODUCTS = [
  ProductSelection.PRODUCT_OHITORISAMA,
  ProductSelection.PRODUCT_TSUKAETE_ANSHIN
];

export default class SettingsService {
  private storage: StorageService;

  @observable public productSelection: string|null|undefined = null; // TODO: enum of permitted string values?
  @observable public productSelectionInProgress = false;

  get productSelectionWithDefault() {
    return this.productSelection || ProductSelection.PRODUCT_TSUKAETE_ANSHIN;
  }

  get isProductSelected() {
    if (!(typeof this.productSelection === 'string')) {
      return false;
    }

    return [
      ProductSelection.PRODUCT_TSUKAETE_ANSHIN, ProductSelection.PRODUCT_OHITORISAMA
    ].includes(this.productSelection);
  }

  get isProductSelectionOhitori() {
    return this.productSelection === ProductSelection.PRODUCT_OHITORISAMA;
  }

  constructor() {
    this.storage = new StorageService(new StorageDaoImpl());
    this.productSelection = this.storage.getItem(ProductSelection.PRODUCT_SELECTION_KEY);
  }

  @action public setProductSelection(productSelection: string|undefined) {
    if (productSelection) {
      if (!SUPPORTED_PRODUCTS.includes(productSelection)) {
        throw new Error(`Unsupported product selection. Supported are ${SUPPORTED_PRODUCTS.join(',')}`);
      }
      this.storage.setItem(ProductSelection.PRODUCT_SELECTION_KEY, productSelection);
    } else {
      this.storage.removeItem(ProductSelection.PRODUCT_SELECTION_KEY);
    }
    this.productSelection = productSelection;
  }

  @action public setProductSelectionInProgress(productSelectionInProgress: boolean) {
    this.productSelectionInProgress = productSelectionInProgress;
  }

  @action public clearProductSelection() {
    this.productSelection = null;
    this.storage.removeItem(ProductSelection.PRODUCT_SELECTION_KEY);
  }

  public setThemeColor() {
    const rootElement = document.querySelector(':root') as HTMLElement;

    if (rootElement) {
      const rootStyle = getComputedStyle(rootElement);

      const productSelection = this.productSelectionWithDefault;

      const gradations = [
        '',
        '-light-5-percent',
        '-light-30-percent',
        '-light-40-percent',
        '-light-52-percent',
        '-light-54-percent',
        '-dark-10-percent'
      ];

      gradations.forEach((gradation) => {
        rootElement.style.setProperty(
          `--primary-color${gradation}`,
          rootStyle.getPropertyValue(`--primary-color-${productSelection}${gradation}`)
        );
      });
    }
  }
}
