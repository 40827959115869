import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import styles from './styles.scss';

interface IProps extends RouteComponentProps<{}> {
  label: string;
  disabled?: boolean;
  children: React.ReactNode;
}

interface IState {
  isMenuShowing: boolean;
}

class NavMenu extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    disabled: false
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      isMenuShowing: false
    };
  }

  public componentWillUnmount() {
    this.hideMenu();
  }

  public showMenu = () => {
    const { disabled } = this.props;
    const { isMenuShowing } = this.state;
    if (!disabled && !isMenuShowing) {
      this.setState({ isMenuShowing: true });
      document.addEventListener('click', this.hideMenu);
    }
  };

  public hideMenu = () => {
    this.setState({ isMenuShowing: false });
    document.removeEventListener('click', this.hideMenu);
  };

  public renderMenu() {
    const { children } = this.props;

    return (
      <div className={styles.list}>
        <div className={styles.listContent}>{children}</div>
      </div>
    );
  }

  public render() {
    const { label, disabled } = this.props;
    const { isMenuShowing } = this.state;
    const classNames = classnames(styles.menu, { [styles.disabled]: disabled });
    return (
      <div className={classNames} onClick={this.showMenu}>
        {label}
        {isMenuShowing ? this.renderMenu() : null}
      </div>
    );
  }
}

export default withRouter(NavMenu);
