import React from 'react';
import classnames from 'classnames';
import Checkbox from '../Checkbox';
// Styles
import styles from './styles.scss';

interface IOption {
  id: string;
  label: string;
}

interface IProps {
  options: IOption[];
  selectedOptions: string[];
  disabled: boolean;
  onChange?: (selectedOptions: string[]) => void;
}

export default class OptionList extends React.PureComponent<IProps> {
  public handleChange = (value: string, checked: boolean) => {
    const { onChange, selectedOptions } = this.props;
    if (!onChange) {
      return;
    }

    // TODO: Consider refactoring this to use a Set<string>
    let newValues = selectedOptions;

    if (checked) {
      if (!selectedOptions.includes(value)) {
        newValues = [...selectedOptions, value];
      }
    } else {
      newValues = selectedOptions.filter((currentValue) => currentValue !== value);
    }

    onChange(newValues);
  };

  public render() {
    const { options, selectedOptions, disabled } = this.props;

    return (
      <div className={classnames(styles.optionList)}>
        {
          options.map((option) => (
            <div key={option.id} className={classnames(styles.option)}>
              <Checkbox
                checked={selectedOptions.includes(option.id)}
                value={option.id}
                label={option.label}
                disabled={disabled}
                onChange={this.handleChange}
              />
            </div>
          ))
        }
      </div>
    );
  }
}
