import React from 'react';
import { inject, observer } from 'mobx-react';
// Components
import { Redirect } from 'react-router-dom';
import Button, { ButtonSize } from '../../components/Button';
import InputField, { InputFieldType } from '../../components/InputField';
import Title from '../../components/Title';
// Services
import FormService from '../../services/FormService';
import AccountService from '../../services/AccountService';
import DialogService, { DialogType } from '../../services/DialogService';
// Helpers
import locales from '../../helpers/locales';
import { AccountState } from '../../models/AccountModel';
// Styles
import styles from './styles.scss';
import Loader from '../Loader';

interface IProps {
  accountService: AccountService;
  dialogService: DialogService;
}

interface IState {
  touchPassword1: boolean;
  touchPassword2: boolean;
}

const PASSWORD1 = locales.get('password_change.password1.field_name');
const PASSWORD2 = locales.get('password_change.password2.field_name');

@inject('accountService', 'dialogService')
@observer
export default class NewPassword extends React.Component<IProps, IState> {
  private formService: FormService;

  constructor(props: IProps) {
    super(props);
    this.state = {
      touchPassword1: false,
      touchPassword2: false
    };
    this.formService = new FormService(false)
      .initForm({
        same: locales.get('error_messages.password_not_same')
      })
      .setField(PASSWORD1, 'required|password')
      .setField(PASSWORD2, `required|password|same:${PASSWORD1}`);
  }

  public changePassword = async () => {
    const { accountService, dialogService } = this.props;
    if (!this.formService.validateAll()) {
      return;
    }

    try {
      await accountService.completeNewPassword(this.formService.getValue(PASSWORD1));

      dialogService.setDialog({
        type: DialogType.Warn,
        content: locales.get('password_change.success'),
        passwordChange: true
      });
    } catch (error) {
      dialogService.setDialog({
        type: DialogType.Warn,
        content: error.message
      });
    }
  };

  public changePassword1 = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.formService.setValue(PASSWORD1, event.currentTarget.value);
  };

  public changePassword2 = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.formService.setValue(PASSWORD2, event.currentTarget.value);
  };

  public focusPassword1 = () => {
    this.setState({ touchPassword1: true });
  };

  public focusPassword2 = () => {
    this.setState({ touchPassword2: true });
  };

  public blurPassword = () => {
    this.formService.validate(PASSWORD1, PASSWORD2);
  };

  public render() {
    const { accountService: { accountState, pendingAccount: loading } } = this.props;
    const { touchPassword1, touchPassword2 } = this.state;

    if (accountState !== AccountState.NewPasswordRequired) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <Loader loading={loading} />
        <div className={styles.password}>
          <Title className={styles.title}>{locales.get('initial_password_change.title')}</Title>
          <div className={styles.form}>
            <div className={styles.inputs}>
              <InputField
                type={InputFieldType.Password}
                label={locales.get('password_change.password1.description')}
                value={this.formService.getValue(PASSWORD1)}
                error={touchPassword1 && this.formService.getError(PASSWORD1)}
                onChange={this.changePassword1}
                onFocus={this.focusPassword1}
                onBlur={this.blurPassword}
              />
              <InputField
                type={InputFieldType.Password}
                label={locales.get('password_change.password2.description')}
                value={this.formService.getValue(PASSWORD2)}
                error={touchPassword2 && this.formService.getError(PASSWORD2)}
                onChange={this.changePassword2}
                onFocus={this.focusPassword2}
                onBlur={this.blurPassword}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              size={ButtonSize.Big}
              label={locales.get('password_change.change')}
              disabled={!this.formService.isFormFilled()}
              onClick={this.changePassword}
            />
          </div>
        </div>
      </>
    );
  }
}
