import EventSourceModel, { IEventSourceObject } from './EventSourceModel';
import { translateDateTime } from '../helpers/translate';

export interface ICustomerEventObject {
  name: string;
  at: string;
  source: IEventSourceObject;
}

export enum EventName {
  Create = 'create',
  Invite = 'invite',
  Link = 'link',
  Unlink = 'unlink',
  Lock = 'lock',
  Unlock = 'unlock',
  Discard = 'discard'
}

export default class CustomerEventModel {
  public name: EventName;
  public at: Date;
  public source: EventSourceModel;

  constructor(json: ICustomerEventObject) {
    this.name = json.name as EventName;
    this.at = new Date(json.at);
    this.source = new EventSourceModel(json.source);
  }

  get atString() {
    return translateDateTime(this.at);
  }
}
